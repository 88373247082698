import { Table } from '@chakra-ui/react';
import { TableLoadingSpinner } from './TableLoadingSpinner';
import { TableNoResultsMessage } from './TableNoResultsMessage';

type BasicTableProps = {
    isLoading: boolean;
    isEmpty: boolean;
    children?: React.ReactNode | React.ReactNode[];
}

export const BasicTable = ({ isLoading, isEmpty, children }: BasicTableProps) => (
    <Table>
        {isLoading &&
            <TableLoadingSpinner />
        }
        {(!isLoading && isEmpty) &&
            <TableNoResultsMessage />
        }
        {children}
    </Table>
)