import {
    Alert,
    AlertDescription,
    AlertIcon,
    AlertTitle,
    Button,
    FormControl,
    FormErrorMessage,
    FormLabel,
    Input,
    Stack,
    Text,
    useToast
} from '@chakra-ui/react'
import { httpsCallable } from '@firebase/functions';
import { useState } from 'react'
import { useForm } from 'react-hook-form';
import { Link } from 'react-router-dom'
import { firebaseFunctions } from '../../../firebase';
import { showToast } from '../../../helpers';
import { Validators } from '../../../validationPatterns';
import { Header } from './header'

const sendResetPasswordLinkCallable = httpsCallable(
    firebaseFunctions,
    'users-sendResetPasswordLink'
);

export const Recover = () => {

    const [recoverEmailWasSent, setRecoverEmailWasSent] = useState(false);
    const toast = useToast();

    const sendResetPasswordLink = (data: any) => {
        return new Promise<void>((resolve) => {
            sendResetPasswordLinkCallable({
                email: data.email
            }).then(() => {
                setRecoverEmailWasSent(true);
                reset();
            }).catch((e) => {
                console.error(e);
                showToast(toast, {
                    title: 'Ha ocurrido un error. Vuelve a intentarlo.',
                    status: 'error'
                });
            }).finally(() => {
                resolve();
            });
        })
    }

    const {
        handleSubmit,
        reset,
        register,
        formState: { errors, isSubmitting },
    } = useForm();

    return (
        <>
            <Header title={'Recupera tu contraseña'} />
            {recoverEmailWasSent &&
                <>
                    <Alert
                        status='success'
                        variant='subtle'
                        flexDirection='column'
                        alignItems='center'
                        justifyContent='center'
                        textAlign='center'
                        pb={4}
                    >
                        <AlertIcon boxSize='60px' mr={0} />
                        <AlertTitle mt={4} mb={2} fontSize='lg'>
                            ¡Instrucciones de recuperación enviadas!
                        </AlertTitle>
                        <AlertDescription maxWidth='md'>
                            Te hemos enviado un correo electrónico con las instrucciones para
                            recuperar tu contraseña.  Verifica tu buzón de entrada y la bandeja de SPAM.
                        </AlertDescription>
                    </Alert>
                    <Button
                        variant='primary'
                        colorScheme={'brand'}
                        as={Link}
                        to='../login'>
                        Inicio
                    </Button>
                </>
            }
            {!recoverEmailWasSent &&
                <>
                    <Text>
                        Introduce el correo electrónico de tu cuenta y te enviaremos un email
                        con las instrucciones de recuperación de tu cuenta.
                    </Text>
                    <Stack as='form' onSubmit={handleSubmit(sendResetPasswordLink)} spacing='6' noValidate>
                        <FormControl isRequired isInvalid={errors.email !== undefined}>
                            <FormLabel htmlFor='email'>Email</FormLabel>
                            <Input
                                id='email'
                                type='email'
                                autoComplete='off'
                                {...register('email', {
                                    required: 'Campo obligatorio.',
                                    pattern: {
                                        value: Validators.email,
                                        message: 'Introduce un correo electrónico válido.'
                                    }
                                })}
                            />
                            <FormErrorMessage>
                                {errors.email &&
                                    <>{errors.email.message}</>
                                }
                            </FormErrorMessage>
                        </FormControl>
                        <Button
                            type='submit'
                            isLoading={isSubmitting}
                            colorScheme='brand'
                            variant='primary'>
                            Recuperar contraseña
                        </Button>
                    </Stack>
                    <Button variant='link' as={Link} size='sm' to='../login'>
                        ¿Ya recuerdas tu contraseña? Inicia sesión
                    </Button>
                </>
            }
        </>
    )
}