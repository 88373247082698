import { SectionHeader } from '../components/SectionHeader'
import { ChevronDownIcon } from '@chakra-ui/icons';
import { MdColorLens } from 'react-icons/md';
import { HotelSpecifficSection } from '../components/HotelSpecifficSection';
import { Button, Link, Menu, MenuButton, MenuItem, MenuList, Stack, useDisclosure, useToast } from '@chakra-ui/react';
import { Branding } from './Brand';
import { Legal } from './Legal';
import { General } from './General';
import { useConsoleContext } from '../../../layout/console/authenticated/AuthenticatedConsoleLayout';
import { showToast } from '../../../helpers';
import { Copy } from './Copy';
import { WidgetModal } from './WidgetModal';
import { PaymentMethod } from './paymentMethod/PaymentMethod';
import { Mailing } from './mailing/Mailing';

export const HotelSettings = () => {
    const context = useConsoleContext();
    const toast = useToast();

    const copyLandingPage = () => {
        navigator.clipboard.writeText(
            `${window.location.origin}/hotel/${context.settings.selectedClient!.id}-${context.settings.selectedHotel!.id}`
        ).then(() => {
            showToast(toast, {
                title: 'Valor copiado al portapapeles.',
                status: 'success'
            });
        }).catch((e) => {
            console.error(e);
            showToast(toast, {
                title: 'No se ha podido copiar el valor al portapapeles.',
                status: 'error'
            });
        });
    }
    const landingUrl =
        `${window.location.origin}/hotel/${context.settings.selectedClient?.id}-${context.settings.selectedHotel?.id}`

    const widgetModalDisclosure = useDisclosure();

    return (
        <>
            <SectionHeader
                title='Personalización de hotel'
                subtitle='Completa los campos para personalizar la configuración del hotel'
                icon={MdColorLens}>
                {(context.settings.selectedHotel && context.settings.selectedClient) &&
                    <Menu>
                        <MenuButton
                            as={Button}
                            rightIcon={<ChevronDownIcon />}
                            variant='primary'>
                            Acciones
                        </MenuButton>
                        <MenuList>
                            <MenuItem as='a' href={landingUrl} target='_blank'>Abrir landing page</MenuItem>
                            <MenuItem onClick={copyLandingPage}>Copiar enlace landing page</MenuItem>
                            <MenuItem onClick={widgetModalDisclosure.onOpen}>Instrucciones de instalación de widget</MenuItem>
                        </MenuList>
                    </Menu>
                }
            </SectionHeader>
            <WidgetModal
                isOpen={widgetModalDisclosure.isOpen}
                onClose={widgetModalDisclosure.onClose}
                clientId={context.settings.selectedClient?.id ?? '-'}
                hotelId={context.settings.selectedHotel?.id ?? '-'} />
            <HotelSpecifficSection>
                <Stack spacing={14}>
                    <Branding />
                    <General />
                    <Legal />
                    <Copy />

                    <Mailing />
                    {context.isSuperAdmin() &&
                        <PaymentMethod />
                    }
                </Stack>
            </HotelSpecifficSection>
        </>
    )
}