import { Center, Flex, Spinner } from '@chakra-ui/react'
import { doc } from 'firebase/firestore'
import { useEffect } from 'react'
import { useDocumentData } from 'react-firebase-hooks/firestore'
import { useOutletContext, useParams } from 'react-router-dom'
import { firebaseFirestore, initializeAnalytics } from '../../firebase'
import { generateHotelTheme } from '../../helpers'
import { HotelContext } from '../../types/HotelContext'
import { Error } from './Error'
import { Landing } from './Landing'
import { Widget } from './Widget'
import { CookieConsentModal } from './CookieConsnet/CookieConsentModal'
import { useCookies } from 'react-cookie'

type HotelLayoutProps = {
    widget?: boolean;
}

export const HotelLayout = ({ widget = false }: HotelLayoutProps) => {
    const { id } = useParams();
    const [clientId, hotelId = '?'] = id!!.split('-')

    const [cookies, _setCookie] = useCookies(['ggb-cookie-consent']);

    const allowedCookies: string[] = [];
    if (cookies['ggb-cookie-consent']) {
        const [config, _date] = cookies['ggb-cookie-consent'].split('|');
        allowedCookies.push(...config.split('+'));
    }

    const [
        generalSettingsSnapshot,
        isLoadingGeneralSettingsSnapshot,
        hasGeneralSettingsLoadingError
    ] = useDocumentData(doc(
        firebaseFirestore,
        'clients',
        clientId,
        'hotels',
        hotelId,
        'settings',
        'general'
    ));

    const [
        brandSettingsSnapshot,
        isLoadingBrandSettingsSnapshot,
        hasBrandSettingsLoadingError
    ] = useDocumentData(doc(
        firebaseFirestore,
        'clients',
        clientId,
        'hotels',
        hotelId,
        'settings',
        'branding'
    ));

    const [
        legalSettingsSnapshot,
        isLoadingLegalSettingsSnapshot,
        hasLegalSettingsLoadingError
    ] = useDocumentData(doc(
        firebaseFirestore,
        'clients',
        clientId,
        'hotels',
        hotelId,
        'settings',
        'legal'
    ));

    const [
        copySettingsSnapshot,
        isLoadingCopySettingsSnapshot,
        hasCopySettingsLoadingError
    ] = useDocumentData(doc(
        firebaseFirestore,
        'clients',
        clientId,
        'hotels',
        hotelId,
        'settings',
        'copy'
    ));

    useEffect(() => {
        if (hasLegalSettingsLoadingError) {
            console.error(hasLegalSettingsLoadingError);
        }
        if (hasBrandSettingsLoadingError) {
            console.error(hasBrandSettingsLoadingError);
        }
        if (hasGeneralSettingsLoadingError) {
            console.error(hasGeneralSettingsLoadingError);
        }
        if (hasCopySettingsLoadingError) {
            console.error(hasCopySettingsLoadingError);
        }
    }, [
        hasLegalSettingsLoadingError,
        hasBrandSettingsLoadingError,
        hasGeneralSettingsLoadingError,
        hasCopySettingsLoadingError
    ]);

    useEffect(() => {
        if (allowedCookies.includes('statistics') && brandSettingsSnapshot) {
            initializeAnalytics(brandSettingsSnapshot?.trackingId);
        }
    }, [allowedCookies, brandSettingsSnapshot])


    const isLoading =
        isLoadingGeneralSettingsSnapshot ||
        isLoadingBrandSettingsSnapshot ||
        isLoadingLegalSettingsSnapshot ||
        isLoadingCopySettingsSnapshot;


    const isAnySetttingUndefined =
        legalSettingsSnapshot === undefined ||
        brandSettingsSnapshot === undefined ||
        generalSettingsSnapshot === undefined ||
        copySettingsSnapshot === undefined;

    if (!isLoading && isAnySetttingUndefined) {
        return (<Error />)
    }

    const context: HotelContext = {
        isWidget: widget,
        clientId,
        hotelId,

        settings: {
            legal: legalSettingsSnapshot,
            brand: brandSettingsSnapshot,
            general: generalSettingsSnapshot,
            copy: copySettingsSnapshot,
            theme: {
                colors: {
                    palette: generateHotelTheme(brandSettingsSnapshot?.primaryColor),
                    text: `#${brandSettingsSnapshot?.textOnPrimary || '000'}`
                }
            }
        }
    }

    return (
        <>
            <CookieConsentModal context={context}/>
            <Flex direction='column' flex='1'>
                {(!isLoading && !widget) &&
                    <Landing context={context} />
                }
                {(!isLoading && widget) &&
                    <Widget context={context} />
                }
                {isLoading &&
                    <Center height='full'>
                        <Spinner color='black' size='lg' />
                    </Center>
                }
            </Flex>
        </>
    )
}


export function useHotelContext() {
    return useOutletContext<HotelContext>();
}