import { createMultiStyleConfigHelpers, extendTheme, type ThemeConfig } from '@chakra-ui/react';
import { tagAnatomy } from '@chakra-ui/anatomy'
import { theme as proTheme } from '@chakra-ui/pro-theme'
const { definePartsStyle, defineMultiStyleConfig } = createMultiStyleConfigHelpers(tagAnatomy.keys);


const tagBaseStyle = definePartsStyle({
    container: {
        cursor: 'pointer'
    },
});

const tagTheme = defineMultiStyleConfig({baseStyle: tagBaseStyle});

export const theme = extendTheme(
    proTheme,
    {
        styles: {
            global: {
                '#root': {
                    height: '100%',
                },
                '.no-scroll-bar': {
                    msOverflowStyle: 'none',
                    scrollbarWidth: 'none'
                },
                '.no-scroll-bar::-webkit-scrollbar': {
                    display: 'none'
                },
            },
        },
        config: {
            initialColorMode: 'light',
            useSystemColorMode: false
        },
        colors: {
            ...proTheme.colors,
            brand: {
                50: '#f3f5ff',
                100: '#e8ebff',
                200: '#d5dcff',
                300: '#b3bdff',
                400: '#8892fd',
                500: '#585ffa',
                600: '#3a35f2',
                700: '#2c23de',
                800: '#241dba',
                900: '#201a98',
            },
            violet: {
                50: '#e9f0ff',
                100: '#d8e1ff',
                200: '#b8c8ff',
                300: '#8ea3ff',
                400: '#616fff',
                500: '#3d3fff',
                600: '#2e1cff',
                700: '#2611f1',
                800: '#1f12c1',
                900: '#09072c',
            },
        },
        semanticTokens: {
            colors: {
                'bg-accent': 'violet.900',
                'bg-accent-subtle': 'brand.800',
            }
        },
        components: {
            Tag:tagTheme,
            Button: {
                variants: {
                    primary: () => ({
                        variant: 'solid',
                        bg: 'brand.800',
                        _hover: {
                            bg: 'brand.900'
                        }
                    }),
                    'ghost-on-accent': () => ({
                        color: 'brand.50',
                        _hover: {
                            bg: 'brand.900',
                        },
                        _activeLink: {
                            color: 'white',
                            bg: 'bg-accent-subtle',
                        },
                    }),
                }
            }
        }
    }
);