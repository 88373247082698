import { Center, Container, Stack, LinkOverlay, LinkBox } from '@chakra-ui/react'
import { Outlet } from 'react-router'
import { Link } from 'react-router-dom'
import { ResourceSize } from '../../helpers'
import { ResizedImageWithDefaultFallback } from '../../routes/hotel/components/ResizedImage'
import { HotelContext } from '../../types/HotelContext'
import { Footer } from './Footer'
import { Header } from './Helmet'

export type LandingProps = {
  context: HotelContext;
}
export const Landing = ({ context }: LandingProps) => (
  <>
    <Header generalSettings={context.settings.general} brandSettings={context.settings.brand} />
    <Container as='main' role='main' flex='1' px='6' pt='8' pb='16'>
      <Stack spacing={8} height='full'>
        <Center>
          <LinkBox>
            <LinkOverlay as={Link} to='products'>
              <ResizedImageWithDefaultFallback
                maxW={225}
                src={context.settings.brand.logo}
                resizeTo={ResourceSize.MEDIUM}
              />
            </LinkOverlay>
          </LinkBox>
        </Center>
        <Stack height={'full'} spacing={8}>
          <Outlet context={context} />
        </Stack>
      </Stack>
    </Container>
    <Footer generalSettings={context.settings.general} legalSettings={context.settings.legal} />
  </>
)

