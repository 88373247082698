import { BsHandbagFill } from 'react-icons/bs';
import { ListViewHeader } from '../components/ListViewHeader';
import { HotelSpecifficSection } from '../components/HotelSpecifficSection';
import {
    Thead,
    Tr,
    Th,
    Tbody,
    Td,
    Text,
    useToast,
    Badge,
    Icon,
    Input,
    InputGroup,
    InputLeftElement,
    Stack,
    Select,
    InputRightElement,
    HStack,
    Button
} from '@chakra-ui/react';
import { BasicTable } from '../components/tables/BasicTable';
import { BasicTableContainer } from '../components/tables/BasicTableContainer';
import { query, collection, orderBy, doc, where, QueryDocumentSnapshot, startAfter, limit } from 'firebase/firestore';
import debounce from 'lodash.debounce';
import { useState, useCallback, useEffect, useRef } from 'react';
import { useCollection, useDocumentData } from 'react-firebase-hooks/firestore';
import { firebaseFirestore } from '../../../firebase';
import {
    getColorForOrderStatusLabel,
    showToast,
    getOrderStatusLabel,
    formatDate,
    formatPrice
} from '../../../helpers';
import { useConsoleContext } from '../../../layout/console/authenticated/AuthenticatedConsoleLayout';
import { ViewButton } from '../components/tables/ViewButton';
import { FiSearch } from 'react-icons/fi';
import { TiDeleteOutline } from 'react-icons/ti';

const pageSize = 25;

const WrappedView = () => {
    const toast = useToast();
    const context = useConsoleContext();

    const [codeSearchQuery, setCodeSearchQuery] = useState<string>();
    const [emailSearchQuery, setEmailSearchQuery] = useState<string>();
    const [selectedStatus, setSelectedStatus] = useState<string>();

    const [lastVisible, setLastVisible] = useState<QueryDocumentSnapshot>();

    const [docs, setDocs] = useState<QueryDocumentSnapshot[]>([]);

    const codeChangeHandler = (event: any) => setCodeSearchQuery(event.target.value);
    const debouncedCodeChangeHandler = useCallback(debounce(codeChangeHandler, 300), []);

    const emailChangeHandler = (event: any) => setEmailSearchQuery(event.target.value);
    const debouncedEmailChangeHandler = useCallback(debounce(emailChangeHandler, 300), []);

    const codeInputRef = useRef<HTMLInputElement>(null);
    const emailInputRef = useRef<HTMLInputElement>(null);

    const clearCode = () => {
        if (codeInputRef?.current && codeInputRef?.current.value) {
            codeInputRef.current.value = '';
            setCodeSearchQuery('');
        }
    }

    const clearEmail = () => {
        if (emailInputRef?.current && emailInputRef?.current.value) {
            emailInputRef.current.value = '';
            setEmailSearchQuery('');
        }
    }

    const onStatusSelect = (e: any) => {
        setSelectedStatus(e.target.value);
    }

    const queryConstraints = [
        where('clientId', '==', context.settings.selectedClient!!.id),
        where('hotelId', '==', context.settings.selectedHotel!!.id),
    ];

    if (selectedStatus) {
        queryConstraints.push(
            where('status', 'in', [selectedStatus])
        )
    } else {
        queryConstraints.push(
            where('status', 'in', ['PAID', 'CLAIMED', 'CANCELLED'])
        )
    }

    if (codeSearchQuery) {
        queryConstraints.push(
            where('claimCode', '==', codeSearchQuery.toUpperCase())
        );
    }

    if (emailSearchQuery) {
        queryConstraints.push(
            where('customer.email', '==', emailSearchQuery.toLowerCase())
        );
    }

    const pagination = [];

    if (lastVisible) {
        pagination.push(startAfter(lastVisible), limit(pageSize))
    } else {
        pagination.push(limit(pageSize))
    }

    const [legalSettingsDataSnapshot, _loading, legalSettingLoadingError] = useDocumentData(
        doc(
            firebaseFirestore,
            'clients',
            context.settings.selectedClient!!.id,
            'hotels',
            context.settings.selectedHotel!!.id,
            'settings',
            'legal'
        )
    );

    const [ordersQuerySnapshot, isLoading, ordersLoadingError] = useCollection(
        query(
            collection(
                firebaseFirestore,
                'orders'
            ),
            ...queryConstraints,
            orderBy('created', 'desc'),
            ...pagination
        )
    );

    useEffect(() => {
        if (ordersLoadingError) {
            console.error(ordersLoadingError);
            showToast(toast, {
                title: 'Error al obtener el listado de ventas.',
                status: 'error'
            });
        }
        if (legalSettingLoadingError) {
            console.error(legalSettingLoadingError);
            showToast(toast, {
                title: 'Error al obtener configuración legal.',
                status: 'error'
            });
        }

    }, [ordersLoadingError, legalSettingLoadingError])

    useEffect(() => {
        setLastVisible(undefined);
        setDocs([]);
    }, [
        emailSearchQuery,
        codeSearchQuery,
        selectedStatus,
        context.settings.selectedHotel,
        context.settings.selectedClient
    ]);

    useEffect(() => {
        if (ordersQuerySnapshot?.docs) {
            if (lastVisible) {
                const mappedDocs: any = {};
                const newDocs = [...docs, ...ordersQuerySnapshot.docs];
                newDocs.map(d => {
                    mappedDocs[d.id] = d;
                })
                setDocs(Object.values(mappedDocs));
            } else {
                setDocs(ordersQuerySnapshot.docs);
            }
        }
    }, [ordersQuerySnapshot]);

    useEffect(() => {
        return () => {
            debouncedCodeChangeHandler.cancel();
            debouncedEmailChangeHandler.cancel();
        }
    }, []);


    return (
        <BasicTableContainer
            title={'Ventas'}
            customSearch={
                <>
                    <Stack direction={{ base: 'column', md: 'row' }}>
                        <InputGroup maxW={{ base: '100%', md: '250' }}>
                            <InputLeftElement pointerEvents='none'>
                                <Icon as={FiSearch} color='muted' boxSize='5' />
                            </InputLeftElement>
                            <Input ref={emailInputRef} placeholder='Buscar por email' onChange={debouncedEmailChangeHandler} />
                            <InputRightElement sx={{ cursor: 'pointer' }} onClick={clearEmail}>
                                <Icon as={TiDeleteOutline} color='muted' boxSize='5' />
                            </InputRightElement>
                        </InputGroup>
                        <InputGroup maxW={{ base: '100%', md: '220' }}>
                            <InputLeftElement pointerEvents='none'>
                                <Icon as={FiSearch} color='muted' boxSize='5' />
                            </InputLeftElement>
                            <Input ref={codeInputRef} placeholder='Buscar por código' onChange={debouncedCodeChangeHandler} />
                            <InputRightElement sx={{ cursor: 'pointer' }} onClick={clearCode}>
                                <Icon as={TiDeleteOutline} color='muted' boxSize='5' />
                            </InputRightElement>
                        </InputGroup>
                        <InputGroup maxW={{ base: '100%', md: '260' }}>
                            <Select value={selectedStatus} placeholder='Excluir pendientes de pago' onChange={onStatusSelect}>
                                <option value='AWAITING_PAYMENT'>Esperando pago</option>
                                <option value='PAID'>Pagados</option>
                                <option value='CLAIMED'>Canjeados</option>
                                <option value='CANCELLED'>Cancelados</option>
                            </Select>
                        </InputGroup>

                    </Stack>
                </>
            }
            footer={
                <HStack justify='space-between'>
                    <Text color='subtle' fontSize={'sm'}>
                        {`Mostrando ${docs.length} resultados`}
                    </Text>
                    <Button
                        variant='outline'
                        isLoading={isLoading}
                        isDisabled={(ordersQuerySnapshot?.docs?.length ?? 0) < pageSize}
                        onClick={() => {
                            setLastVisible(ordersQuerySnapshot?.docs[ordersQuerySnapshot.docs.length - 1])
                        }}>
                        Cargar más
                    </Button>
                </HStack>
            }>

            <BasicTable
                isLoading={isLoading}
                isEmpty={docs.length === 0}>
                <Thead>
                    <Tr>
                        <Th width={8}></Th>
                        <Th>Fecha</Th>
                        <Th>Código</Th>
                        <Th>Nombre</Th>
                        <Th>Email</Th>
                        <Th>Importe</Th>
                        <Th>Estado</Th>
                    </Tr>
                </Thead>
                <Tbody>
                    {docs.map((order) => (
                        <Tr key={order.id}>
                            <Td>
                                <ViewButton to={`/console/sales/${order.id}`} />
                            </Td>

                            <Td>
                                <Text color='muted'>{
                                    formatDate(order.data().created, legalSettingsDataSnapshot as any)}
                                </Text>
                            </Td>
                            <Td>
                                <Text color='muted'>
                                    {order.data().claimCode}
                                </Text>
                            </Td>
                            <Td>
                                <Text color='muted'>
                                    {`${order.data().customer.name} ${order.data().customer.lastName}`}
                                </Text>
                            </Td>
                            <Td>
                                <Text color='muted'>{order.data().customer.email}</Text>
                            </Td>
                            <Td>
                                <Text color='muted'>
                                    {formatPrice(order.data().amount, legalSettingsDataSnapshot as any)}
                                </Text>
                            </Td>
                            <Td>
                                <Badge
                                    size='md'
                                    variant='solid'
                                    colorScheme={getColorForOrderStatusLabel(order.data().status)}>
                                    {getOrderStatusLabel(order.data().status)}
                                </Badge>
                            </Td>
                        </Tr>
                    ))}
                </Tbody>
            </BasicTable>
        </BasicTableContainer >
    );
}

export const SalesList = () =>
    <>
        <ListViewHeader
            icon={BsHandbagFill}
            title={'Administración de ventas'}
            subtitle={'Ver y gestionar ventas'}
        />
        <HotelSpecifficSection>
            <WrappedView />
        </HotelSpecifficSection>
    </>
