import { Stack, Text } from '@chakra-ui/react';
import { SectionHeader } from '../components/SectionHeader';
import { useHotelContext } from '../../../layout/hotel/HotelLayout';

export const Legal = () => {

    const context = useHotelContext();
    const legalSettings = context.settings.legal;

    return (
        <>
            <SectionHeader
                title='AVISO LEGAL'
            />
            <Stack spacing={3}>
                <Text>
                    {`${legalSettings.company}, provisto con identificación fiscal ${legalSettings.taxId}, dirección ${legalSettings.address}, ${legalSettings.city}, 
                    no puede asumir ninguna responsabilidad derivada del uso incorrecto, inapropiado o ilícito de la información aparecida en la página 
                    de Internet visualizada.`}
                </Text>
                <Text>
                    {`Con los límites establecidos en la ley, ${legalSettings.company} no asume ninguna responsabilidad derivada de la falta de veracidad, 
                    integridad, actualización y precisión de los datos o informaciones que se contienen en sus páginas de Internet.`}
                </Text>
                <Text>
                    {`Los contenidos e información no vinculan a ${legalSettings.company} ni constituyen opiniones, consejos o asesoramiento legal de ningún tipo 
                    pues se trata meramente de un servicio ofrecido con carácter informativo y divulgativo.`}
                </Text>
                <Text>
                    {`La página de Internet puede contener enlaces (links) a otras páginas de terceras partes que ${legalSettings.company} no puede controlar. Por lo tanto, ${legalSettings.company} 
                    no puede asumir responsabilidades por el contenido que pueda aparecer en páginas de terceros.`}
                </Text>
                <Text>
                    {`Asimismo, para acceder a algunos de los servicios que ${legalSettings.company} ofrece a través del website deberá proporcionar algunos datos de carácter personal. 
                    En cumplimiento de lo establecido en el Reglamento (UE) 2016/679 del Parlamento Europeo y del Consejo, de 27 de abril de 2016, relativo a la protección de las personas
                    físicas en lo que respecta al tratamiento de datos personales y a la libre circulación de estos datos le informamos que, mediante la cumplimentación de los presentes formularios, 
                    sus datos personales quedarán incorporados y serán tratados en los ficheros de ${legalSettings.company} con el fin de poderle prestar y ofrecer nuestros servicios así como para 
                    informarle de las mejoras del sitio Web. Asimismo, le informamos de la posibilidad de que ejerza los derechos de acceso, rectificación, cancelación y oposición de sus datos de 
                    carácter personal, manera gratuita en la dirección ${legalSettings.address}, ${legalSettings.city}, ${legalSettings.country}.`}
                </Text>

            </Stack>
        </>
    )
}