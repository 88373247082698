import { Tag, TagLabel, TagCloseButton } from '@chakra-ui/react'
import { useHotelContext } from '../../../layout/hotel/HotelLayout'

export const CategoryTag = ({
    name,
    selected,
    canDeselect,
    onDeselect,
    onSelect
}: {
    name: string,
    selected: boolean,
    canDeselect: boolean,
    onDeselect: () => void
    onSelect: () => void
}) => {
    const context = useHotelContext();

    return (
        <Tag
            size={{ base: 'md', md: 'lg' }}
            px={6}
            py={3}
            borderRadius='full'
            onClick={selected ? onDeselect : onSelect}
            variant={selected ? 'solid' : 'outline'}
            bgColor={selected ? context.settings.theme.colors.palette[600] : 'inherit'}
            boxShadow={`inset 0 0 0px 1px ${context.settings.theme.colors.palette[600]}`}
            color={selected ? context.settings.theme.colors.text : 'black'}
        >
            <TagLabel>{name}</TagLabel>
            {(selected && canDeselect) &&
                <TagCloseButton color={context.settings.theme.colors.text} />
            }
        </Tag>
    )
}