import {
    Button,
    FormControl,
    FormErrorMessage,
    FormLabel,
    Input,
    Modal,
    ModalBody,
    ModalCloseButton,
    ModalContent,
    ModalFooter,
    ModalHeader,
    ModalOverlay,
    useToast
} from '@chakra-ui/react'
import { useConsoleContext } from '../../../../layout/console/authenticated/AuthenticatedConsoleLayout';
import { showToast } from '../../../../helpers';
import { useForm } from 'react-hook-form';
import { Validators } from '../../../../validationPatterns';
import { httpsCallable } from '@firebase/functions';
import { firebaseFunctions } from '../../../../firebase';

const sendTestMailCallable = httpsCallable(firebaseFunctions, 'misc-testMailingSettings');

type FormData = {
    email: string;
}
export const SendTestmailModal = (props: { isOpen: boolean, onClose: () => void }) => {
    const { isOpen, onClose } = props;
    const toast = useToast();
    const context = useConsoleContext();

    const {
        handleSubmit,
        register,
        reset,
        formState: { errors, isSubmitting }
    } = useForm<FormData>();

    const submit = (data: FormData) => new Promise<void>((resolve) => {
        const success = () => {
            reset();
            onClose();
            showToast(toast, {
                title: 'Correo de prueba enviado correctamente',
                description: 'Verifica que efectivamente lo hayas recibido.',
                status: 'success'
            });
        }
        const error = (e: any) => {
            console.error(e);
            showToast(toast, {
                title: 'Error al enviar el correo de prueba.',
                description: 'No se ha podido enviar el correo. Recomendamos que dejes configurado el modo por defecto.',
                status: 'error'
            });
        }
        sendTestMailCallable({
            clientId: context.settings.selectedClient!!.id,
            hotelId: context.settings.selectedHotel!!.id,
            email: data.email
        }).then(success)
            .catch(error)
            .finally(resolve)
    });

    return (
        <Modal
            isCentered
            isOpen={isOpen}
            onClose={onClose}>
            <ModalOverlay />
            <ModalContent as='form' noValidate onSubmit={handleSubmit(submit)}>
                <ModalHeader>Enviar correo de prueba</ModalHeader>
                <ModalCloseButton />
                <ModalBody>
                    <FormControl isRequired isInvalid={errors?.email !== undefined}>
                        <FormLabel htmlFor='email'>Correo electrónico destinatario</FormLabel>
                        <Input
                            id='email'
                            {...register('email', {
                                required: 'Campo obligatorio',
                                pattern: {
                                    value: Validators.email,
                                    message: 'Formato inválido.'
                                }
                            })}
                        />
                        <FormErrorMessage>
                            {errors.email &&
                                <>{errors.email.message}</>
                            }
                        </FormErrorMessage>
                    </FormControl>
                </ModalBody>
                <ModalFooter>
                    <Button variant='ghost' mr={3} onClick={onClose}>
                        Cancelar
                    </Button>
                    <Button type='submit' isLoading={isSubmitting} variant='primary'>Enviar</Button>
                </ModalFooter>
            </ModalContent>
        </Modal>
    )
}


