import { Text, HStack, Icon, Stack, Heading, IconButton, useBreakpointValue } from '@chakra-ui/react';
import { IconType } from 'react-icons';
import { Link } from 'react-router-dom';

export type SectionHeaderProps = {
    icon: IconType;
    to?: string;
    title: string;
    subtitle: string;
    children?: React.ReactNode | React.ReactNode[];
}

export const SectionHeader = (props: SectionHeaderProps) => {

    return (
        <Stack
            spacing='4'
            direction={{ base: 'column', lg: 'row' }}
            pb={6}
            justify={'space-between'}
            align={'start'}
        >
            <HStack spacing={6} alignItems='start' flexShrink={0}>
                {props.to &&
                    <IconButton
                        size='lg'
                        variant={'ghost'}
                        as={Link}
                        to={props.to}
                        icon={<props.icon size={useBreakpointValue({ base: '1.5em', lg: '2em' })} />}
                        aria-label={'Action'} />
                }
                {!props.to &&
                    <Icon as={props.icon} mt={1} boxSize={{ base: '1.5em', lg: '2em' }} />
                }
                <Stack spacing={2}>
                    <Heading size={{ base: 'xs', lg: 'sm' }} fontWeight='medium'>
                        {props.title}
                    </Heading>
                    <Text color='muted'>
                        {props.subtitle}
                    </Text>
                </Stack>
            </HStack>
            <Stack width={'full'} alignItems='end'>
                {props.children}
            </Stack>
        </Stack>

    )
}