import { Button, Link, Stack, Table, TableCaption, TableContainer, Tbody, Td, Text, Tfoot, Th, Thead, Tr } from '@chakra-ui/react';
import { SectionHeader } from '../components/SectionHeader';
import { Link as RouterLink } from 'react-router-dom';
import { useCookies } from 'react-cookie';
import { DateTime } from 'luxon';

export const Cookies = () => {
    const [cookies, _setCookie, removeCookie] = useCookies(['ggb-cookie-consent']);

    let allowedCookies: string[] = [];
    let consentDate: DateTime | undefined = undefined;
    if (cookies['ggb-cookie-consent']) {
        const [config, date] = cookies['ggb-cookie-consent'].split('|');
        for (const cookie of config.split('+')) {
            let translatedCookie = cookie.replace('required', 'Necesarias');
            translatedCookie = translatedCookie.replace('statistics', 'Estadística')
            allowedCookies.push(translatedCookie);
        }
        consentDate = DateTime.fromISO(date);
    }
    const deleteCookies = () => {
        removeCookie('ggb-cookie-consent', {
            path: '/',
            sameSite: 'strict'
        });
    }

    return (
        <>
            <SectionHeader
                title='POLÍTICA DE COOKIES'
            />
            <Stack spacing={3}>
                <Text>
                    Este sitio web utiliza cookies. Usamos cookies para personalizar el contenido y para analizar nuestro tráfico. También compartimos información sobre su uso de nuestro sitio con nuestros socios de análisis, quienes pueden combinarla con otra información que les haya proporcionado o que hayan recopilado a partir de su uso de sus servicios.
                </Text>
                <Text>
                    Las cookies son pequeños archivos de texto que los sitios web pueden utilizar para hacer que la experiencia del usuario sea más eficiente.
                </Text>
                <Text>
                    La ley establece que podemos almacenar cookies en su dispositivo si son estrictamente necesarias para el funcionamiento de este sitio. Para todos los demás tipos de cookies necesitamos su permiso.
                </Text>
                <Text>
                    Este sitio utiliza diferentes tipos de cookies. Algunas cookies son colocadas por servicios de terceros que aparecen en nuestras páginas.
                </Text>
                <Text>
                    En cualquier momento puede cambiar o retirar su consentimiento de la Declaración de cookies en nuestro sitio web.
                </Text>
                <Text>
                    Obtenga más información sobre quiénes somos, cómo puede contactarnos y cómo procesamos los datos personales en nuestra <Link as={RouterLink} to='../privacy' color='teal.500'>política de privacidad.</Link>
                </Text>
                <Text fontWeight={'bold'}>
                    Consentimiento
                </Text>
                {consentDate &&
                    <Text>
                        {`Otorgado el ${consentDate.toLocaleString(DateTime.DATETIME_MED_WITH_SECONDS)} con las siguientes opciones: ${allowedCookies.join(', ')}.`} Puedes revocar o reconfigurar tu consentimiento pulsando <Button variant='link' color='teal.500' onClick={deleteCookies}>aquí.</Button>
                    </Text>
                }
                {!consentDate &&
                    <Text>
                        No otorgado.
                    </Text>
                }
                <Text fontSize={'xl'} fontWeight={'bold'} pt={8}>
                    Detalle de cookies utilizadas en este sitio web
                </Text>
                <TableContainer>
                    <Table variant='striped'>
                        <Thead>
                            <Tr>
                                <Th>Clave o identificador</Th>
                                <Th>Tipo</Th>
                                <Th>Proveedor</Th>
                                <Th>Caducidad</Th>
                                <Th>Descripción</Th>
                            </Tr>
                        </Thead>
                        <Tbody>
                            <Tr>
                                <Td>ggb-cookie-consent</Td>
                                <Td>Necesaria (HTTP)</Td>
                                <Td>-</Td>
                                <Td>365 días</Td>
                                <Td>Almacena su consentimiento de uso de cookies y la configuración.</Td>
                            </Tr>
                            <Tr>
                                <Td>_ga_#, _ga</Td>
                                <Td>Estadística (HTTP)</Td>
                                <Td>Google</Td>
                                <Td>365 días</Td>
                                <Td>Recopila datos sobre el número de veces que un usuario ha visitado el sitio web además de las fechas de la primera visita y de la más reciente. Utilizada por Google Analytics.</Td>
                            </Tr>
                        </Tbody>
                    </Table>
                </TableContainer>
            </Stack>
        </>
    )
}