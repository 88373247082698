import {
    Button,
    Link,
    Modal,
    ModalBody,
    ModalContent,
    Box,
    ModalFooter,
    ModalHeader,
    ModalOverlay,
    Text
} from '@chakra-ui/react'
import { DateTime } from 'luxon';
import { useCookies } from 'react-cookie';
import { useResolvedPath } from 'react-router';
import { CheckboxCard, CheckboxCardGroup } from './CheckboxCardGroup';
import { useState } from 'react';
import { HotelContext } from '../../../types/HotelContext';

export const CookieConsentModal = ({context}: {context: HotelContext}) => {

    const defaultCookies = ['required', 'statistics'];

    const [cookieSettings, setCookieSettings] = useState(defaultCookies);
    const [cookies, setCookie] = useCookies(['ggb-cookie-consent']);
    const resolvedPath = useResolvedPath('cookies');


    const onSave = () => {
        setCookie(
            'ggb-cookie-consent',
            `${cookieSettings.join('+')}|${DateTime.now().toISO()}`,
            {
                path: '/',
                expires: DateTime.now().plus({ year: 1 }).toJSDate(),
                sameSite: 'strict'
            }
        );
    }

    const isOpen =cookies['ggb-cookie-consent'] === undefined && !context.isWidget;

    return (
        <Modal
            isOpen={isOpen}
            onClose={onSave}
            closeOnOverlayClick={false}
            closeOnEsc={false}
            isCentered
            size={'3xl'}>
            <ModalOverlay />
            <ModalContent>
                <ModalHeader>
                    Configuración de cookies
                </ModalHeader>
                <ModalBody>
                    <Text>
                        Las cookies de este sitio web se usan para analizar el tráfico. Además, compartimos información sobre el uso que haga del
                        sitio web con nuestros partners de análisis web, quienes pueden combinarla con otra información que les haya proporcionado
                        o que hayan recopilado a partir del uso que haya hecho de sus servicios. Puedes ver más información en nuestra <Link tabIndex={-1} href={`${resolvedPath.pathname}`} target='_blank' color='teal.400'>política de cookies</Link>.
                    </Text>
                    <Box mt={4}>
                        <CheckboxCardGroup defaultValue={['required', 'statistics']} spacing='3' onChange={e => setCookieSettings(e as string[])}>
                            <CheckboxCard key={'required'} value={'required'} isDisabled={true}>
                                <Text color='emphasized' fontWeight='medium' fontSize='sm'>
                                    Necesarias
                                </Text>
                                <Text color='muted' fontSize='sm'>
                                    Las cookies necesarias ayudan a hacer una página web utilizable activando funciones básicas como la navegación en la página y el acceso a áreas seguras de la página web. La página web no puede funcionar adecuadamente sin estas cookies.
                                </Text>
                            </CheckboxCard>
                            <CheckboxCard key={'statistics'} value={'statistics'} >
                                <Text color='emphasized' fontWeight='medium' fontSize='sm'>
                                    Estadística
                                </Text>
                                <Text color='muted' fontSize='sm'>
                                    Las cookies estadísticas ayudan a los propietarios de páginas web a comprender cómo interactúan los visitantes con las páginas web reuniendo y proporcionando información de forma anónima.
                                </Text>
                            </CheckboxCard>
                        </CheckboxCardGroup>

                    </Box>
                </ModalBody>
                <ModalFooter>
                    <Button variant='outline' onClick={onSave} _focusVisible={{ boxShadow: 'none' }}>
                        GUARDAR CONFIGURACIÓN
                    </Button>
                </ModalFooter>
            </ModalContent>
        </Modal>
    )
}
