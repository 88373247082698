import {
    Box,
    Text,
    Stack,
    Center,
    Spinner,
    Icon,
    HStack,
    Avatar,
    Divider,
    Flex,
    Button,
    useToast,
    useDisclosure,
    UseDisclosureReturn,
    Modal,
    ModalBody,
    ModalCloseButton,
    ModalContent,
    ModalFooter,
    ModalHeader,
    ModalOverlay,
    FormControl,
    FormErrorMessage,
    FormLabel,
    Textarea
} from '@chakra-ui/react';
import { addDoc, collection, serverTimestamp } from 'firebase/firestore';
import { useEffect } from 'react';
import { useCollection } from 'react-firebase-hooks/firestore';
import { SlNote } from 'react-icons/sl';
import { firebaseFirestore } from '../../../firebase';
import { formatDate, showToast } from '../../../helpers';
import { useForm } from 'react-hook-form';
import { useConsoleContext } from '../../../layout/console/authenticated/AuthenticatedConsoleLayout';

type NotesProps = {
    orderId: string;
    legalSettings?: any;
}

type NoteModalProps = {
    disclosure: UseDisclosureReturn
    orderId: string;
}

type NoteFormData = {
    content: string;
}

const AddNoteModal = (props: NoteModalProps) => {
    const { orderId, disclosure } = props;
    const toast = useToast();
    const context = useConsoleContext();
    const {
        handleSubmit,
        register,
        reset,
        formState: { errors, isSubmitting }
    } = useForm<NoteFormData>();

    const submit = (data: NoteFormData) => new Promise<void>((resolve) => {
        const success = () => {
            reset();
            disclosure.onClose();
            showToast(toast, {
                title: 'Nota creata correctamente',
                status: 'success'
            });
        }
        const error = (e: any) => {
            console.error(e);
            showToast(toast, {
                title: 'Error al crear nota',
                status: 'error'
            });
        }
        const ref = collection(
            firebaseFirestore,
            'orders',
            orderId,
            'notes'
        );
        addDoc(ref, {
            ...data,
            userName: context.user.displayName,
            uerId: context.user.uid,
            created: serverTimestamp(),
            updated: serverTimestamp()
        })
            .then(success)
            .catch(error)
            .finally(resolve);
    });

    return (
        <Modal
            isCentered
            isOpen={disclosure.isOpen}
            onClose={disclosure.onClose}>
            <ModalOverlay />
            <ModalContent as='form' noValidate onSubmit={handleSubmit(submit)}>
                <ModalHeader>Agregar nota</ModalHeader>
                <ModalCloseButton />
                <ModalBody>
                    <FormControl isRequired isInvalid={errors?.content !== undefined}>
                        <FormLabel htmlFor='content'>Contenido</FormLabel>
                        <Textarea
                            id='content'
                            height={300}
                            resize='none'
                            {...register('content', {
                                required: 'Campo obligatorio',
                                maxLength: {
                                    value: 300,
                                    message: 'Máximo 300 caracteres.'
                                }
                            })}
                        />
                        <FormErrorMessage>
                            {errors.content &&
                                <>{errors.content.message}</>
                            }
                        </FormErrorMessage>
                    </FormControl>
                </ModalBody>
                <ModalFooter>
                    <Button variant='ghost' mr={3} onClick={disclosure.onClose}>
                        Cancelar
                    </Button>
                    <Button type='submit' isLoading={isSubmitting} variant='primary'>Guardar</Button>
                </ModalFooter>
            </ModalContent>
        </Modal>
    )
}

export const Notes = (props: NotesProps) => {
    const { orderId, legalSettings } = props;
    const toast = useToast();

    const modalDisclosure = useDisclosure();

    const [notesQuerySnapshot, loadingNotesQuery, notesQueryLoadingError] = useCollection(
        collection(
            firebaseFirestore,
            'orders',
            orderId,
            'notes'
        )
    );

    useEffect(() => {
        if (notesQueryLoadingError) {
            showToast(toast, {
                title: 'Error al obtener la información de las notas',
                status: 'error'
            });
        }
    }, [notesQueryLoadingError]);

    return (
        <>
            <AddNoteModal disclosure={modalDisclosure} orderId={orderId}/>
            <Stack
                direction={{ base: 'column', xl: 'row' }}
                spacing={{ base: '5', xl: '8' }}
            >
                <Box flexShrink={0} width={{ base: '100%', xl: '250px' }}>
                    <Text fontSize='lg' fontWeight='medium'>
                        Notas y comentarios
                    </Text>
                    <Text color='muted' fontSize='sm'>
                        Información de uso interno acerca de la venta
                    </Text>
                </Box>
                <Box bg='bg-surface' boxShadow='sm' borderRadius='lg' flexGrow={1}>
                    {loadingNotesQuery &&
                        <Box p={8}>
                            <Center>
                                <Spinner color='brand.600' size='lg' />
                            </Center>
                        </Box>
                    }
                    {!loadingNotesQuery && notesQuerySnapshot?.docs.length === 0 &&
                        <Box p={12}>
                            <Stack alignItems={'center'} spacing={4}>
                                <Icon color='brand.900' as={SlNote} boxSize={'3.5em'} />
                                <Text>Todavía no hay notas</Text>
                            </Stack>
                        </Box>
                    }
                    {!loadingNotesQuery &&
                        <Box>
                            {(notesQuerySnapshot?.docs.length ?? 0) > 0 &&
                                <Stack p={12} spacing='12'>
                                    {notesQuerySnapshot?.docs.map(n => (
                                        <Stack key={n.id} direction='column' spacing='4'>
                                            <HStack>
                                                <Avatar name={n.data().userName} />
                                                <Stack spacing='0'>
                                                    <Text fontWeight={'bold'}>
                                                        {n.data().userName}
                                                    </Text>
                                                    <Text fontSize={'sm'} fontStyle={'italic'}>
                                                        {formatDate(n.data().created, legalSettings)}
                                                    </Text>
                                                </Stack>
                                            </HStack>
                                            <Text>
                                                {n.data().content}
                                            </Text>
                                        </Stack>
                                    ))}
                                </Stack>
                            }

                            <Divider mt={4} />
                            <Flex direction='row-reverse' py='4' px={{ base: '4', md: '6' }}>
                                <Button variant='primary' onClick={modalDisclosure.onOpen}>
                                    Agregar nota
                                </Button>
                            </Flex>
                        </Box>
                    }
                </Box>
            </Stack>
        </>
    )

}