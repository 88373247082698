import { SectionHeader } from '../components/SectionHeader'
import { MdLeaderboard, MdOutlineRemoveShoppingCart, MdPointOfSale, MdShoppingCart } from 'react-icons/md';
import { HotelSpecifficSection } from '../components/HotelSpecifficSection';
import {
    Text,
    Card,
    CardBody,
    HStack,
    Stack,
    Stat,
    StatHelpText,
    StatLabel,
    StatNumber,
    Tab,
    TabList,
    TabPanel,
    TabPanels,
    Tabs,
    useToast,
    Icon,
    Spinner,
    Center
} from '@chakra-ui/react';
import { formatPrice, showToast } from '../../../helpers';
import { useDocumentData } from 'react-firebase-hooks/firestore';
import { doc } from 'firebase/firestore';
import { firebaseFirestore, firebaseFunctions } from '../../../firebase';
import { useEffect, useState } from 'react';
import { useConsoleContext } from '../../../layout/console/authenticated/AuthenticatedConsoleLayout';
import { IconType } from 'react-icons';
import { FaUserTag } from 'react-icons/fa';
import { TfiStatsDown, TfiStatsUp } from 'react-icons/tfi';
import { httpsCallable } from '@firebase/functions';

const statsCallable = httpsCallable(firebaseFunctions, 'stats-hotel');

type StatCardProps = {
    title: string;
    icon?: IconType;
    value: string;
    increase: number;
}
const StatCard = (props: StatCardProps) => (
    <Card width={{ base: 'full', md: '50%' }} variant='elevated' size='lg' backgroundColor='violet.900' color='white'>
        <CardBody>
            <Stat>
                <StatLabel fontSize={'2xl'}>
                    <HStack spacing={3}>
                        {props.icon &&
                            <props.icon size={'1.2em'} />
                        }
                        <Text>{props.title}</Text>
                    </HStack>
                </StatLabel>
                <StatNumber fontSize={'5xl'}>{props.value}</StatNumber>
                <StatHelpText
                    fontWeight={'bold'}
                    textAlign={'right'}
                    fontSize={'2xl'}
                    color={props.increase > 0 ? '#9AFF8A' : '#FF8064'}>
                    <Icon as={props.increase > 0 ? TfiStatsUp : TfiStatsDown} mr={3} />
                    {`${props.increase.toFixed(2)} %`}
                </StatHelpText>
            </Stat>
        </CardBody>
    </Card>
)

type StatPanelProps = {
    type: 'day' | 'week' | 'month' | 'year',
    legalSettings?: any
}


const StatData = (props: StatPanelProps) => {
    const { type, legalSettings } = props;

    const [isLoading, setIsLoading] = useState(true);
    const [stats, setStats] = useState<any>();
    const context = useConsoleContext();

    useEffect(() => {
        setIsLoading(true);
        statsCallable({
            clientId: context.settings.selectedClient!!.id,
            hotelId: context.settings.selectedHotel!!.id,
            type
        }).then((d) => {
            setStats(d.data);
            setIsLoading(false);
        }).catch(e => {
            console.error(e);
        });
    }, [context.settings]);

    const getIncrease = (currentValue?: number, previousValue?: number) => {
        if (!currentValue) {
            currentValue = 0;
        }
        if (!previousValue) {
            previousValue = 0;
        }
        if (previousValue === currentValue) return 0;
        if (previousValue === 0) return 100
        return (currentValue - previousValue) / previousValue * 100
    }

    return (
        <>
            {isLoading &&
                <Center py={20}>
                    <Spinner size='xl' color={'violet.900'} />
                </Center>
            }
            {!isLoading &&
                <Stack direction='column' spacing={6} pt={8}>
                    <Stack direction={{ base: 'column', md: 'row' }} spacing={6}>
                        <StatCard title='Cantidad de ventas' icon={MdShoppingCart} value={stats?.current.orderQuantity} increase={getIncrease(stats?.current.orderQuantity, stats?.previous.orderQuantity)} />
                        <StatCard title='Importe de ventas' icon={MdPointOfSale} value={formatPrice(stats?.current.orderAmount, legalSettings)} increase={getIncrease(stats?.current.orderAmount, stats?.previous.orderAmount)} />
                    </Stack>
                    <Stack direction={{ base: 'column', md: 'row' }} spacing={6}>
                        <StatCard title='Ventas no finalizadas' icon={MdOutlineRemoveShoppingCart} value={stats?.current.unpaidOrderQuantity} increase={getIncrease(stats?.current.unpaidOrderQuantity, stats?.previous.unpaidOrderQuantity)} />
                        <StatCard title='Compradores únicos' icon={FaUserTag} value={stats?.current.uniqueBuyerQuantity} increase={getIncrease(stats?.current.uniqueBuyerQuantity, stats?.previous.uniqueBuyerQuantity)} />
                    </Stack>
                </Stack>
            }
        </>
    )
}

export const WrappedView = () => {

    const context = useConsoleContext();
    const toast = useToast();

    const [legalSettingsDataSnapshot, _loading, legalSettingLoadingError] = useDocumentData(
        doc(
            firebaseFirestore,
            'clients',
            context.settings.selectedClient!!.id,
            'hotels',
            context.settings.selectedHotel!!.id,
            'settings',
            'legal'
        )
    );

    useEffect(() => {
        if (legalSettingLoadingError) {
            showToast(toast, {
                title: 'Error al cargar los ajustes legales del hotel',
                status: 'error'
            });
        }
    }, [legalSettingLoadingError]);

    return (
        <Tabs
            variant='soft-rounded'
            align='center'
            colorScheme='brand'
            size='lg'
            isLazy
            lazyBehavior='keepMounted'
        >
            <TabList>
                <Tab>Día</Tab>
                <Tab>Semana</Tab>
                <Tab>Mes</Tab>
                <Tab>Año</Tab>
            </TabList>
            <TabPanels textAlign={'start'}>
                <TabPanel>
                    <StatData type='day' legalSettings={legalSettingsDataSnapshot} />
                </TabPanel>
                <TabPanel>
                    <StatData type='week' legalSettings={legalSettingsDataSnapshot} />
                </TabPanel>
                <TabPanel>
                    <StatData type='month' legalSettings={legalSettingsDataSnapshot} />
                </TabPanel>
                <TabPanel>
                    <StatData type='year' legalSettings={legalSettingsDataSnapshot} />
                </TabPanel>
            </TabPanels>
        </Tabs>


    )

}

export const HotelMetrics = () => (
    <>
        <SectionHeader
            title='Métricas de ventas'
            subtitle='Información sobre las ventas'
            icon={MdLeaderboard}
        />
        <HotelSpecifficSection>
            <WrappedView />
        </HotelSpecifficSection>
    </>
)