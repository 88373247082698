import { ListViewHeader } from '../components/ListViewHeader';
import { MdManageAccounts } from 'react-icons/md';
import { IoMdAdd } from 'react-icons/io';
import {
    Text,
    Badge,
    Tbody,
    Td,
    Th,
    Thead,
    Tr,
    useToast,
} from '@chakra-ui/react';
import {
    query,
    collection,
    where,
    QueryConstraint,
    DocumentData
} from '@firebase/firestore';
import { firebaseFirestore } from '../../../firebase';
import { useCallback, useEffect, useState } from 'react';
import debounce from 'lodash.debounce';
import { useCollection } from 'react-firebase-hooks/firestore';
import { showToast } from '../../../helpers';
import { BasicTableContainer } from '../components/tables/BasicTableContainer';
import { EditButton } from '../components/tables/EditButton';
import { BasicTable } from '../components/tables/BasicTable';
import { useConsoleContext } from '../../../layout/console/authenticated/AuthenticatedConsoleLayout';
import { Role } from '../../../types/role';

export const UsersList = () => {
    const toast = useToast();

    const [searchQuery, setSearchQuery] = useState<string>();
    const changeHandler = (event: any) => setSearchQuery(event.target.value);
    const debouncedChangeHandler = useCallback(debounce(changeHandler, 300), []);

    const context = useConsoleContext();

    const queryConstraints: QueryConstraint[] = [];
    if (context.claims.clientId) {
        queryConstraints.push(where('clientId', '==', context.claims.clientId));
    }

    const [usersQuerySnapshot, isLoadingUsers, usersLoadError] = useCollection(
        query(
            collection(firebaseFirestore, 'users'),
            ...queryConstraints
        )
    );

    const [clientsCollectionSnapshot, _loadingClients, clientsLoadError] = useCollection(
        collection(firebaseFirestore, 'clients'),
    );

    useEffect(() => {
        if (usersLoadError) {
            console.error(usersLoadError);
            showToast(
                toast, {
                title: 'Error al cargar listado de usuarios.',
                status: 'error'
            });
        }
        if (context.isSuperAdmin() && clientsLoadError) {
            console.error(clientsLoadError);
            showToast(
                toast, {
                title: 'Error al cargar listado de clientes.',
                status: 'error'
            });
        }
    }, [usersLoadError, clientsLoadError])

    const clientsMap = (clientsCollectionSnapshot?.docs ?? []).reduce((p, c) => {
        p[c.id] = c.data();
        return p;
    }, {} as { [key: string]: DocumentData });

    useEffect(() => {
        return () => {
            debouncedChangeHandler.cancel();
        }
    }, []);

    const filteredUsers = usersQuerySnapshot?.docs.filter((d) => {
        if (!searchQuery) return d;
        const lowerCaseQuery = searchQuery.toLowerCase();
        return d.data().name.toLowerCase().includes(lowerCaseQuery) ||
            d.data().email.toLowerCase().includes(lowerCaseQuery)
    });

    return (
        <>
            <ListViewHeader
                icon={MdManageAccounts}
                title={'Gestión de usuarios'}
                subtitle={'Ver, editar y añadir usuarios'}
                buttonIcon={IoMdAdd}
                buttonTitle={'Añadir usuario'}
                buttonTo={'/console/users/new'} />
            <BasicTableContainer
                title={'Usuarios'}
                caption={`Mostrando ${filteredUsers?.length} de ${usersQuerySnapshot?.docs.length} resultados`}
                onSearch={debouncedChangeHandler}
            >
                <BasicTable
                    isLoading={isLoadingUsers}
                    isEmpty={filteredUsers?.length === 0}>
                    <Thead>
                        <Tr>
                            <Th width={8}></Th>
                            <Th>Nombre</Th>
                            <Th>Email</Th>
                            {context.isSuperAdmin() &&
                                <Th>Cliente</Th>
                            }
                            <Th>Role</Th>
                            <Th>Estado</Th>
                        </Tr>
                    </Thead>
                    <Tbody>
                        {filteredUsers?.map((user) => (
                            <Tr key={user.id}>
                                <Td>
                                    <EditButton
                                        to={`/console/users/${user.id}`}
                                    />
                                </Td>
                                <Td>
                                    <Text color='muted'>{user.data().name}</Text>
                                </Td>
                                <Td>
                                    <Text color='muted'>{user.data().email}</Text>
                                </Td>
                                {context.isSuperAdmin() &&
                                    <Td>
                                        <Text color='muted'>{clientsMap[user.data().clientId]?.name ?? '-'}</Text>
                                    </Td>
                                }
                                <Td>
                                    <Badge size='md' variant='outline' colorScheme={user.data().role === Role.SUPERADMIN ? 'teal' : 'green'}>
                                        {user.data().role}
                                    </Badge>
                                </Td>
                                <Td>
                                    <Badge size='md' variant='solid' colorScheme={user.data().disabled ? 'red' : 'green'}>
                                        {user.data().disabled ? 'DESHABILITADO' : 'HABILITADO'}
                                    </Badge>
                                </Td>
                            </Tr>
                        ))}
                    </Tbody>
                </BasicTable>
            </BasicTableContainer>
        </>
    );
}