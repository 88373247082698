import {
    useToast,
    FormControl,
    FormLabel,
    Input,
    FormErrorMessage,
    Select
} from '@chakra-ui/react';
import { doc, setDoc, serverTimestamp } from 'firebase/firestore';
import { useEffect } from 'react';
import { useDocumentData } from 'react-firebase-hooks/firestore';
import { useForm } from 'react-hook-form';
import { firebaseFirestore } from '../../../../firebase';
import { useConsoleContext } from '../../../../layout/console/authenticated/AuthenticatedConsoleLayout';
import { showToast } from '../../../../helpers';
import { Form } from './Form';

type PaylandsFormData = {
    environment: string;
    apiKey: string;
    service: string;
    signature: string;
    templateId: string;
    comissionMerchantId:string;
    merchantId: string;
    comission: string;
    tax:string;
}

export const PaylandsForm = () => {
    const context = useConsoleContext();
    const toast = useToast();

    const docRef = doc(
        firebaseFirestore,
        'clients',
        context.settings.selectedClient!!.id,
        'hotels',
        context.settings.selectedHotel!!.id,
        'settings',
        'paymentMethods'
    );

    const [paymentMethodsDataSnapshot, loading, errorLoadingData] = useDocumentData(docRef);

    useEffect(() => {
        if (errorLoadingData) {
            console.error(errorLoadingData);
            showToast(toast, {
                title: 'Error al obtener la información del método de pago.',
                status: 'error'
            });
        }
    }, [errorLoadingData]);

    useEffect(() => {
        reset({
            environment: 'PRODUCTION',
            apiKey: '',
            service: '',
            signature: '',
            templateId: '',
            comissionMerchantId : '',
            merchantId : '',
            comission: '',
            tax: ''
        }, { keepDirty: false });
    }, [context.settings]);

    const showSaveSuccess = () => {
        showToast(toast, {
            title: 'Información actualizada correctamente',
            status: 'success',
        });

    }
    const showSaveError = (e: any) => {
        console.error(e);
        showToast(toast, {
            title: 'Error al guardar la información. Vuelve a itentarlo.',
            status: 'error',
        });
    }

    const save = (data: PaylandsFormData) => new Promise<void>((resolve) => {
        setDoc(
            docRef, {
            paylands: {
                ...data,
                comission: parseFloat(data.comission),
                tax: parseFloat(data.tax),
                merchantId: parseInt(data.merchantId),
                comissionMerchantId: parseInt(data.comissionMerchantId)
            },
            updated: serverTimestamp()
        }, { merge: true })
            .then(showSaveSuccess)
            .catch(showSaveError)
            .finally(resolve);
    });

    const {
        handleSubmit,
        register,
        reset,
        formState: { errors, isSubmitting },
    } = useForm<PaylandsFormData>({
        values: paymentMethodsDataSnapshot?.paylands as PaylandsFormData,
        resetOptions: {
            keepDirtyValues: true
        }
    });

    return (
        <Form
            isSubmitting={isSubmitting}
            onSubmit={handleSubmit(save)}
            canSubmit={!loading}>
            <FormControl isRequired isInvalid={errors?.apiKey !== undefined}>
                <FormLabel htmlFor='environment'>Entorno</FormLabel>
                <Select
                    id='environment'
                    placeholder='Selecciona el entorno'
                    {...register('environment', {
                        required: 'Campo obligatorio',
                    })}>
                    <option value='SANDBOX'>SANDBOX</option>
                    <option value='PRODUCTION'>PRODUCTION</option>
                </Select>
                <FormErrorMessage>
                    {errors.environment &&
                        <>{errors.environment.message}</>
                    }
                </FormErrorMessage>
            </FormControl>
            <FormControl isRequired isInvalid={errors?.apiKey !== undefined}>
                <FormLabel htmlFor='apiKey'>API Key</FormLabel>
                <Input
                    id='apiKey'
                    {...register('apiKey', {
                        required: 'Campo obligatorio',
                    })}
                />
                <FormErrorMessage>
                    {errors.apiKey &&
                        <>{errors.apiKey.message}</>
                    }
                </FormErrorMessage>
            </FormControl>
            <FormControl isRequired isInvalid={errors?.service !== undefined}>
                <FormLabel htmlFor='shortDescription'>Servicio</FormLabel>
                <Input
                    id='service'
                    {...register('service', {
                        required: 'Campo obligatorio',
                    })}
                />
                <FormErrorMessage>
                    {errors.service &&
                        <>{errors.service.message}</>
                    }
                </FormErrorMessage>
            </FormControl>
            <FormControl isRequired isInvalid={errors?.signature !== undefined}>
                <FormLabel htmlFor='signature'>Firma</FormLabel>
                <Input
                    id='signature'
                    {...register('signature', {
                        required: 'Campo obligatorio',
                    })}
                />
                <FormErrorMessage>
                    {errors.signature &&
                        <>{errors.signature.message}</>
                    }
                </FormErrorMessage>
            </FormControl>
            <FormControl isRequired isInvalid={errors?.templateId !== undefined}>
                <FormLabel htmlFor='templateId'>ID plantilla</FormLabel>
                <Input
                    id='templateId'
                    {...register('templateId', {
                        required: 'Campo obligatorio',
                    })}
                />
                <FormErrorMessage>
                    {errors.templateId &&
                        <>{errors.templateId.message}</>
                    }
                </FormErrorMessage>
            </FormControl>
            <FormControl isRequired isInvalid={errors?.comissionMerchantId !== undefined}>
                <FormLabel htmlFor='comissionMerchantId'>Cuenta de comisiones</FormLabel>
                <Input
                    id='comissionMerchantId'
                    {...register('comissionMerchantId', {
                        required: 'Campo obligatorio',
                    })}
                />
                <FormErrorMessage>
                    {errors.comissionMerchantId &&
                        <>{errors.comissionMerchantId.message}</>
                    }
                </FormErrorMessage>
            </FormControl>
            <FormControl isRequired isInvalid={errors?.merchantId !== undefined}>
                <FormLabel htmlFor='merchantId'>Cuenta comercio</FormLabel>
                <Input
                    id='merchantId'
                    {...register('merchantId', {
                        required: 'Campo obligatorio',
                        pattern:{
                            value: /^[0-9]+$/,
                            message: 'Debe ser un número entero válido.'
                        }
                    })}
                />
                <FormErrorMessage>
                    {errors.merchantId &&
                        <>{errors.merchantId?.message}</>
                    }
                </FormErrorMessage>
            </FormControl>
            <FormControl isRequired isInvalid={errors?.comission !== undefined}>
                <FormLabel htmlFor='comission'>Comisión</FormLabel>
                <Input
                    id='comission'
                    {...register('comission', {
                        required: 'Campo obligatorio',
                        pattern:{
                            value: /^([0-9]+(\.[0-9]+)?|\.[0-9]+)$/,
                            message: 'Debe ser un número decimal válido. Usar punto (.) como separador de flotante.'
                        }
                    })}
                />
                <FormErrorMessage>
                    {errors.comission &&
                        <>{errors.comission?.message}</>
                    }
                </FormErrorMessage>
            </FormControl>
            <FormControl isRequired isInvalid={errors?.tax !== undefined}>
                <FormLabel htmlFor='tax'>Impuestos</FormLabel>
                <Input
                    id='tax'
                    {...register('tax', {
                        required: 'Campo obligatorio',
                        pattern:{
                            value: /^([0-9]+(\.[0-9]+)?|\.[0-9]+)$/,
                            message: 'Debe ser un número decimal válido. Usar punto (.) como separador de flotante.'
                        }
                    })}
                />
                <FormErrorMessage>
                    {errors.tax &&
                        <>{errors.tax?.message}</>
                    }
                </FormErrorMessage>
            </FormControl>
        </Form>

    )
}
