import {
    Box,
    Center,
    Spinner,
    Stack,
    Text,
    Button,
    HStack,
    List,
    ListIcon,
    ListItem
} from '@chakra-ui/react';
import { doc } from '@firebase/firestore';
import { useDocumentData } from 'react-firebase-hooks/firestore';
import { Navigate, useParams, useResolvedPath } from 'react-router';
import { firebaseFirestore, logAnalyticsEvent } from '../../../firebase';
import { useHotelContext } from '../../../layout/hotel/HotelLayout';
import { SectionHeader } from '../components/SectionHeader';
import { ResizedImageWithDefaultFallback } from '../components/ResizedImage';
import { formatPrice, ResourceSize } from '../../../helpers';
import { MdShoppingBasket } from 'react-icons/md';
import { Link } from 'react-router-dom';
import { useEffect } from 'react';
import { FeatureListIcon } from './FeatureListIcon';

export const ProductDetails = () => {
    const { productId } = useParams();
    const context = useHotelContext();
    const [
        productDataSnapshot,
        isLoadingProductData,
        hasProductDataLoadingError
    ] = useDocumentData(
        doc(
            firebaseFirestore,
            'clients',
            context.clientId,
            'hotels',
            context.hotelId,
            'products',
            productId!!
        )
    );

    const resolvedPath = useResolvedPath('checkout');
    const resolvedPathWithoutWidget = resolvedPath.pathname.replace('/widget', '');

    useEffect(() => {
        if (hasProductDataLoadingError) {
            console.error(hasProductDataLoadingError);
        }
    }, [hasProductDataLoadingError]);

    useEffect(() => {
        if (productDataSnapshot) {
            logAnalyticsEvent('view_item', {
                currency: context.settings.legal.currency,
                value: productDataSnapshot.price,
                items: [{
                    item_id: productId,
                    item_name: productDataSnapshot.name,
                    location_id: context.settings.general.name
                }]
            })
        }
    }, [productDataSnapshot])

    if (!isLoadingProductData && !productDataSnapshot) {
        return <Navigate to='/not-found' replace />
    }


    return (
        <>
            <SectionHeader
                title='REGALA EXPERIENCIAS'
                subTitle='Las mejores promociones y ofertas para disfrutar de nuestros servicios'
            />
            {isLoadingProductData &&
                <Center height={'full'}>
                    <Spinner size={'lg'} color='black' />
                </Center>
            }
            {!isLoadingProductData &&
                <Box bg='bg-surface' p={3} boxShadow='sm' borderRadius='2xl'>
                    <Stack alignItems={'flex-start'}>
                        <Stack
                            spacing={8}
                            width='full'
                            alignItems={'flex-start'}
                            p={{ base: 0, md: 4 }}
                            direction={{ base: 'column', lg: 'row' }}>
                            <ResizedImageWithDefaultFallback
                                borderRadius={'2xl'}
                                objectFit='cover'
                                width={{ base: '100%', lg: '450px' }}
                                src={productDataSnapshot!!.image}
                                resizeTo={ResourceSize.LARGE}
                            />
                            <Stack p={2} spacing={8} width='full'>
                                <Stack spacing={8} flexGrow={1}>
                                    <Stack>
                                        <Text fontWeight={'medium'} fontSize='xl'>{productDataSnapshot?.name}</Text>
                                        <Text textAlign='justify' mt={4}>{productDataSnapshot?.description}</Text>
                                    </Stack>
                                    <List spacing={3} pl={{ base: 0, lg: 8 }}>
                                        {productDataSnapshot?.features?.map((f: string) => (
                                            <ListItem key={f}>
                                                <ListIcon as={FeatureListIcon} color={context.settings.theme.colors.palette[500]} />
                                                {f}
                                            </ListItem>
                                        ))}
                                    </List>
                                </Stack>
                                <Text align='right' fontSize={'3xl'}>
                                    {formatPrice(productDataSnapshot?.price, context.settings.legal)}
                                </Text>
                                <HStack spacing={6} justify={'stretch'}>
                                    <Button as={Link} to='..' replace size='xl' width='full'>
                                        Cancelar
                                    </Button>
                                    <Button
                                        width='full'
                                        size='xl'
                                        variant='solid'
                                        as={Link}
                                        target={context.isWidget ? '_parent' : '_self'}
                                        to={resolvedPathWithoutWidget}
                                        leftIcon={<MdShoppingBasket size={'22'} />}
                                        color={context.settings.theme.colors.text}
                                        _hover={{
                                            bg: context.settings.theme.colors.palette[600],
                                            textDecoration: 'none'
                                        }}
                                        _active={{
                                            bg: context.settings.theme.colors.palette[700]
                                        }}
                                        bg={context.settings.theme.colors.palette[500]}>
                                        {'Comprar'}
                                    </Button>

                                </HStack>

                            </Stack>

                        </Stack>
                    </Stack>
                </Box>
            }
        </>
    )
}