import { Icon, IconProps } from '@chakra-ui/react';

export const MailIcon = (props: IconProps) => (
    <Icon viewBox='0 0 20 14' {...props}>
        <path
            d='M9.26167 8.79667C9.45724 8.99167 9.72215 9.10117 9.99833 9.10117C10.2745 9.10117 10.5394 8.99167 10.735 8.79667L18.8067 0.725001C18.8615 0.6704 18.9 0.601621 18.9179 0.526367C18.9358 0.451112 18.9324 0.372355 18.9081 0.298914C18.8838 0.225473 18.8396 0.160249 18.7803 0.110547C18.721 0.0608445 18.6491 0.0286266 18.5725 0.0175011C18.4933 0.00583327 18.4134 -1.56555e-05 18.3333 1.22581e-06H1.66667C1.58607 -9.73686e-05 1.50557 0.00575177 1.42583 0.0175011C1.34928 0.0286266 1.27733 0.0608445 1.21805 0.110547C1.15877 0.160249 1.1145 0.225473 1.09019 0.298914C1.06589 0.372355 1.0625 0.451112 1.08043 0.526367C1.09835 0.601621 1.13687 0.6704 1.19167 0.725001L9.26167 8.79667Z'
            fill='currentColor' />
        <path
            d='M19.9067 1.52667C19.8788 1.51484 19.8481 1.51162 19.8184 1.51741C19.7887 1.5232 19.7614 1.53774 19.74 1.55917L14.5733 6.72583C14.5344 6.76503 14.5125 6.81806 14.5125 6.87333C14.5125 6.92861 14.5344 6.98164 14.5733 7.02083L18.7758 11.2233C18.8356 11.281 18.8832 11.3499 18.916 11.4261C18.9488 11.5024 18.9661 11.5844 18.9668 11.6674C18.9676 11.7504 18.9518 11.8327 18.9204 11.9095C18.889 11.9863 18.8427 12.0561 18.784 12.1148C18.7253 12.1735 18.6556 12.22 18.5788 12.2514C18.502 12.2829 18.4197 12.2988 18.3367 12.2981C18.2537 12.2974 18.1717 12.2802 18.0954 12.2475C18.0192 12.2147 17.9502 12.1672 17.8925 12.1075L13.69 7.905C13.6706 7.8856 13.6477 7.87021 13.6223 7.8597C13.597 7.8492 13.5699 7.84379 13.5425 7.84379C13.5151 7.84379 13.488 7.8492 13.4627 7.8597C13.4373 7.87021 13.4143 7.8856 13.395 7.905L11.6208 9.67917C11.1911 10.1087 10.6084 10.3499 10.0008 10.3499C9.39327 10.3499 8.81057 10.1087 8.38083 9.67917L6.605 7.90667C6.56581 7.86769 6.51278 7.84581 6.4575 7.84581C6.40222 7.84581 6.34919 7.86769 6.31 7.90667L2.1075 12.1092C1.98957 12.223 1.83166 12.2859 1.66779 12.2844C1.50392 12.2829 1.34719 12.2171 1.23136 12.1011C1.11554 11.9852 1.04988 11.8284 1.04854 11.6645C1.04719 11.5007 1.11026 11.3428 1.22417 11.225L5.42667 7.0225C5.46565 6.98331 5.48753 6.93028 5.48753 6.875C5.48753 6.81972 5.46565 6.76669 5.42667 6.7275L0.26 1.56083C0.238756 1.53921 0.21146 1.52453 0.181708 1.51873C0.151956 1.51293 0.121145 1.51628 0.0933333 1.52833C0.0659541 1.53974 0.0425201 1.55892 0.0259312 1.58351C0.00934231 1.6081 0.000326684 1.63701 0 1.66667V11.6667C0 12.1087 0.175595 12.5326 0.488155 12.8452C0.800716 13.1577 1.22464 13.3333 1.66667 13.3333H18.3333C18.7754 13.3333 19.1993 13.1577 19.5118 12.8452C19.8244 12.5326 20 12.1087 20 11.6667V1.66667C20.0001 1.6367 19.9913 1.60738 19.9747 1.58244C19.958 1.55751 19.9344 1.53809 19.9067 1.52667Z'
            fill='currentColor' />
    </Icon>


)