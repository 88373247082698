import {
  FormControl,
  FormErrorMessage,
  FormLabel,
  Heading,
  Input,
  Stack,
} from '@chakra-ui/react'
import { FormState, UseFormRegister } from 'react-hook-form'
import { CheckoutFormData } from '../../../types/CheckoutFormData'
import { Validators } from '../../../validationPatterns'

export const BuyerInformation = ({
  register,
  formState
}: {
  register: UseFormRegister<CheckoutFormData>,
  formState: FormState<CheckoutFormData>
}) => {
  return (
    <Stack spacing={{ base: '6', md: '10' }}>
      <Heading size='md'>Datos del comprador</Heading>
      <Stack spacing={{ base: '6', md: '8' }}>
        <FormControl id='name' isRequired isInvalid={formState.errors.customer?.name !== undefined}>
          <FormLabel>Nombre</FormLabel>
          <Input
            _focus={{
              borderColor: 'gray.600'
            }}
            _focusVisible={{
              boxShadow: `0px 0px 0px 1px gray`
            }}
            placeholder='Nombre'
            {...register(
              'customer.name',
              {
                required: 'Campo obligatorio'
              }
            )}
          />
          {formState.errors.customer?.name !== undefined &&
            <FormErrorMessage>
              {formState.errors.customer.name.message}
            </FormErrorMessage>
          }
        </FormControl>
        <FormControl id='lastname' isRequired isInvalid={formState.errors.customer?.lastName !== undefined}>
          <FormLabel>Apellidos</FormLabel>
          <Input
            placeholder='Apellidos'
            _focus={{
              borderColor: 'gray.600'
            }}
            _focusVisible={{
              boxShadow: `0px 0px 0px 1px gray`
            }}
            {...register(
              'customer.lastName',
              {
                required: 'Campo obligatorio.'
              }
            )}
          />
          {formState.errors.customer?.lastName !== undefined &&
            <FormErrorMessage>
              {formState.errors.customer.lastName.message}
            </FormErrorMessage>
          }
        </FormControl>
        <FormControl id='email' isRequired isInvalid={formState.errors.customer?.email !== undefined}>
          <FormLabel>Correo electrónico</FormLabel>
          <Input
            type='email'
            placeholder='tu@correo.com'
            _focus={{
              borderColor: 'gray.600'
            }}
            _focusVisible={{
              boxShadow: `0px 0px 0px 1px gray`
            }}
            {...register(
              'customer.email',
              {
                required: 'Campo obligatorio',
                pattern: {
                  value: Validators.email,
                  message: 'Formato de correo incorrecto.'
                }
              }
            )}
          />
          {formState.errors.customer?.email !== undefined &&
            <FormErrorMessage>
              {formState.errors.customer?.email.message}
            </FormErrorMessage>
          }
        </FormControl>
        <FormControl id='phone' isRequired isInvalid={formState.errors.customer?.phone !== undefined}>
          <FormLabel>Teléfono</FormLabel>
          <Input
            placeholder='600 000 000'
            _focus={{
              borderColor: 'gray.600'
            }}
            _focusVisible={{
              boxShadow: `0px 0px 0px 1px gray`
            }}
            {...register(
              'customer.phone',
              {
                required: 'Campo obligatorio.'
              }
            )}
          />
          {formState.errors.customer?.phone !== undefined &&
            <FormErrorMessage>
              {formState.errors.customer.phone.message}
            </FormErrorMessage>
          }
        </FormControl>

      </Stack>
    </Stack>
  )
}