import { Box, Button, Divider, Flex, Stack, Text } from '@chakra-ui/react';

type BasicFormProps = {
    title: string;
    description?: string;
    children?: React.ReactNode | React.ReactNode[];
    isSubmitting?: boolean;
    canSubmit?: boolean;
    onSubmit?: () => void
    additionalActions?: React.ReactNode | React.ReactNode[];
}

export const BasicForm = (props: BasicFormProps) => (
    <Stack
        direction={{ base: 'column', xl: 'row' }}
        spacing={{ base: '5', xl: '8' }}
    >
        <Box flexShrink={0} width={{ base: '100%', xl: '250px' }}>
            <Text fontSize='lg' fontWeight='medium'>
                {props.title}
            </Text>
            <Text color='muted' fontSize='sm'>
                {props.description ?? ''}
            </Text>
        </Box>
        <Box
            noValidate
            as='form'
            onSubmit={props.onSubmit}
            bg='bg-surface'
            boxShadow='sm'
            borderRadius='lg'
            flexGrow={1}>
            <Stack spacing='5' px={{ base: '4', md: '6' }} py={{ base: '5', md: '6' }}>
                {props.children}
            </Stack>
            <Divider mt={4} />
            <Flex direction='row-reverse' py='4' px={{ base: '4', md: '6' }}>
                <Button
                    isDisabled={!props.canSubmit}
                    isLoading={props.isSubmitting}
                    ml={4}
                    type='submit'
                    variant='primary'>
                    Guardar
                </Button>
                {props.additionalActions &&
                    props.additionalActions
                }
            </Flex>
        </Box>
    </Stack>
)