export const countries = [{
    iso2: 'ES', name: 'España', locale: 'es-ES',
}, {
    iso2: 'MX', name: 'México', locale: 'es-MX',
}, {
    iso2: 'CO', name: 'Colombia', locale: 'es-CO'
}, {
    iso2: 'AR', name: 'Argentina', locale: 'es-AR'
}, {
    iso2: 'US', name: 'USA', locale: 'en-US'
}]