import { initializeApp } from '@firebase/app';
import { initializeAppCheck, ReCaptchaV3Provider } from '@firebase/app-check';
import { getAuth, connectAuthEmulator } from '@firebase/auth';
import { connectFirestoreEmulator, initializeFirestore } from '@firebase/firestore';
import { getStorage, connectStorageEmulator } from '@firebase/storage';
import { getFunctions, connectFunctionsEmulator } from '@firebase/functions';
import ReactGA from 'react-ga4';

const firebaseConfig = {
    apiKey: "AIzaSyBsa6soHcbhSZch9TnOATSG99Lxchu21Tc",
    authDomain: "goguest-giftbox.firebaseapp.com",
    projectId: "goguest-giftbox",
    storageBucket: "goguest-giftbox.appspot.com",
    messagingSenderId: "63500976326",
    appId: "1:63500976326:web:1c47795af69e6d0d8897c7",
    measurementId: "G-R6SKBD6PLK"

};

// Initialize Firebase
const firebaseApp = initializeApp(firebaseConfig);

if (process.env.APP_CHECK_DEBUG_TOKEN) {
    self.FIREBASE_APPCHECK_DEBUG_TOKEN = process.env.APP_CHECK_DEBUG_TOKEN;
}

export const appCheck = initializeAppCheck(firebaseApp, {
    provider: new ReCaptchaV3Provider('6LcvY7kkAAAAALEgEJybkK6-_5G84Ca5WUsa95Op'),
    isTokenAutoRefreshEnabled: true
});

const auth = getAuth(firebaseApp);
const firestore = initializeFirestore(firebaseApp, { ignoreUndefinedProperties: true });
const storage = getStorage(firebaseApp);
const functions = getFunctions(firebaseApp, 'europe-west3');

const emulatorHost = process.env.EMULATOR_HOST ?? 'localhost';

if (process.env.USE_EMULATORS === 'true') {
    connectAuthEmulator(auth, `http://${emulatorHost}:9099`, { disableWarnings: true });
    connectFirestoreEmulator(firestore, emulatorHost, 8080);
    connectStorageEmulator(storage, emulatorHost, 9199);
    connectFunctionsEmulator(functions, emulatorHost, 5001);
}

export const initializeAnalytics = (trackingId?: string) => {
    if (!ReactGA.isInitialized) {
        if (trackingId) {
            ReactGA.initialize([{
                trackingId: firebaseConfig.measurementId,
            }, {
                trackingId
            }]);
        } else {
            ReactGA.initialize([{
                trackingId: firebaseConfig.measurementId,
            }]);
        }
    }
}
export const logAnalyticsEvent = (
    eventName: string,
    eventParams?: { [key: string]: any; },
) => {
    if (ReactGA.isInitialized) {
        ReactGA.event(eventName, eventParams);
    }
}
export const firebaseAuth = auth;
export const firebaseFirestore = firestore;
export const firebaseStorage = storage;
export const firebaseFunctions = functions;