export const currencies = [{
    iso: 'EUR', name: 'Euros'
}, {
    iso: 'MXN', name: 'Pesos Mexicanos'
}, {
    iso: 'USD', name: 'Dólares Americanos'
}, {
    iso: 'COP', name: 'Pesos Colombianos'
}, {
    iso: 'ARS', name: 'Pesos Argentinos'
}]