import { IconButton } from '@chakra-ui/react';
import { MdDeleteForever } from 'react-icons/md';

type DeleteButtonProps = {
    onClick: () => void;
    isLoading?: boolean;
}

export const DeleteButton = (props: DeleteButtonProps) => (
    <IconButton
        onClick={props.onClick}
        isLoading={props.isLoading}
        color='brand.800'
        icon={<MdDeleteForever fontSize='1.5rem' />}
        variant='ghost'
        aria-label='Borrar fila'
    />
)
