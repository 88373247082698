import {
    useToast,
    FormControl,
    FormLabel,
    Input,
    FormErrorMessage,
    Select
} from '@chakra-ui/react';
import { doc, setDoc, serverTimestamp } from 'firebase/firestore';
import { useEffect } from 'react';
import { useDocumentData } from 'react-firebase-hooks/firestore';
import { useForm } from 'react-hook-form';
import { countries } from '../../../data/countries';
import { currencies } from '../../../data/currencies';
import { firebaseFirestore } from '../../../firebase';
import { useConsoleContext } from '../../../layout/console/authenticated/AuthenticatedConsoleLayout';
import { showToast } from '../../../helpers';
import { FormControlRow } from '../components/forms/FormControlRow';
import { BasicForm } from '../components/forms/BasicForm';

type LegalFormData = {
    company: string;
    taxId: string;
    address: string;
    city: string;
    state?: string;
    postCode?: string;
    country: string;
    currency: string;
}

export const Legal = () => {

    const context = useConsoleContext();
    const toast = useToast();

    const docRef = doc(
        firebaseFirestore,
        'clients',
        context.settings.selectedClient!!.id,
        'hotels',
        context.settings.selectedHotel!!.id,
        'settings',
        'legal'
    );
    const [legalDataSnapshot, loading, errorLoadingData] = useDocumentData(docRef);

    useEffect(() => {
        if (errorLoadingData) {
            console.error(errorLoadingData);
            showToast(toast, {
                title: 'Error al obtener la información legal',
                status: 'error'
            });
        }
    }, [errorLoadingData]);

    const showSaveSuccess = () => {
        showToast(toast, {
            title: 'Información actualizada correctamente',
            status: 'success',
        });

    }
    const showSaveError = (e: any) => {
        console.error(e);
        showToast(toast, {
            title: 'Error al guardar la información. Vuelve a itentarlo.',
            status: 'error',
        });
    }

    const save = (data: LegalFormData) => new Promise<void>((resolve) => {
        setDoc(docRef, {
            ...data,
            updated: serverTimestamp()
        }, { merge: true })
            .then(showSaveSuccess)
            .catch(showSaveError)
            .finally(resolve);
    });

    useEffect(() => {
        reset({
            company:'',
            taxId: '',
            address: '',
            city: '',
            country: '',
            state: '',
            postCode: '',
            currency: ''
        }, { keepDirty: false });
    }, [context.settings]);

    const {
        handleSubmit,
        register,
        reset,
        formState: { errors, isSubmitting },
    } = useForm<LegalFormData>({
        values: legalDataSnapshot as LegalFormData,
        resetOptions:{
            keepDirtyValues:true
        }
    });


    return (
        <BasicForm
            title={'Datos fiscales'}
            description={'Datos legales de la empresa'}
            canSubmit={!loading}
            isSubmitting={isSubmitting}
            onSubmit={handleSubmit(save)} >
            <FormControlRow>
                <FormControl isRequired isInvalid={errors.company !== undefined}>
                    <FormLabel htmlFor='company'>Nombre de la empresa</FormLabel>
                    <Input
                        id='company'
                        type='text'
                        {...register('company', {
                            required: 'Campo obligatorio.',
                        })} />
                    <FormErrorMessage>
                        {errors.company &&
                            <>{errors.company.message}</>
                        }
                    </FormErrorMessage>
                </FormControl>
                <FormControl isRequired isInvalid={errors.taxId !== undefined}>
                    <FormLabel htmlFor='taxId'>CIF</FormLabel>
                    <Input
                        id='taxId'
                        type='text'
                        {...register('taxId', {
                            required: 'Campo obligatorio.',
                        })} />
                    <FormErrorMessage>
                        {errors.taxId &&
                            <>{errors.taxId.message}</>
                        }
                    </FormErrorMessage>
                </FormControl>
            </FormControlRow>

            <FormControl isRequired isInvalid={errors.address !== undefined}>
                <FormLabel htmlFor='address'>Dirección</FormLabel>
                <Input
                    id='address'
                    type='text'
                    {...register('address', {
                        required: 'Campo obligatorio.',
                    })} />
                <FormErrorMessage>
                    {errors.address &&
                        <>{errors.address.message}</>
                    }
                </FormErrorMessage>
            </FormControl>
            <FormControlRow>
                <FormControl isRequired isInvalid={errors.city !== undefined}>
                    <FormLabel htmlFor='city'>Ciudad</FormLabel>
                    <Input
                        id='city'
                        type='text'
                        {...register('city', {
                            required: 'Campo obligatorio.',
                        })} />
                    <FormErrorMessage>
                        {errors.city &&
                            <>{errors.city.message}</>
                        }
                    </FormErrorMessage>
                </FormControl>
                <FormControl isInvalid={errors.postCode !== undefined}>
                    <FormLabel htmlFor='postCode'>Código postal</FormLabel>
                    <Input
                        id='postCode'
                        type='text'
                        {...register('postCode')} />
                    <FormErrorMessage>
                        {errors.postCode &&
                            <>{errors.postCode.message}</>
                        }
                    </FormErrorMessage>
                </FormControl>
                <FormControl isInvalid={errors.state !== undefined}>
                    <FormLabel htmlFor='state'>Provincia/Estado</FormLabel>
                    <Input
                        id='state'
                        type='text'
                        {...register('state')} />
                    <FormErrorMessage>
                        {errors.state &&
                            <>{errors.state.message}</>
                        }
                    </FormErrorMessage>
                </FormControl>
            </FormControlRow>
            <FormControlRow>
                <FormControl isRequired isInvalid={errors.country !== undefined}>
                    <FormLabel htmlFor='country'>País</FormLabel>
                    <Select
                        id='country'
                        placeholder='Selecciona un país'
                        {...register('country', {
                            required: 'Campo obligatorio.',
                        })}>
                        {countries.map(c => (
                            <option key={c.iso2} value={c.iso2}>{c.name}</option>
                        ))}
                    </Select>
                    <FormErrorMessage>
                        {errors.country &&
                            <>{errors.country.message}</>
                        }
                    </FormErrorMessage>
                </FormControl>
                <FormControl isRequired isInvalid={errors.currency !== undefined}>
                    <FormLabel htmlFor='country'>Moneda</FormLabel>
                    <Select
                        id='currency'
                        placeholder='Selecciona una moneda'
                        {...register('currency', {
                            required: 'Campo obligatorio.',
                        })}>
                        {currencies.map(c => (
                            <option key={c.iso} value={c.iso}>{c.name}</option>
                        ))}
                    </Select>
                    <FormErrorMessage>
                        {errors.currency &&
                            <>{errors.currency.message}</>
                        }
                    </FormErrorMessage>
                </FormControl>
            </FormControlRow>

        </BasicForm >
    )

}