import { Center, Text, Spinner, Box, Flex, Stack, Button, HStack } from '@chakra-ui/react';
import { doc } from 'firebase/firestore';
import { useEffect, useState } from 'react';
import { useDocumentData } from 'react-firebase-hooks/firestore';
import { Navigate, useParams } from 'react-router-dom';
import { firebaseFirestore, firebaseFunctions } from '../../../firebase';
import { useHotelContext } from '../../../layout/hotel/HotelLayout';
import { BsArrowRight } from 'react-icons/bs';
import { PaymentKoIcon } from './PaymentKoIcon';
import { httpsCallable } from '@firebase/functions';

const retryPaymentCallable = httpsCallable<any, any>(firebaseFunctions, 'orders-retryPayment');

export const PaymentKo = () => {
    const { orderId } = useParams();
    const context = useHotelContext();
    const [
        orderDataSnapshot,
        isLoadingOrderData,
        hasOrderDataLoadingError
    ] = useDocumentData(
        doc(
            firebaseFirestore,
            'orders',
            orderId!!
        )
    );

    useEffect(() => {
        if (hasOrderDataLoadingError) {
            console.error(hasOrderDataLoadingError);
        }
    }, [hasOrderDataLoadingError]);

    const [isLoading, setIsLoading] = useState(false);

    const retryPayment = async () => {
        setIsLoading(true);
        try {
            const response = await retryPaymentCallable({
                orderId
            });
            if (response.data.paymentLink) {
                window.location.href = response.data.paymentLink;
            }
        } catch (e) {
            console.error(e)
            setIsLoading(false);
        }
    }

    if (!isLoadingOrderData && !orderDataSnapshot) {
        return <Navigate to='/not-found' replace />
    }

    if (orderDataSnapshot?.status === 'PAID') {
        return <Navigate to={`../orders/${orderId}/payment/ok`} replace />
    }

    return (
        <>
            {isLoadingOrderData &&
                <Center height={'full'}>
                    <Spinner size={'lg'} color='black' />
                </Center>
            }
            {!isLoadingOrderData &&
                <Flex
                    maxW='xl'
                    mx='auto'
                    boxShadow={'sm'}
                    bg='bg-surface'
                    borderRadius='2xl'>
                    <Box
                        flex='1'
                        px={{ base: '4', md: '8', lg: '12' }}
                        py={{ base: '12' }}
                    >
                        <Stack spacing={{ base: '8' }} alignItems={'center'}>
                            <PaymentKoIcon boxSize={'5em'} color={context.settings.theme.colors.palette[500]} />
                            <Text align={'center'} fontSize={'2xl'}>HA OCURRIDO UN ERROR</Text>
                            <Stack spacing={4} direction={{ base: 'column' }} alignItems={'center'}>
                                <Text align='justify'>
                                    El pago no se ha podido completar. No te hemos cobrado nada. Puedes volver a intentarlo y si el error persiste te recomendamos que te pongas en contacto con tu banco.
                                </Text>
                            </Stack>
                            <HStack>
                                <Button
                                    isLoading={isLoading}
                                    variant='outline'
                                    onClick={retryPayment}
                                    rightIcon={<BsArrowRight size={'22'} />}
                                    size='lg'>
                                    Intentar de nuevo
                                </Button>
                            </HStack>
                        </Stack>
                    </Box>

                </Flex>
            }
        </>
    )
}

