import { HStack, Stack, Text } from '@chakra-ui/react'
import { formatPrice, ResourceSize } from '../../../helpers'
import { useHotelContext } from '../../../layout/hotel/HotelLayout'
import { ResizedImageWithDefaultFallback } from '../components/ResizedImage'

type Props = {
  product: any
}

export const ProductItem = ({ product }: Props) => {
  const context = useHotelContext();
  return (
    <HStack spacing={6} justify={'space-between'} alignItems='center'>
      <Stack direction='row' spacing='5' alignItems={'flex-start'}>
        <ResizedImageWithDefaultFallback
          width='80px'
          objectFit={'cover'}
          src={product.image}
          borderRadius='xl'
          resizeTo={ResourceSize.SMALL}
        />
        <Stack spacing='3'>
          <Stack spacing='1'>
            <Text fontWeight='semibold'>{product.name}</Text>
            <Text fontSize='sm' align={{ base: 'justify', md:'left', xl: 'justify' }} fontWeight='thin'> {product.shortDescription}</Text>
          </Stack>
        </Stack>
      </Stack>
      <Text fontWeight='medium'>{formatPrice(product.price, context.settings.legal)}</Text>
    </HStack>
  )
}