import {
    FormControl,
    FormErrorMessage,
    FormLabel,
    Input,
    useToast
} from '@chakra-ui/react';
import { addDoc, collection, serverTimestamp } from 'firebase/firestore';
import { useForm } from 'react-hook-form';
import { IoMdAdd } from 'react-icons/io';
import { useNavigate } from 'react-router';
import { firebaseFirestore } from '../../../firebase';
import { useConsoleContext } from '../../../layout/console/authenticated/AuthenticatedConsoleLayout';
import { showToast } from '../../../helpers';
import { FullForm } from '../components/forms/FullForm';
import { FormControlGroup } from '../components/forms/FormControlGroup';
import { HotelSpecifficSection } from '../components/HotelSpecifficSection';
import { SectionHeader } from '../components/SectionHeader';

type CategoriesFormData = {
    name: string;
}

const WrappedView = () => {
    const toast = useToast();
    const navigate = useNavigate();
    const context = useConsoleContext();

    const save = (data: CategoriesFormData) => new Promise<void>((resolve) => {
        const success = () => {
            showToast(toast, {
                title: 'Categoría creada',
                description: 'La categoría se ha creado correctamente.',
                status: 'success',
            });
            navigate('/console/categories', { replace: true });
        }
        const error = (e: any) => {
            console.error(e);
            showToast(toast, {
                title: 'Error al crear la categoría',
                description: 'No se ha podido completar la acción. Inténtalo de nuevo.',
                status: 'error'
            });
        }
        const ref = collection(
            firebaseFirestore,
            'clients',
            context.settings.selectedClient!!.id,
            'hotels',
            context.settings.selectedHotel!!.id,
            'categories'
        );
        addDoc(ref, {
            ...data,
            clientId: context.settings.selectedClient!!.id,
            created: serverTimestamp(),
        })
            .then(success)
            .catch(error)
            .finally(resolve);
    });


    const {
        handleSubmit,
        register,
        formState: { errors, isSubmitting }
    } = useForm<CategoriesFormData>();

    return (
        <FullForm
            onSubmit={handleSubmit(save)}
            cancelTo={'/console/categories'}
            isSubmitting={isSubmitting}
            canSubmit={true}>
            <FormControlGroup
                title={'Datos generales'}
                description={'Nombra la categoría'}>
                <FormControl isRequired isInvalid={errors?.name !== undefined}>
                    <FormLabel htmlFor='name'>Nombre</FormLabel>
                    <Input
                        id='name'
                        type='text'
                        {...register('name', {
                            required: 'Campo obligatorio',
                            maxLength: {
                                value: 80,
                                message: 'Máximo 80 caracteres'
                            }
                        })}
                    />
                    <FormErrorMessage>
                        {errors.name &&
                            <>{errors.name.message}</>
                        }
                    </FormErrorMessage>
                </FormControl>
            </FormControlGroup>
        </FullForm>
    )
}

export const CategoriesForm = () => {
    return (
        <>
            <SectionHeader
                title={'Nueva categoría'}
                subtitle='Completa los campos y pulsa en Enviar'
                icon={IoMdAdd} />
            <HotelSpecifficSection>
                <WrappedView />
            </HotelSpecifficSection>
        </>
    )
}