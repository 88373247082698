import { Text, FormControl, FormErrorMessage, FormLabel, IconButton, Input, InputGroup, InputRightElement, NumberDecrementStepper, NumberIncrementStepper, NumberInput, NumberInputField, NumberInputStepper, Switch, Textarea, useToast, Wrap } from '@chakra-ui/react';
import { addDoc, collection, doc, serverTimestamp, updateDoc } from 'firebase/firestore';
import { useEffect, useState } from 'react';
import { useCollectionData, useDocumentData } from 'react-firebase-hooks/firestore';
import { Controller, useFieldArray, useForm } from 'react-hook-form';
import { IoMdAdd, IoMdCreate } from 'react-icons/io';
import { useNavigate, useParams } from 'react-router';
import { firebaseFirestore } from '../../../firebase';
import { useConsoleContext } from '../../../layout/console/authenticated/AuthenticatedConsoleLayout';
import { showToast } from '../../../helpers';
import { FullForm } from '../components/forms/FullForm';
import { Dropzone } from '../components/forms/Dropzone';
import { FormControlGroup } from '../components/forms/FormControlGroup';
import { HotelSpecifficSection } from '../components/HotelSpecifficSection';
import { SectionHeader } from '../components/SectionHeader';
import { IoMdRemoveCircle, IoMdAddCircle } from 'react-icons/io'
import { CreatableSelect } from 'chakra-react-select';

type Features = {
    name: string;
}

type ProductsFormData = {
    name: string;
    shortDescription: string;
    description: string;
    price: number;
    features: Features[];
    categories: string[];
    disabled: boolean;
}

const WrappedView = () => {
    const { id } = useParams();
    const toast = useToast();
    const navigate = useNavigate();
    const context = useConsoleContext();

    const isNew = id === 'new';


    const [clientId, hotelId, productId] = isNew ? [null, null, null] : id!!.split('-');
    const [imageRef, setImageRef] = useState<string>();

    const [categoriesDataSnapshot] = useCollectionData(collection(
        firebaseFirestore,
        'clients',
        clientId || context.settings.selectedClient?.id || '?',
        'hotels',
        hotelId || context.settings.selectedHotel?.id || '?',
        'categories'
    ));

    const [
        productDataSnapshot,
        isLoadingProductDataSnapshot,
        loadingProductDataSnapshotError
    ] = useDocumentData(doc(
        firebaseFirestore,
        'clients',
        clientId || '?',
        'hotels',
        hotelId || '?',
        'products',
        productId || '?',
    ));

    useEffect(() => {
        if (!isNew && loadingProductDataSnapshotError) {
            console.error(loadingProductDataSnapshotError);
            showToast(toast, {
                title: 'Error al obtener la información',
                status: 'error'
            });
        }
    }, [loadingProductDataSnapshotError])

    const save = (data: ProductsFormData) => new Promise<void>((resolve) => {
        const success = () => {
            showToast(toast, {
                title: isNew ? 'Producto creado' : 'Producto actualizado',
                description: isNew ?
                    'El producto se ha creado correctamente.' :
                    'El producto se ha actualizado correctamente.',
                status: 'success',
            });
            navigate('/console/products', { replace: true });
        }
        const error = (e: any) => {
            console.error(e);
            showToast(toast, {
                title: isNew ? 'Error al crear producto' : 'Error al actualizar el producto',
                description: 'No se ha podido completar la acción. Inténtalo de nuevo.',
                status: 'error'
            });
        }
        if (isNew) {
            const ref = collection(
                firebaseFirestore,
                'clients',
                context.settings.selectedClient!!.id,
                'hotels',
                context.settings.selectedHotel!!.id,
                'products'
            );
            addDoc(ref, {
                ...data,
                features: data.features?.map(f => f.name),
                image: imageRef,
                clientId: context.settings.selectedClient!!.id,
                created: serverTimestamp(),
                updated: serverTimestamp()
            })
                .then(success)
                .catch(error)
                .finally(resolve);
        } else {
            const ref = doc(
                firebaseFirestore,
                'clients',
                clientId!!,
                'hotels',
                hotelId!!,
                'products',
                productId!!
            );
            updateDoc(ref, {
                ...data,
                features: data.features?.map(f => f.name),
                image: imageRef,
                updated: serverTimestamp()
            })
                .then(success)
                .catch(error)
                .finally(resolve);
        }
    });


    const {
        handleSubmit,
        control,
        register,
        formState: { errors, isSubmitting }
    } = useForm<ProductsFormData>({
        values: {
            ...(productDataSnapshot as ProductsFormData),
            disabled: productDataSnapshot?.disabled === undefined ? false : productDataSnapshot?.disabled,
            price: parseFloat(productDataSnapshot?.price ?? 0) || 10.50,
            features: isNew ?
                [{ name: 'Característica 1' }] :
                productDataSnapshot?.features?.map((f: string) => ({ name: f })) ?? []
        }
    });


    const { fields, append, remove } = useFieldArray({
        control,
        name: 'features'
    });

    return (
        <FullForm
            onSubmit={handleSubmit(save)}
            cancelTo={'/console/products'}
            isSubmitting={isSubmitting}
            canSubmit={!isLoadingProductDataSnapshot}>
            <FormControlGroup
                title={'Datos generales'}
                description={'Nombra el producto y sube una imagen.'}>
                <FormControl isRequired isInvalid={errors?.name !== undefined}>
                    <FormLabel htmlFor='name'>Nombre</FormLabel>
                    <Input
                        id='name'
                        type='text'
                        {...register('name', {
                            required: 'Campo obligatorio',
                            maxLength: {
                                value: 80,
                                message: 'Máximo 80 caracteres'
                            }
                        })}
                    />
                    <FormErrorMessage>
                        {errors.name &&
                            <>{errors.name.message}</>
                        }
                    </FormErrorMessage>
                </FormControl>
                <FormControl>
                    <FormLabel htmlFor='image'>Imagen</FormLabel>
                    <Dropzone
                        previewReference={productDataSnapshot?.image}
                        onFileUploaded={setImageRef} />
                </FormControl>
            </FormControlGroup>
            <FormControlGroup
                title={'Descripción'}
                description={'Describe qué se ofrece con este producto.'}>
                <FormControl isRequired isInvalid={errors?.shortDescription !== undefined}>
                    <FormLabel htmlFor='shortDescription'>Descripción corta</FormLabel>
                    <Textarea
                        id='shortDescription'
                        height={100}
                        resize='none'
                        {...register('shortDescription', {
                            required: 'Campo obligatorio',
                            maxLength: {
                                value: 200,
                                message: 'Máximo 200 caracteres'
                            }
                        })}
                    />
                    <FormErrorMessage>
                        {errors.shortDescription &&
                            <>{errors.shortDescription.message}</>
                        }
                    </FormErrorMessage>
                </FormControl>
                <FormControl isRequired isInvalid={errors?.description !== undefined}>
                    <FormLabel htmlFor='description'>Descripción</FormLabel>
                    <Textarea
                        id='description'
                        height={300}
                        resize='none'
                        {...register('description', {
                            required: 'Campo obligatorio',
                        })}
                    />
                    <FormErrorMessage>
                        {errors.description &&
                            <>{errors.description.message}</>
                        }
                    </FormErrorMessage>
                </FormControl>
                <FormControl isInvalid={errors?.categories !== undefined}>
                    <FormLabel htmlFor='categories'>Categorías</FormLabel>
                    <Controller
                        control={control}
                        name='categories'
                        render={({ field: { onChange, value, ref } }) => (
                            <CreatableSelect
                                placeholder='Selecciona o agrega categorías'
                                onCreateOption={(i) => {
                                    addDoc(
                                        collection(
                                            firebaseFirestore,
                                            'clients',
                                            clientId || context.settings.selectedClient!!.id,
                                            'hotels',
                                            hotelId || context.settings.selectedHotel!!.id,
                                            'categories'
                                        ),
                                        {
                                            name: i,
                                            clientId: clientId || context.settings.selectedClient!!.id,
                                            created: serverTimestamp(),
                                            updated: serverTimestamp()
                                        }
                                    );
                                    const newVal =[];
                                    if(value){
                                        newVal.push(...value);
                                    }
                                    newVal.push(i);
                                    onChange(newVal);;
                                }}
                                options={categoriesDataSnapshot?.map(o => ({ label: o.name, value: o.name }))}
                                value={value?.map(v => ({ label: v, value: v }))}
                                onChange={(nv) => onChange(nv.map(v => v.value))}
                                formatCreateLabel={(i) => (`Crear categoría "${i}"`)}
                                noOptionsMessage={(_i) => 'Escribe el nombre de la categoría para crearla.'}
                                ref={ref}
                                isMulti
                            />
                        )}
                    />
                    <FormErrorMessage>
                        {errors.categories &&
                            <>{errors.categories.message}</>
                        }
                    </FormErrorMessage>
                </FormControl>
                <FormLabel>
                    Características destacadas
                    <IconButton
                        size='sm'
                        variant='ghost'
                        isDisabled={fields.length >= 5}
                        color='brand.600'
                        ml={4}
                        aria-label='Añadir'
                        onClick={() =>
                            append({ name: `Característica ${fields.length + 1}` })
                        }
                        icon={<IoMdAddCircle size='2.5em' />}
                    />
                </FormLabel>
                {fields.map((field, index) => {
                    return (
                        <FormControl key={field.id} isRequired isInvalid={errors.features && errors.features[index] !== undefined}>
                            <InputGroup>
                                <Input
                                    id={`features.${index}`}
                                    {...register(`features.${index}.name`, {
                                        required: 'Campo obligatorio',
                                        maxLength: {
                                            value: 100,
                                            message: 'Máximo 100 caracteres'
                                        }
                                    })}
                                />
                                <InputRightElement>
                                    <IconButton
                                        size='sm'
                                        variant='ghost'
                                        color='brand.600'
                                        aria-label='Borrar'
                                        onClick={() => remove(index)}
                                        icon={<IoMdRemoveCircle size='1.5em' />}
                                    />
                                </InputRightElement>
                            </InputGroup>
                            <FormErrorMessage>
                                {(errors.features && errors.features[index] !== undefined) &&
                                    <>{errors.features[index]?.message}</>
                                }
                            </FormErrorMessage>
                        </FormControl>

                    )
                })}
            </FormControlGroup>
            <FormControlGroup
                title={'Precio y disponibilidad'}
                description={'Establece el precio del producto y si actualmente está disponible para la compra.'}>
                <FormControl isRequired isInvalid={errors?.price !== undefined}>
                    <FormLabel htmlFor='price'>Precio</FormLabel>
                    <Controller
                        control={control}
                        name='price'
                        rules={{
                            required: 'Campo obligatorio',
                            min: {
                                value: 0.1,
                                message: `El precio debe ser mayor a 0.01`
                            },
                            max: {
                                value: 9999,
                                message: `El precio debe ser menor a 99999`
                            }
                        }}
                        render={({ field: { onChange, value, ref } }) => (
                            <NumberInput
                                id='price'
                                ref={ref}
                                onChange={(val) => { onChange(parseFloat(val)) }}
                                value={value}
                                precision={2}
                                step={0.1}>
                                <NumberInputField />
                                <NumberInputStepper>
                                    <NumberIncrementStepper />
                                    <NumberDecrementStepper />
                                </NumberInputStepper>
                            </NumberInput>
                        )}
                    />

                    <FormErrorMessage>
                        {errors.price &&
                            <>{errors.price.message}</>
                        }
                    </FormErrorMessage>
                </FormControl>
                <FormControl isInvalid={errors.disabled !== undefined} display='flex' alignItems='center'>
                    <FormLabel htmlFor='disabled' mb='0'>
                        ¿Deshabilitado?
                    </FormLabel>
                    <Controller
                        control={control}
                        name='disabled'
                        render={({ field: { onChange, value, ref } }) => (
                            <Switch
                                isChecked={value}
                                onChange={onChange}
                                ref={ref}
                                size='lg'
                                colorScheme={'brand'} />
                        )}
                    />
                </FormControl>
            </FormControlGroup>
        </FullForm>
    )
}

export const ProductsForm = () => {
    const { id } = useParams();
    const isNew = id === 'new';
    return (
        <>
            <SectionHeader
                title={isNew ? 'Nuevo producto' : 'Actualizar producto'}
                subtitle='Completa los campos y pulsa en Enviar'
                icon={isNew ? IoMdAdd : IoMdCreate} />
            {isNew &&
                <HotelSpecifficSection>
                    <WrappedView />
                </HotelSpecifficSection>
            }
            {!isNew &&
                <WrappedView />
            }
        </>
    )
}