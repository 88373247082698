import { ChevronDownIcon } from '@chakra-ui/icons'
import {
    Box,
    Stack,
    Center,
    Spinner,
    Badge,
    Divider,
    Flex,
    Menu,
    MenuButton,
    Button,
    MenuList,
    MenuItem,
    Text,
    useToast,
    useDisclosure
} from '@chakra-ui/react'
import { AiFillFilePdf } from 'react-icons/ai'
import { GiCancel, GiCheckMark } from 'react-icons/gi'
import {
    getColorForOrderStatusLabel,
    getOrderStatusLabel,
    formatDate,
    formatPrice,
    showToast
} from '../../../helpers'
import { DataLabel } from './DataLabel'
import { useEffect, useState } from 'react'
import { getDownloadURL, ref } from '@firebase/storage';
import { firebaseFunctions, firebaseStorage } from '../../../firebase'
import { ConfirmationModal } from '../components/ConfirmationModal'
import { httpsCallable } from '@firebase/functions'
import { useConsoleContext } from '../../../layout/console/authenticated/AuthenticatedConsoleLayout'
import { Role } from '../../../types/role'

const claimOrderCallable = httpsCallable(firebaseFunctions, 'orders-claim');
const cancelOrderCallable = httpsCallable(firebaseFunctions, 'orders-cancel');
const rebuildReceiptCallable = httpsCallable(firebaseFunctions, 'orders-rebuildReceipt');

type OrderDetailsProps = {
    orderId: string;
    loading: boolean;
    orderData?: any;
    legalData?: any;
}
export const OrderDetails = (props: OrderDetailsProps) => {

    const context = useConsoleContext();
    const { orderId, loading, legalData, orderData } = props;

    const [receiptUrl, setReceiptUrl] = useState<string>();

    const claimCofirmationModalDisclosure = useDisclosure();
    const cancelCofirmationModalDisclosure = useDisclosure();
    const rebuildReceiptDisclosure = useDisclosure();

    const [isClaiming, setIsClaiming] = useState(false);
    const [isCancelling, setIsCancelling] = useState(false);
    const [isRebuildingReceipt, setIsRebuildingReceipt] = useState(false);

    useEffect(() => {
        if (orderData?.receipt) {
            getDownloadURL(ref(firebaseStorage, orderData.receipt))
                .then(setReceiptUrl)
                .catch(console.error)
        }

    }, [orderData]);

    const toast = useToast();


    const onClaimConfirm = () => {
        setIsClaiming(true);
        claimOrderCallable({ orderId })
            .then(() => {
                claimCofirmationModalDisclosure.onClose();
                showToast(toast, {
                    title: '¡Correcto!',
                    description: 'El vale se ha canjeado correctamente',
                    status: 'success'
                });
            })
            .catch((e) => {
                console.error(e);
                showToast(toast, {
                    title: '¡Error!',
                    description: 'Error al canjear el vale. Inténtalo de nuevo más tarde.',
                    status: 'error'
                });
            })
            .finally(() => {
                setIsClaiming(false);
            })
    }


    const onCancelConfirm = () => {
        setIsCancelling(true);
        cancelOrderCallable({ orderId })
            .then(() => {
                cancelCofirmationModalDisclosure.onClose();
                showToast(toast, {
                    title: '¡Correcto!',
                    description: 'El vale se ha cancelado correctamente',
                    status: 'success'
                });
            })
            .catch((e) => {
                console.error(e);
                showToast(toast, {
                    title: '¡Error!',
                    description: 'Error al cancelar el vale. Inténtalo de nuevo más tarde.',
                    status: 'error'
                });
            })
            .finally(() => {
                setIsCancelling(false);
            })
    }

    const onRebuildReceipt = () => {
        setIsRebuildingReceipt(true);
        rebuildReceiptCallable({ orderId })
            .then(() => {
                rebuildReceiptDisclosure.onClose();
                showToast(toast, {
                    title: '¡Correcto!',
                    description: 'El vale se ha regenerado correctamente',
                    status: 'success'
                });
            })
            .catch((e) => {
                console.error(e);
                showToast(toast, {
                    title: '¡Error!',
                    description: 'Error al regenerar el vale. Inténtalo de nuevo más tarde.',
                    status: 'error'
                });
            }).finally(() => {
                setIsRebuildingReceipt(false);
            });
    }

    return (
        <>
            <ConfirmationModal
                title='Regenerar y reenviar vale'
                isLoading={isRebuildingReceipt}
                disclosure={rebuildReceiptDisclosure}
                onConfirm={onRebuildReceipt} />
            <ConfirmationModal
                title='Marcar vale como canjeado'
                isLoading={isClaiming}
                disclosure={claimCofirmationModalDisclosure}
                onConfirm={onClaimConfirm} />
            <ConfirmationModal
                title='Marcar vale como cancelado'
                isLoading={isCancelling}
                disclosure={cancelCofirmationModalDisclosure}
                onConfirm={onCancelConfirm} />
            <Stack
                direction={{ base: 'column', xl: 'row' }}
                spacing={{ base: '5', xl: '8' }}
            >
                <Box flexShrink={0} width={{ base: '100%', xl: '250px' }}>
                    <Text fontSize='lg' fontWeight='medium'>
                        Detalles de la compra
                    </Text>
                    <Text color='muted' fontSize='sm'>
                        Información detallada de la compra
                    </Text>
                </Box>
                <Box bg='bg-surface' boxShadow='sm' borderRadius='lg' flexGrow={1}>
                    {loading &&
                        <Box p={8}>
                            <Center>
                                <Spinner color='brand.600' size='lg' />
                            </Center>
                        </Box>
                    }
                    {!loading &&
                        <Box>
                            <Stack p={12} direction={{ base: 'column', md: 'row' }} spacing={{ base: 2, md: '24' }}>
                                <Stack flexGrow={1}>
                                    <DataLabel title='Id' value={orderId} />
                                    <DataLabel
                                        title='Nombre'
                                        value={`${orderData?.customer.name} ${orderData?.customer.lastName}`} />
                                    <DataLabel
                                        title='Correo electrónico'
                                        value={orderData?.customer.email} />
                                    <DataLabel
                                        title='Teléfono'
                                        value={orderData?.customer.phone} />
                                    <DataLabel
                                        title='Producto'
                                        value={orderData?.items.find((i: any) => i.type === 'PRODUCT')?.name} />
                                </Stack>
                                <Stack flexGrow={1}>
                                    <DataLabel title='Estado' value={
                                        <Badge size='md' variant='solid' colorScheme={getColorForOrderStatusLabel(orderData?.status)}>
                                            {getOrderStatusLabel(orderData?.status)}
                                        </Badge>
                                    } />
                                    <DataLabel
                                        title='Fecha de compra'
                                        value={formatDate(orderData?.created, legalData as any)} />
                                    <DataLabel
                                        title='Fecha de canje'
                                        value={orderData?.claim?.date ? formatDate(orderData?.claimed_at, legalData as any) : '-'} />
                                    <DataLabel
                                        title='Canjeado por'
                                        value={orderData?.claim?.userName ?? '-'} />
                                    <DataLabel
                                        title='Importe'
                                        value={formatPrice(orderData?.amount, legalData as any)} />
                                </Stack>
                            </Stack>
                            <Divider mt={4} />
                            <Flex direction='row-reverse' py='4' px={{ base: '4', md: '6' }}>
                                <Menu>
                                    <MenuButton
                                        as={Button}
                                        rightIcon={<ChevronDownIcon />}
                                        variant='primary'>
                                        Acciones
                                    </MenuButton>
                                    <MenuList>
                                        <MenuItem
                                            as='a'
                                            href={receiptUrl}
                                            target='_blank'
                                            isDisabled={orderData?.status === 'AWAITING_PAYMENT' || !receiptUrl}
                                            icon={<AiFillFilePdf size={'2em'} />}>
                                            Descargar vale
                                        </MenuItem>
                                            <MenuItem
                                                onClick={rebuildReceiptDisclosure.onOpen}
                                                isDisabled={orderData?.status !== 'PAID' || context.isAgent()}
                                                icon={<AiFillFilePdf size={'2em'} />}>
                                                Regenerar y reenviar vale
                                            </MenuItem>
                                        <MenuItem
                                            isDisabled={orderData?.status !== 'PAID'}
                                            onClick={claimCofirmationModalDisclosure.onOpen}
                                            icon={<GiCheckMark size={'2em'} />}>
                                            Canjear
                                        </MenuItem>
                                        <MenuItem
                                            isDisabled={orderData?.status !== 'PAID'}
                                            onClick={cancelCofirmationModalDisclosure.onOpen}
                                            icon={<GiCancel size={'2em'} />}>
                                            Cancelar
                                        </MenuItem>
                                    </MenuList>
                                </Menu>
                            </Flex>
                        </Box>
                    }
                </Box>
            </Stack>
        </>
    )
}