import { SectionHeader } from '../components/SectionHeader'
import { IoMdAdd, IoMdCreate } from 'react-icons/io';
import {
    FormControl,
    FormErrorMessage,
    FormLabel,
    Input,
    Select,
    useToast
} from '@chakra-ui/react';
import { useNavigate, useParams } from 'react-router-dom';
import { useForm } from 'react-hook-form';
import { firebaseFirestore } from '../../../firebase';
import {
    doc,
    updateDoc,
    serverTimestamp,
    collection,
    addDoc
} from '@firebase/firestore';
import { useEffect } from 'react';
import { showToast } from '../../../helpers';
import { FullForm } from '../components/forms/FullForm';
import { FormControlGroup } from '../components/forms/FormControlGroup';
import { useDocumentData } from 'react-firebase-hooks/firestore';
import { Validators } from '../../../validationPatterns';

type ClientFormData = {
    name: string;
    country?: string;
    contactPerson?: {
        name?: string;
        email?: string;
        phone?: string;
    }
}

export const ClientsForm = () => {
    const { id } = useParams();
    const isNew = id === 'new';
    const toast = useToast();
    const navigate = useNavigate();

    const [clientsDataSnapshot, isLoading, errorLoadingClients] = useDocumentData(
        doc(firebaseFirestore, 'clients', id!!)
    );

    useEffect(() => {
        if (errorLoadingClients) {
            console.error(errorLoadingClients);
            showToast(toast, {
                title: 'Error al obtener la información del cliente',
                status: 'error'
            });
        }
    }, [errorLoadingClients]);

    const {
        handleSubmit,
        register,
        formState: { errors, isSubmitting },
    } = useForm<ClientFormData>({
        values: clientsDataSnapshot as ClientFormData,
        resetOptions:{
            keepDirtyValues:true
        }
    });

    const send = (data: ClientFormData) => {
        return new Promise<void>((resolve) => {
            const success = () => {
                showToast(toast, {
                    title: isNew ? 'Cliente creado' : 'Cliente actualizado',
                    description: isNew ?
                        'El cliente se ha creado correctamente.' :
                        'El cliente se ha actualizado correctamente.',
                    status: 'success',
                });
                navigate('/console/clients', { replace: true });
            }
            const error = (e: any) => {
                console.error(e);
                showToast(toast, {
                    title: isNew ?
                        'Error al crear cliente' :
                        'Error al actualizar el cliente',
                    description: 'No se ha podido completar la acción. Inténtalo de nuevo.',
                    status: 'error',
                });
            }
            if (isNew) {
                addDoc(
                    collection(firebaseFirestore, 'clients'), {
                    ...data,
                    created: serverTimestamp(),
                    updated: serverTimestamp()
                })
                    .then(success)
                    .catch(error)
                    .finally(resolve);
            } else {
                updateDoc(
                    doc(firebaseFirestore, 'clients', id!!), {
                    ...data,
                    updated: serverTimestamp()
                })
                    .then(success)
                    .catch(error)
                    .finally(resolve);
            }
        });
    }

    return (
        <>
            <SectionHeader
                title={isNew ? 'Nuevo cliente' : 'Actualizar cliente'}
                subtitle='Completa los campos y pulsa en Enviar'
                icon={isNew ? IoMdAdd : IoMdCreate} />
            <FullForm
                onSubmit={handleSubmit(send)}
                cancelTo={'/console/clients'}
                isSubmitting={isSubmitting}
                canSubmit={!isLoading}>
                <FormControlGroup
                    title={'Datos generales'}
                    description='Completa los datos generales'>
                    <FormControl isRequired isInvalid={errors.name !== undefined}>
                        <FormLabel htmlFor='name'>Nombre</FormLabel>
                        <Input
                            id='name'
                            type='text'
                            {...register('name', {
                                required: 'Campo obligatorio.',
                            })} />
                        <FormErrorMessage>
                            {errors.name &&
                                <>{errors.name.message}</>
                            }
                        </FormErrorMessage>
                    </FormControl>
                    <FormControl isRequired isInvalid={errors.country !== undefined}>
                        <FormLabel htmlFor='country'>País</FormLabel>
                        <Select
                            id='country'
                            placeholder='Selecciona un país'
                            {...register('country', {
                                required: 'Campo obligatorio.',
                            })}>
                            <option value='Spain'>España</option>
                            <option value='Mexico'>México</option>
                            <option value='Colombia'>Colombia</option>
                            <option value='Argentina'>Argentina</option>
                            <option value='United States of America'>USA</option>
                        </Select>
                        <FormErrorMessage>
                            {errors.country &&
                                <>{errors.country.message}</>
                            }
                        </FormErrorMessage>
                    </FormControl>
                </FormControlGroup>
                <FormControlGroup
                    title={'Persona de contacto'}
                    description='Completa los datos de la persona de contacto'>
                    <FormControl isInvalid={errors.contactPerson?.name !== undefined}>
                        <FormLabel htmlFor='contactPerson.name'>Nombre y apellidos</FormLabel>
                        <Input
                            id='contactPerson.name'
                            type='text'
                            {...register('contactPerson.name')} />
                        <FormErrorMessage>
                            {errors.contactPerson?.name &&
                                <>{errors.contactPerson?.name.message}</>
                            }
                        </FormErrorMessage>
                    </FormControl>
                    <FormControl isInvalid={errors.contactPerson?.email !== undefined}>
                        <FormLabel htmlFor='conctactPerson.email'>Correo electrónico</FormLabel>
                        <Input
                            id='contactPerson.email'
                            type='email'
                            {...register('contactPerson.email', {
                                pattern: {
                                    value: Validators.email,
                                    message: 'Introduce un correo electrónico válido.'
                                }
                            })} />
                        <FormErrorMessage>
                            {errors.contactPerson?.email &&
                                <>{errors.contactPerson?.email.message}</>
                            }
                        </FormErrorMessage>
                    </FormControl>
                    <FormControl isInvalid={errors.contactPerson?.phone !== undefined}>
                        <FormLabel htmlFor='contactPerson.phone'>Teléfono</FormLabel>
                        <Input
                            id='contactPerson.phone'
                            type='text'
                            {...register('contactPerson.phone')} />
                        <FormErrorMessage>
                            {errors.contactPerson?.phone &&
                                <>{errors.contactPerson?.phone.message}</>
                            }
                        </FormErrorMessage>
                    </FormControl>
                </FormControlGroup>
            </FullForm>
        </>
    )
}