import { Box, Text } from '@chakra-ui/react';
import { useHotelContext } from '../../../layout/hotel/HotelLayout';

type SectionHeaderProps = {
    title: string;
    subTitle?: string;
}
export const SectionHeader = ({ title, subTitle }: SectionHeaderProps) => {
    const context = useHotelContext();
    if (context.isWidget) {
        return null;
    }
    return (
        <Box>
            <Text as='h1' fontSize={'3xl'} fontWeight={'thin'}>
                {title}
            </Text>
            {subTitle &&
                <Text as='h2' fontSize={'xl'} mt={2}>
                    {subTitle}
                </Text>
            }
        </Box>
    )
}