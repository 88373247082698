import { Stack, Center, VStack, CircularProgress, Icon, Image, Text, useToast } from '@chakra-ui/react';
import { nanoid } from 'nanoid';
import { useEffect, useState } from 'react';
import { useDropzone } from 'react-dropzone';
import { FiUploadCloud } from 'react-icons/fi';
import ImgPlaceholder from '../../../../assets/img/img-placeholder.svg';
import { firebaseStorage } from '../../../../firebase';
import { showToast } from '../../../../helpers';
import { getDownloadURL, ref, uploadBytes } from '@firebase/storage';

type DropzoneProps = {
    isDisabled?: boolean;
    previewReference?: string;
    onFileUploaded?: (ref: string) => void
}

export const Dropzone = (props: DropzoneProps) => {
    const toast = useToast();

    const [isUploading, setIsUploading] = useState(false);

    const [logoDownloadUrl, setLogoDownloadUrl] = useState<string>();

    useEffect(() => {
        if (props.previewReference) {
            getDownloadURL(ref(firebaseStorage, props.previewReference))
                .then(setLogoDownloadUrl)
                .catch(console.error)
        }else{
            setLogoDownloadUrl(undefined);
        }

    }, [props.previewReference]);

    const uploadFile = (files: File[]) => {
        const [_type, extension] = files[0].type.split('/');
        const storageRef = ref(firebaseStorage, `uploads/images/${nanoid()}.${extension}`);
        setIsUploading(true);
        uploadBytes(storageRef, files[0])
            .then((snapshot) => {
                if (props.onFileUploaded) {
                    props.onFileUploaded(snapshot.ref.toString());
                }
                getDownloadURL(ref(firebaseStorage, snapshot.ref.toString()))
                    .then(setLogoDownloadUrl)
                    .catch(console.error);
            })
            .catch((e) => {
                console.error(e);
                showToast(toast, {
                    title: 'Error al subir la imagen. Vuelve a intentarlo',
                    status: 'error'
                });
            })
            .finally(() => {
                setIsUploading(false);
            });
    }

    const onInvalidFile = () => {
        showToast(toast, {
            title: 'Revisa que el fichero sea una imagen y pese menos de 5MB',
            status: 'error',
        });
    }
    const {
        open,
        isDragActive,
        getRootProps,
        getInputProps,
    } = useDropzone({
        disabled: props.isDisabled || isUploading,
        onDropAccepted(files) {
            uploadFile(files);
        },
        onDropRejected(_fileRejections) {
            onInvalidFile();
        },
        noClick: true,
        accept: {
            'image/*': ['.png', '.webp', '.jpeg', '.jpg']
        },
        multiple: false,
        maxSize: 5 * 1024 * 1024,
        maxFiles: 1
    });

    const getBorderColor = () => {
        if (isDragActive) {
            return 'brand.800';
        }
        return 'gray.200';
    }

    return (
        <Stack spacing={4} width={'full'} direction={'row'} alignItems='center'>
            <Image
                src={logoDownloadUrl}
                boxSize={{ base: '80px', md: '120px' }}
                objectFit='contain'
                fallbackSrc={ImgPlaceholder} />
            <Center
                width={'full'}
                borderWidth='1px'
                borderRadius='lg'
                borderColor={getBorderColor()}
                px='6'
                py='4'
                {...getRootProps({ className: 'dropzone' })}
            >
                <input {...getInputProps()} />
                <VStack spacing='3'>
                    {isUploading &&
                        <CircularProgress isIndeterminate color='brand.900' />
                    }
                    {!isUploading &&
                        <Icon as={FiUploadCloud} boxSize='10' color='violet.900' />
                    }
                    <VStack spacing='1'>
                        <Center onClick={open}>
                            <Text fontSize='sm' color='muted' align={'center'}>
                                <Text as='span' color='violet.900' fontWeight='medium' cursor={'pointer'}>
                                    Haz click aquí&nbsp;
                                </Text>
                                para subir una imágen o arrástrala aquí
                            </Text>
                        </Center>
                        <Text fontSize='xs' color='muted'>
                            PNG, JPG o WEBP hasta 5MB
                        </Text>
                    </VStack>
                </VStack>
            </Center>
        </Stack>
    )
}
