import { TableCaption, Stack, Icon, Text } from '@chakra-ui/react';
import { TfiFaceSad } from 'react-icons/tfi';

export const TableNoResultsMessage = () => (
    <TableCaption>
        <Stack alignItems={'center'} p={4}>
            <Icon as={TfiFaceSad} color='brand.800' boxSize='12' />
            <Text fontSize={'lg'} color='muted'>
                Sin resultados
            </Text>
        </Stack>
    </TableCaption>
)