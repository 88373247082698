import { Navigate, Outlet } from 'react-router'
import { Container, Spinner, Stack } from '@chakra-ui/react'
import { firebaseAuth } from '../../../firebase'
import { useEffect, useState } from 'react'

export const PublicConsoleLayout = () => {

    const [loading, setIsLoading] = useState(true);
    const [isLoggedIn, setIsLoggedIn] = useState(false);

    useEffect(() => {
        const unsubscribe = firebaseAuth.onAuthStateChanged((user) => {
            localStorage.removeItem('client');
            localStorage.removeItem('hotel');
            setIsLoading(false);
            setIsLoggedIn(user !== null);
        });
        return unsubscribe;
    }, []);

    return (
        <Container maxW='xl' py={{ base: '16', md: '36' }} display='flex' flexGrow={1}>
            {loading &&
                <Stack justify={'center'} alignItems={'center'} width='full'>
                    <Spinner size='xl' color={'violet.900'} />
                </Stack>
            }
            {(!loading && !isLoggedIn) &&
                <Stack spacing='8' width='full'>
                    <Outlet />
                </Stack>
            }
            {(!loading && isLoggedIn) &&
                <Navigate to='/console' replace />
            }
        </Container>
    )
}