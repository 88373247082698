import { Center, Text, Spinner, Box, Flex, Stack, Button, HStack, Show } from '@chakra-ui/react';
import { doc } from 'firebase/firestore';
import { useEffect, useState } from 'react';
import { useDocumentData } from 'react-firebase-hooks/firestore';
import { Link, Navigate, useParams } from 'react-router-dom';
import { firebaseFirestore, firebaseStorage, logAnalyticsEvent } from '../../../firebase';
import { useHotelContext } from '../../../layout/hotel/HotelLayout';
import { PaymentOkIcon } from './PaymentOkIcon';
import { MailIcon } from './MailIcon';
import { BsArrowRight, BsFilePdf } from 'react-icons/bs';
import { getDownloadURL, ref } from '@firebase/storage';

export const PaymentOk = () => {
    const { orderId } = useParams();
    const context = useHotelContext();
    const [
        orderDataSnapshot,
        isLoadingOrderData,
        hasOrderDataLoadingError
    ] = useDocumentData(
        doc(
            firebaseFirestore,
            'orders',
            orderId!!
        )
    );

    const [receiptDownloadUrl, setReceiptDownloadUrl] = useState<string>();

    useEffect(() => {
        if (hasOrderDataLoadingError) {
            console.error(hasOrderDataLoadingError);
        }
    }, [hasOrderDataLoadingError]);


    useEffect(() => {
        if (orderDataSnapshot?.receipt) {
            getDownloadURL(ref(firebaseStorage, orderDataSnapshot.receipt))
                .then(setReceiptDownloadUrl)
                .catch((e) => {
                    console.error(e);
                })
        }
        if (orderDataSnapshot) {
            logAnalyticsEvent('purchase', {
                transaction_id:orderId,
                currency: orderDataSnapshot.currency,
                value: orderDataSnapshot.amount,
                items: orderDataSnapshot.items.map((i:any)=>({
                    item_id: i.productId,
                    item_name: i.name,
                    price: i.price,
                    quantity: i.quantity,
                    location_id: context.settings.general.name
                }))
            })
        }
    }, [orderDataSnapshot]);

    if (!isLoadingOrderData && !orderDataSnapshot) {
        return <Navigate to='/not-found' replace />
    }

    return (
        <>
            {isLoadingOrderData &&
                <Center height={'full'}>
                    <Spinner size={'lg'} color='black' />
                </Center>
            }
            {!isLoadingOrderData &&
                <Flex
                    maxW='xl'
                    mx='auto'
                    boxShadow={'sm'}
                    bg='bg-surface'
                    borderRadius='2xl'>
                    <Box
                        flex='1'
                        px={{ base: '4', md: '8', lg: '12' }}
                        py={{ base: '12' }}
                    >
                        <Stack spacing={{ base: '8' }} alignItems={'center'}>
                            <PaymentOkIcon boxSize={'5em'} color={context.settings.theme.colors.palette[500]} />
                            <Text align={'center'} fontSize={'2xl'}>COMPRA REALIZADA CORRECTAMENTE</Text>
                            <Stack spacing={4} direction={{ base: 'row' }} alignItems={'center'}>
                                <Show above='sm'>
                                    <MailIcon boxSize={'1.5em'} />
                                </Show>
                                <Text>
                                    Recibirás un email con la información de compra y el vale con las instrucciones de canjeo.
                                </Text>
                            </Stack>
                            <Stack spacing={4} direction={{ base: 'column', md: 'row' }}>
                                <Button
                                    isLoading={receiptDownloadUrl === undefined}
                                    isDisabled={receiptDownloadUrl === undefined}
                                    size='lg'
                                    variant='solid'
                                    as='a'
                                    href={receiptDownloadUrl}
                                    rightIcon={<BsFilePdf size={'22'} />}
                                    color={context.settings.theme.colors.text}
                                    _hover={{
                                        bg: context.settings.theme.colors.palette[600],
                                        textDecoration: 'none'
                                    }}
                                    _active={{
                                        bg: context.settings.theme.colors.palette[700]
                                    }}
                                    bg={context.settings.theme.colors.palette[500]}>
                                    {'Descargar vale'}
                                </Button>
                                <Button
                                    variant='outline'
                                    as={Link}
                                    rightIcon={<BsArrowRight size={'22'} />}
                                    to={'../products'}
                                    size='lg'>
                                    Seguir comprando
                                </Button>
                            </Stack>
                        </Stack>
                    </Box>

                </Flex>
            }
        </>
    )
}