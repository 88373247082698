import { Box, Button, Divider, Flex, Stack } from '@chakra-ui/react';

type FormProps = {
    children?: React.ReactNode | React.ReactNode[];
    isSubmitting?: boolean;
    canSubmit?: boolean;
    onSubmit?: () => void
}

export const Form = (props: FormProps) => (
    <Box
        noValidate
        as='form'
        onSubmit={props.onSubmit}
    >
        <Stack spacing='5' px={{ base: '4', md: '6' }} py={{ base: '5', md: '6' }}>
            {props.children}
        </Stack>
        <Divider mt={4} />
        <Flex direction='row-reverse' py='4' px={{ base: '4', md: '6' }}>
            <Button
                isDisabled={!props.canSubmit}
                isLoading={props.isSubmitting}
                type='submit'
                variant='primary'>
                Guardar
            </Button>
        </Flex>
    </Box>
)