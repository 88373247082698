import { Stack } from '@chakra-ui/react'

type FormControlRowProps = {
    children?: React.ReactNode | React.ReactNode[]
}
export const FormControlRow = ({ children }: FormControlRowProps) => (
    <Stack
        direction={{ base: 'column', xl: 'row' }}
        spacing={{ base: '5', xl: '6' }}
    >
        {children}
    </Stack>
)