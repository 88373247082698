import { HiUsers } from 'react-icons/hi'
import {
    Text,
    Tbody,
    Td,
    Th,
    Thead,
    Tr,
    useToast
} from '@chakra-ui/react';
import { collection, orderBy, query } from '@firebase/firestore';
import { firebaseFirestore } from '../../../firebase';
import { ListViewHeader } from '../components/ListViewHeader';
import { useCollection } from 'react-firebase-hooks/firestore';
import { BasicTableContainer } from '../components/tables/BasicTableContainer';
import { BasicTable } from '../components/tables/BasicTable';
import { EditButton } from '../components/tables/EditButton';
import { useCallback, useEffect, useState } from 'react';
import { showToast } from '../../../helpers';
import debounce from 'lodash.debounce';
import { IoMdAdd } from 'react-icons/io';

export const ClientList = () => {
    const toast = useToast();

    const [searchQuery, setSearchQuery] = useState<string>();
    const changeHandler = (event: any) => setSearchQuery(event.target.value);
    const debouncedChangeHandler = useCallback(debounce(changeHandler, 300), []);

    const [clientsQuerySnapshot, isLoading, clientsLoadingError] = useCollection(
        query(collection(firebaseFirestore, 'clients'), orderBy('updated', 'desc'))
    );

    useEffect(() => {
        if (clientsLoadingError) {
            console.error(clientsLoadingError);
            showToast(toast, {
                title: 'Error al obtener el listado de clientes.',
                status: 'error'
            });
        }
    }, [clientsLoadingError])

    useEffect(() => {
        return () => {
            debouncedChangeHandler.cancel();
        }
    }, []);

    const filteredClients = clientsQuerySnapshot?.docs.filter((d) => {
        if (!searchQuery) return d;
        const lowerCaseQuery = searchQuery.toLowerCase();
        return d.data().name.toLowerCase().includes(lowerCaseQuery) ||
            d.data().contactPerson?.name?.toLowerCase().includes(lowerCaseQuery)
    });


    return (
        <>
            <ListViewHeader
                icon={HiUsers}
                title={'Administración de clientes'}
                subtitle={'Ver, editar y añadir clientes'}
                buttonIcon={IoMdAdd}
                buttonTitle={'Añadir cliente'}
                buttonTo={'/console/clients/new'} />
            <BasicTableContainer
                title={'Clientes'}
                onSearch={debouncedChangeHandler}
                caption={`Mostrando ${filteredClients?.length} de ${clientsQuerySnapshot?.docs.length} resultados`}>
                <BasicTable
                    isLoading={isLoading}
                    isEmpty={filteredClients?.length === 0}>
                    <Thead>
                        <Tr>
                            <Th width={8}></Th>
                            <Th>Id</Th>
                            <Th>País</Th>
                            <Th>Nombre</Th>
                            <Th>Persona de contacto</Th>
                        </Tr>
                    </Thead>
                    <Tbody>
                        {filteredClients?.map((client) => (
                            <Tr key={client.id}>
                                <Td>
                                    <EditButton to={`/console/clients/${client.id}`} />
                                </Td>
                                <Td>
                                    <Text color='muted'>{client.id}</Text>
                                </Td>
                                <Td>
                                    <Text color='muted'>{client.data().country}</Text>
                                </Td>
                                <Td>
                                    <Text color='muted'>{client.data().name}</Text>
                                </Td>
                                <Td>
                                    <Text color='muted'>{client.data().contactPerson?.name ?? ''}</Text>
                                </Td>
                            </Tr>
                        ))}
                    </Tbody>
                </BasicTable>
            </BasicTableContainer>
        </>

    );
}


