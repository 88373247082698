import { Box, Drawer, DrawerContent, DrawerOverlay, Flex, useDisclosure } from '@chakra-ui/react'
import { Logo } from './Logo'
import { ToggleButton } from './ToggleButton'

type NavbarProps = {
  children?: React.ReactNode;
}
export const Navbar = (props: NavbarProps) => {
  const { isOpen, onToggle, onClose } = useDisclosure();
  return (
    <Box width='full' py='4' px={{ base: '4', md: '8' }} bg='bg-accent'>
      <Flex justify='space-between'>
        <Logo size='small' />
        <ToggleButton isOpen={isOpen} aria-label='Abrir menú' onClick={onToggle} />
        <Drawer
          isOpen={isOpen}
          autoFocus={false}
          placement='left'
          onClose={onClose}
          isFullHeight
          preserveScrollBarGap
        >
          <DrawerOverlay />
          <DrawerContent>
            {props.children}
          </DrawerContent>
        </Drawer>
      </Flex>
    </Box>
  )
}