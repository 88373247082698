import {
    FormControl,
    FormLabel,
    InputGroup,
    InputLeftElement,
    Input,
    InputRightElement,
    Square,
    FormErrorMessage,
    useToast,
    HStack,
    Stack,
    IconButton
} from '@chakra-ui/react'
import { doc, serverTimestamp, setDoc } from 'firebase/firestore';
import { useEffect, useState } from 'react';
import { useDocumentData } from 'react-firebase-hooks/firestore';
import { useForm } from 'react-hook-form'
import { firebaseFirestore } from '../../../firebase';
import { useConsoleContext } from '../../../layout/console/authenticated/AuthenticatedConsoleLayout';
import { showToast } from '../../../helpers';
import { Validators } from '../../../validationPatterns';
import { Dropzone } from '../components/forms/Dropzone';
import { BasicForm } from '../components/forms/BasicForm';
import { FiHelpCircle } from 'react-icons/fi';

type BrandFormData = {
    primaryColor: string;
    textOnPrimary: string;
    trackingId?: string;
    logo?: string;
}

export const Branding = () => {

    const context = useConsoleContext();
    const toast = useToast();

    const [logoRef, setLogoRef] = useState<string>();

    const docRef = doc(
        firebaseFirestore,
        'clients',
        context.settings.selectedClient!!.id,
        'hotels',
        context.settings.selectedHotel!!.id,
        'settings',
        'branding'
    );
    const [brandDataSnapshot, loading, errorLoadingData] = useDocumentData(docRef);

    useEffect(() => {
        if (errorLoadingData) {
            console.error(errorLoadingData);
            showToast(toast, {
                title: 'Error al obtener la información de marca',
                status: 'error'
            });
        }
    }, [errorLoadingData]);

    const showSaveSuccess = () => {
        showToast(toast, {
            title: 'Información actualizada correctamente',
            status: 'success',
        });

    }
    const showSaveError = (e: any) => {
        console.error(e);
        showToast(toast, {
            title: 'Error al guardar la información. Vuelve a itentarlo.',
            status: 'error',
        });
    }

    const save = (data: BrandFormData) => new Promise<void>((resolve) => {
        setDoc(docRef, {
            ...data,
            updated: serverTimestamp()
        }, { merge: true })
            .then(showSaveSuccess)
            .catch(showSaveError)
            .finally(resolve);
    });

    useEffect(() => {
        reset({
            logo: '',
            primaryColor: '241dba',
            textOnPrimary: '000000'
        }, { keepDirty: false });
    }, [context.settings]);

    const {
        handleSubmit,
        register,
        reset,
        watch,
        formState: { errors, isSubmitting },
    } = useForm<BrandFormData>({
        values: {
            logo: logoRef,
            primaryColor: brandDataSnapshot?.primaryColor || '241dba',
            textOnPrimary: brandDataSnapshot?.textOnPrimary || '000000'
        },
        resetOptions: {
            keepDirtyValues: true
        }
    });
    const primaryColor = watch('primaryColor');
    const textOnPrimary = watch('textOnPrimary');

    return (
        <BasicForm
            title={'Aspecto'}
            description={'Personaliza el aspecto de Giftbox para el hotel'}
            canSubmit={!loading}
            isSubmitting={isSubmitting}
            onSubmit={handleSubmit(save)} >
            <FormControl>
                <FormLabel>Logo</FormLabel>
                <Dropzone
                    previewReference={brandDataSnapshot?.logo}
                    onFileUploaded={setLogoRef}
                />
            </FormControl>
            <Stack direction={{ base: 'column', md: 'row' }}>
                <FormControl isInvalid={errors.primaryColor !== undefined} width='auto'>
                    <FormLabel htmlFor='primaryColor'>Color de marca</FormLabel>
                    <InputGroup width={'200px'}>
                        <InputLeftElement
                            pointerEvents='none'
                            color='gray.500'
                            fontSize='1.2em'
                            children='#' />
                        <Input
                            id='primaryColor'
                            type='text'
                            {...register('primaryColor', {
                                required: 'Campo obligatorio.',
                                pattern: {
                                    value: Validators.hexColor,
                                    message: 'Introduce un código de color válido. Por ejemplo #000 o #FFFFFF.'
                                }
                            })}
                        />
                        <InputRightElement>
                            <Square border='1px' size='8' bg={`#${primaryColor}`} borderRadius={'lg'} />
                        </InputRightElement>
                    </InputGroup>
                    <FormErrorMessage>
                        {errors.primaryColor &&
                            errors.primaryColor?.message
                        }
                    </FormErrorMessage>
                </FormControl>
                <FormControl isInvalid={errors.textOnPrimary !== undefined}>
                    <FormLabel htmlFor='textOnPrimary'>Texto sobre color de marca</FormLabel>
                    <InputGroup width={'200px'}>
                        <InputLeftElement
                            pointerEvents='none'
                            color='gray.500'
                            fontSize='1.2em'
                            children='#' />
                        <Input
                            id='textOnPrimary'
                            type='text'
                            {...register('textOnPrimary', {
                                required: 'Campo obligatorio.',
                                pattern: {
                                    value: Validators.hexColor,
                                    message: 'Introduce un código de color válido. Por ejemplo #000 o #FFFFFF.'
                                }
                            })}
                        />
                        <InputRightElement>
                            <Square border='1px' size='8' bg={`#${textOnPrimary}`} borderRadius={'lg'} />
                        </InputRightElement>
                    </InputGroup>
                    <FormErrorMessage>
                        {errors.textOnPrimary &&
                            errors.textOnPrimary?.message
                        }
                    </FormErrorMessage>
                </FormControl>
            </Stack>
            <FormControl isInvalid={errors.trackingId !== undefined} width='auto'>
                <FormLabel htmlFor='trackingId'>
                    Id Medición Analytics
                    <IconButton
                        variant='link'
                        colorScheme='brand'
                        aria-label={'Como obtener Id de medición'}
                        icon={<FiHelpCircle size='1.3em'/>}
                        as='a'
                        target='_blank'
                        href='https://docs.google.com/document/d/e/2PACX-1vTDW4mPXNwns0UabItuEbGKce7m-5t67hPhL67DRqLobMYYQ7vxJqCOC4oZYC2zeSGFMrP1ETGw0fm9/pub' />

                </FormLabel>
                <Input
                    width='200px'
                    id='trackingId'
                    type='text'
                    {...register('trackingId')}
                />
                <FormErrorMessage>
                    {errors.trackingId &&
                        errors.trackingId?.message
                    }
                </FormErrorMessage>
            </FormControl>
        </BasicForm >
    )

}