import { Image, Box, Heading, Text, Button, Center } from '@chakra-ui/react';
import Logo from '../../assets/img/giftbox-dark.svg'

export const Error = () => {
    return (
        <Box textAlign='center' py={{ base: 10, md: 24 }} px={6}>
            <Center mb={12}>
                <Image sx={{ width: 320 }} src={Logo} />
            </Center>
            <Heading
                display='inline-block'
                color={'violet.900'}
                size='2xl'
            >
                ⚠️
            </Heading>
            <Text fontSize='xl' mt={3} mb={2}>
                ¡Configuración incompleta!
            </Text>
            <Text color={'gray.500'} mb={6}>
                Por favor verifica la configuración del hotel.
            </Text>

            <Button
                onClick={()=>window.location.reload()}
                size='lg'
                colorScheme='brand'
                variant='outline'>
                Refrescar
            </Button>
        </Box>
    );
}