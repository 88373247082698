import { IconButton } from '@chakra-ui/react';
import { IoMdCreate } from 'react-icons/io';
import { Link } from 'react-router-dom';

type EditButtonProps = {
    to: string;
}

export const EditButton = (props: EditButtonProps) => (
    <IconButton
        as={Link}
        to={props.to}
        color='brand.800'
        icon={<IoMdCreate fontSize='1.5rem' />}
        variant='ghost'
        aria-label='Editar fila'
    />
)
