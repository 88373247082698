import { useFormControl } from '@chakra-ui/form-control'
import {
    chakra,
    forwardRef,
    layoutPropNames,
    omitThemingProps,
    PropsOf,
    SystemStyleObject,
    useMultiStyleConfig,
    HTMLChakraProps,
} from '@chakra-ui/system'
import { dataAttr } from '@chakra-ui/shared-utils'
import { split } from '@chakra-ui/object-utils'
import { cloneElement, isValidElement } from 'react'
import { SelectField, SelectProps } from '@chakra-ui/react'


export interface SidebarSelectProps extends SelectProps {
    leftIcon?: React.ReactElement<any>;
    leftIconColor?: string;
    leftIconSize?: string;
}

/**
 * React component used to select one item from a list of options.
 *
 * @see Docs https://chakra-ui.com/docs/components/select
 */
export const SelectWithLeftIcon = forwardRef<SidebarSelectProps, 'select'>((props, ref) => {
    const styles = useMultiStyleConfig('Select', props)

    const {
        rootProps,
        placeholder,
        leftIcon,
        icon,
        color,
        height,
        h,
        minH,
        minHeight,
        leftIconColor,
        iconColor,
        leftIconSize,
        iconSize,
        ...rest
    } = omitThemingProps(props)

    const [layoutProps, otherProps] = split(rest, layoutPropNames as any[])

    const ownProps = useFormControl(otherProps)

    const rootStyles: SystemStyleObject = {
        width: '100%',
        height: 'fit-content',
        position: 'relative',
        color,
    }

    const fieldStyles: SystemStyleObject = {
        paddingEnd: '2rem',
        ...styles.field,
        _focus: {
            zIndex: 'unset',
            ...(styles as any).field?.['_focus'],
        },
        _disabled: {},
        _focusVisible: {
            borderColor: 'brand.400'
        }
    }
    if (leftIcon) {
        fieldStyles.paddingInlineStart = 12;
    }
    if(props.disabled){
        fieldStyles.paddingInlineEnd = 2;
        (fieldStyles as any)['_hover'] = {};
    }

    return (
        <chakra.div
            className='chakra-select__wrapper'
            __css={rootStyles}
            {...layoutProps}
            {...rootProps}
        >
            <SelectField
                ref={ref}
                height={h ?? height}
                minH={minH ?? minHeight}
                placeholder={placeholder}
                {...ownProps}
                __css={fieldStyles}
            >
                {props.children}
            </SelectField>
            {leftIcon &&
                <LeftSelectIcon
                    {...((leftIconColor || color) && { color: leftIconColor || color })}
                    __css={{ ...styles.icon, width: 6 }}
                    {...(leftIconSize && { fontSize: leftIconSize })}
                >
                    {leftIcon}
                </LeftSelectIcon>
            }
            {!props.disabled &&
                <SelectIcon
                    {...((iconColor || color) && { color: iconColor || color })}
                    __css={{ ...styles.icon, width: 8 }}
                    {...(iconSize && { fontSize: iconSize })}
                >
                    {icon}
                </SelectIcon>
            }
        </chakra.div>
    )
})

SelectWithLeftIcon.displayName = 'SelectWithLeftIcon'

export const DefaultIcon: React.FC<PropsOf<'svg'>> = (props) => (
    <svg viewBox='0 0 24 24' {...props}>
        <path
            fill='currentColor'
            d='M16.59 8.59L12 13.17 7.41 8.59 6 10l6 6 6-6z'
        />
    </svg>
)

const LeftIconWrapper = chakra('div', {
    baseStyle: {
        position: 'absolute',
        display: 'inline-flex',
        alignItems: 'center',
        justifyContent: 'center',
        pointerEvents: 'none',
        top: '50%',
        left: '4',
        transform: 'translateY(-50%)',
    },
})

interface SelectIconProps extends HTMLChakraProps<'div'> { }

const LeftSelectIcon: React.FC<SelectIconProps> = (props) => {
    const { children, ...rest } = props

    const clone = cloneElement(children as any, {
        role: 'presentation',
        className: 'chakra-select__icon',
        focusable: false,
        'aria-hidden': true,
        // force icon to adhere to `IconWrapper` styles
        style: {
            width: '1em',
            height: '1em',
            color: 'currentColor',
        },
    })

    return (
        <LeftIconWrapper {...rest} className='chakra-select__icon-wrapper'>
            {isValidElement(children) ? clone : null}
        </LeftIconWrapper>
    )
}
LeftSelectIcon.displayName = 'LeftSidebarSelectIcon'


const IconWrapper = chakra('div', {
    baseStyle: {
        position: 'absolute',
        display: 'inline-flex',
        alignItems: 'center',
        justifyContent: 'center',
        pointerEvents: 'none',
        top: '50%',
        transform: 'translateY(-50%)',
    },
})

const SelectIcon: React.FC<SelectIconProps> = (props) => {
    const { children = <DefaultIcon />, ...rest } = props

    const clone = cloneElement(children as any, {
        role: 'presentation',
        className: 'chakra-select__icon',
        focusable: false,
        'aria-hidden': true,
        // force icon to adhere to `IconWrapper` styles
        style: {
            width: '1em',
            height: '1em',
            color: 'currentColor',
        },
    })

    return (
        <IconWrapper {...rest} className='chakra-select__icon-wrapper'>
            {isValidElement(children) ? clone : null}
        </IconWrapper>
    )
}

SelectIcon.displayName = 'SidebarSelectIcon'