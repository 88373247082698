import {
    useToast,
    FormControl,
    FormLabel,
    Input,
    FormErrorMessage,
} from '@chakra-ui/react';
import { doc, setDoc, serverTimestamp } from 'firebase/firestore';
import { useEffect } from 'react';
import { useDocumentData } from 'react-firebase-hooks/firestore';
import { useForm } from 'react-hook-form';
import { firebaseFirestore } from '../../../firebase';
import { useConsoleContext } from '../../../layout/console/authenticated/AuthenticatedConsoleLayout';
import { showToast } from '../../../helpers';
import { Validators } from '../../../validationPatterns';
import { BasicForm } from '../components/forms/BasicForm';

type GeneralFormData = {
    name: string;
    website: string;
    phone: string;
    email: string;
}

export const General = () => {

    const context = useConsoleContext();
    const toast = useToast();

    const docRef = doc(
        firebaseFirestore,
        'clients',
        context.settings.selectedClient!!.id,
        'hotels',
        context.settings.selectedHotel!!.id,
        'settings',
        'general'
    );

    const [generalDataSnapshot, loading, errorLoadingData] = useDocumentData(docRef);

    useEffect(() => {
        if (errorLoadingData) {
            console.error(errorLoadingData);
            showToast(toast, {
                title: 'Error al obtener la información general.',
                status: 'error'
            });
        }
    }, [errorLoadingData]);

    useEffect(() => {
        reset({
            name: '',
            website: '',
            phone: '',
            email: ''
        }, { keepDirty: false });
    }, [context.settings]);

    const showSaveSuccess = () => {
        showToast(toast, {
            title: 'Información actualizada correctamente',
            status: 'success',
        });

    }
    const showSaveError = (e: any) => {
        console.error(e);
        showToast(toast, {
            title: 'Error al guardar la información. Vuelve a itentarlo.',
            status: 'error',
        });
    }

    const save = (data: GeneralFormData) => new Promise<void>((resolve) => {
        setDoc(
            docRef, {
            ...data,
            updated: serverTimestamp()
        }, { merge: true })
            .then(showSaveSuccess)
            .catch(showSaveError)
            .finally(resolve);
    });

    const {
        handleSubmit,
        register,
        reset,
        formState: { errors, isSubmitting },
    } = useForm<GeneralFormData>({
        values: generalDataSnapshot as GeneralFormData,
        resetOptions: {
            keepDirtyValues: true
        }
    });

    return (
        <BasicForm
            title={'Datos generales'}
            description={'Nombre del hotel y datos de contacto'}
            canSubmit={!loading}
            isSubmitting={isSubmitting}
            onSubmit={handleSubmit(save)} >
            <FormControl isRequired isInvalid={errors.name !== undefined}>
                <FormLabel htmlFor='company'>Nombre del hotel</FormLabel>
                <Input
                    id='company'
                    type='text'
                    {...register('name', {
                        required: 'Campo obligatorio.',
                    })} />
                <FormErrorMessage>
                    {errors.name &&
                        <>{errors.name.message}</>
                    }
                </FormErrorMessage>
            </FormControl>
            <FormControl isRequired isInvalid={errors.website !== undefined}>
                <FormLabel htmlFor='email'>Página web</FormLabel>
                <Input
                    id='website'
                    type='website'
                    {...register('website', {
                        required: 'Campo obligatorio.',
                        pattern: {
                            value: Validators.website,
                            message: 'Introduce una página web válida. Debe empezar por http:// o https://'
                        }
                    })} />
                <FormErrorMessage>
                    {errors.website &&
                        <>{errors.website.message}</>
                    }
                </FormErrorMessage>
            </FormControl>
            <FormControl isRequired isInvalid={errors.email !== undefined}>
                <FormLabel htmlFor='email'>Correo electrónico</FormLabel>
                <Input
                    id='email'
                    type='email'
                    {...register('email', {
                        required: 'Campo obligatorio.',
                        pattern: {
                            value: Validators.email,
                            message: 'Introduce un correo electrónico válido.'
                        }
                    })} />
                <FormErrorMessage>
                    {errors.email &&
                        <>{errors.email.message}</>
                    }
                </FormErrorMessage>
            </FormControl>
            <FormControl isRequired isInvalid={errors.phone !== undefined}>
                <FormLabel htmlFor='phone'>Teléfono</FormLabel>
                <Input
                    id='phone'
                    type='text'
                    {...register('phone', {
                        required: 'Campo obligatorio.',
                    })} />
                <FormErrorMessage>
                    {errors.phone &&
                        <>{errors.phone.message}</>
                    }
                </FormErrorMessage>
            </FormControl>
        </BasicForm >
    )

}