import {
    Box,
    Container,
    Flex,
    Spinner,
    Stack,
    useBreakpointValue
} from '@chakra-ui/react';
import { IdTokenResult, User } from '@firebase/auth';
import { useState, useEffect } from 'react';
import { Navigate, Outlet, useOutletContext } from 'react-router';
import { firebaseAuth } from '../../../firebase';
import { AuthenticatedConsoleContext } from '../../../types/AuthenticatedConsoleContext';
import { Client } from '../../../types/client';
import { Hotel } from '../../../types/hotel';
import { Role } from '../../../types/role';
import { Navbar } from './Navbar';
import { Sidebar } from './Sidebar';


export const AuthenticatedConsoleLayout = () => {
    const [loading, setIsLoading] = useState(true);
    const [isLoggedIn, setIsLoggedIn] = useState(false);
    const [idTokenResult, setIdTokenResult] = useState<IdTokenResult | null>(null);
    const [user, setUser] = useState<User | null>(null);
    const [client, setClient] = useState<Client | undefined>();
    const [hotel, setHotel] = useState<Hotel | undefined>();

    useEffect(() => {
        try {
            const jsonClient = localStorage.getItem('client');
            const jsonHotel = localStorage.getItem('hotel');
            if (jsonClient) {
                setClient(JSON.parse(jsonClient));
            }
            if (jsonHotel) {
                setHotel(JSON.parse(jsonHotel));
            }
        } catch (e) {
            console.error(e);
        }
        const unsubscribe = firebaseAuth.onAuthStateChanged((user) => {
            setUser(user);
            setIsLoggedIn(user !== null);
            if (user) {
                user.getIdTokenResult(true).then((value) => {
                    setIdTokenResult(value);
                    setIsLoading(false);
                });
            } else {
                setIsLoading(false);
            }
        });
        return unsubscribe;
    }, []);

    useEffect(() => {
        try {
            if (client) {
                localStorage.setItem('client', JSON.stringify(client));
            } else {
                localStorage.removeItem('client');
            }
            if (hotel) {
                localStorage.setItem('hotel', JSON.stringify(hotel));
            } else {
                localStorage.removeItem('hotel');
            }
        } catch (e) {
            console.error(e);
        }
    }, [client, hotel])

    const isDesktop = useBreakpointValue({ base: false, xl: true })

    if (loading) {
        return (
            <Container display='flex' flexGrow={1}>
                <Stack justify='center' alignItems='center' width='full'>
                    <Spinner size='xl' color={'violet.900'} />
                </Stack>
            </Container>
        )
    }
    if (!loading && !isLoggedIn) {
        return (
            <Navigate to='/console/account/login' replace />
        )
    }
    const context: AuthenticatedConsoleContext = {
        user: user!!,
        idTokenResult: idTokenResult!!,
        isSuperAdmin: () => idTokenResult!!.claims.role === Role.SUPERADMIN,
        isAdmin: () => idTokenResult!!.claims.role === Role.ADMIN,
        isAgent: () => idTokenResult!!.claims.role === Role.AGENT,
        isAdminOrSuperadmin: () => idTokenResult!!.claims.role === Role.ADMIN || idTokenResult!!.claims.role === Role.SUPERADMIN,
        isAdminOrAgent: () => idTokenResult!!.claims.role === Role.ADMIN || idTokenResult!!.claims.role === Role.AGENT,
        claims: {
            clientId: idTokenResult!!.claims.clientId,
            canAddUsers: idTokenResult!!.claims.canAddUsers,
            role: idTokenResult!!.claims.role,
        },
        settings: {
            selectedClient: client,
            selectedHotel: hotel,
            selectClient: setClient,
            selectHotel: setHotel
        }
    }
    return (
        <Flex
            as='section'
            direction={{ base: 'column', xl: 'row' }}
            height='full'
            bg='bg-canvas'
        >
            {isDesktop ?
                <Sidebar context={context} /> :
                <Navbar>
                    <Sidebar drawer context={context} />
                </Navbar>
            }
            <Box bg='bg-accent' py={{ base: '0', xl: '4' }} flex='1' minWidth='0'>
                <Box
                    bg='bg-canvas'
                    borderTopLeftRadius={{ base: 'none', xl: '2rem' }}
                    borderBottomLeftRadius={{ base: 'none', xl: '2rem' }}
                    height='full'
                    overflowY={'auto'}>
                    <Container maxW={'container.xl'} py='8'>
                        <Stack spacing={{ base: 2, lg: 4 }}>
                            <Outlet context={context} />
                        </Stack>
                    </Container>
                </Box>
            </Box>
        </Flex>
    )
}

export function useConsoleContext() {
    return useOutletContext<AuthenticatedConsoleContext>();
}