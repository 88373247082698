import {
    Modal,
    ModalOverlay,
    ModalContent,
    ModalHeader,
    ModalCloseButton,
    ModalBody,
    ModalFooter,
    Button,
    Text,
    Code,
    Link
} from "@chakra-ui/react"

type WidgetModalProps = {
    isOpen: boolean;
    clientId: string;
    hotelId: string;
    onClose: () => void;
}

export const WidgetModal = (props: WidgetModalProps) => {
    const { isOpen, onClose, clientId, hotelId } = props;
    return (
        <Modal isOpen={isOpen} onClose={onClose} isCentered size={'4xl'}>
            <ModalOverlay />
            <ModalContent>
                <ModalHeader />
                <ModalCloseButton />
                <ModalBody>
                    <Text>
                        Para instalar el widget de giftbox en tu página web debes copiar el siguiente código justo antes de cerrar la etiqueta <Code>{'</body>'}</Code> en tu página web.
                    </Text>
                    <Code my={8} width={'100%'}>
                        <pre style={{ padding: '12px' }}>
                            {`<div id="widgetContainer"></div>
<script type="text/javascript" src="https://giftbox.goguest.cloud/widget.js"></script>
<script>
    (function () {
        giftbox(
            'widgetContainer', 
            '${clientId}-${hotelId}', 
            { bodyBackground: '#FFFFFF' }
        )
    })();
</script>`}
                        </pre>
                    </Code>
                    <Text mb={2}>
                        Puedes posicionar el <Code>{'<div>'}</Code> donde quieras y personalizar el color de fondo del widget con la propiedad <Code>bodyBackground</Code>.
                    </Text>
                    <Text>
                        Puedes ver un ejemplo del widget funcionando en el siguiente enlace: <Link href='https://giftbox.goguest.cloud/widget-test.html' target='_blank' color='brand.500'>Ejemplo widget.</Link> Inspecciona la página para ver los detalles.
                    </Text>
                </ModalBody>
                <ModalFooter>
                    <Button variant='ghost' onClick={onClose}>
                        Cerrar
                    </Button>
                </ModalFooter>
            </ModalContent>
        </Modal>
    )
}

