import { Alert, AlertIcon, AlertTitle, AlertDescription, Box } from '@chakra-ui/react';
import { useConsoleContext } from '../../../layout/console/authenticated/AuthenticatedConsoleLayout';

type HotelSpecifficSectionProps = {
    children?: React.ReactNode | React.ReactNode[];
}

export const HotelSpecifficSection = ({ children }: HotelSpecifficSectionProps) => {
    const context = useConsoleContext();
    return (
        <>
            {(!context.settings.selectedHotel || !context.settings.selectedClient) &&
                <Box
                    display='flex'
                    justifyContent={'center'}>
                    <Alert
                        mt={{base:0,lg:14}}
                        borderRadius={25}
                        maxW={'600px'}
                        status='warning'
                        variant='subtle'
                        flexDirection='column'
                        alignItems='center'
                        justifyContent='center'
                        textAlign='center'
                        py={14}
                    >
                        <AlertIcon boxSize='60px' mr={0} />
                        <AlertTitle mt={4} mb={2} fontSize='lg'>
                            ¡Selecciona el hotel con el que quieres trabajar!
                        </AlertTitle>
                        <AlertDescription maxWidth='md'>
                            Para continuar primero debes seleccionar el hotel con el que quieres trabajar. Hazlo desde el menú de navegación.
                        </AlertDescription>
                    </Alert>
                </Box>
            }
            {(context.settings.selectedHotel && context.settings.selectedClient) &&
                children
            }
        </>
    )
}
