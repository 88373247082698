import { Button, HStack, Stack } from '@chakra-ui/react';
import { Link } from 'react-router-dom';

type FullFormProps = {
    onSubmit: () => void;
    children?: React.ReactNode | React.ReactNode[];
    cancelTo: string;
    isSubmitting: boolean;
    canSubmit: boolean;
}

export const FullForm = (props: FullFormProps) => (
    <Stack as='form' spacing={14} onSubmit={props.onSubmit} noValidate>
        {props.children}
        <HStack justify={'flex-end'}>
            <Button as={Link} to={props.cancelTo} replace variant='ghost' size='lg'>Cancelar</Button>
            <Button
                variant='primary'
                isLoading={props.isSubmitting}
                isDisabled={!props.canSubmit}
                type='submit'
                size='lg'>
                Guardar
            </Button>
        </HStack>
    </Stack>
)