import { ChevronDownIcon } from '@chakra-ui/icons';
import {
    Box,
    Text,
    Stack,
    Center,
    Spinner,
    Badge,
    Divider,
    Flex,
    Menu,
    MenuButton,
    Button,
    MenuList,
    MenuItem,
    useToast,
    useDisclosure
} from '@chakra-ui/react';
import { RiRefund2Fill } from 'react-icons/ri';
import { getPaymentStatusLabel, formatDate, showToast } from '../../../helpers';
import { DataLabel } from './DataLabel';
import { PaylandsPaymentData } from './PaylandsPaymentData';
import { collection } from 'firebase/firestore';
import { useEffect, useState } from 'react';
import { useCollection } from 'react-firebase-hooks/firestore';
import { firebaseFirestore, firebaseFunctions } from '../../../firebase';
import { httpsCallable } from '@firebase/functions';
import { ConfirmationModal } from '../components/ConfirmationModal';
import { useConsoleContext } from '../../../layout/console/authenticated/AuthenticatedConsoleLayout';

const refundPaymentCallable = httpsCallable(firebaseFunctions, 'payments-refund');

type PaymentDetailsProps = {
    orderId: string;
    legalSettings?: any;
}
export const PaymentDetails = (props: PaymentDetailsProps) => {
    const { orderId, legalSettings } = props;
    const context = useConsoleContext();
    const toast = useToast();

    const refundCofirmationModalDisclosure = useDisclosure();
    const [isRefunding, setIsRefunding] = useState(false);

    const [paymentsQuerySnapshot, loadingPaymentsQuery, paymentsQueryLoadingError] = useCollection(
        collection(
            firebaseFirestore,
            'orders',
            orderId,
            'payments'
        )
    );

    useEffect(() => {
        if (paymentsQueryLoadingError) {
            showToast(toast, {
                title: 'Error al obtener la información de los pagos',
                status: 'error'
            });
        }
    }, [paymentsQueryLoadingError]);

    const onRefundConfirm = () => {
        setIsRefunding(true);
        refundPaymentCallable({ orderId, paymentId: getRelevantPayment()?.id })
            .then(() => {
                refundCofirmationModalDisclosure.onClose();
                showToast(toast, {
                    title: '¡Correcto!',
                    description: 'El pago se ha reembolsado correctamente',
                    status: 'success'
                });
            })
            .catch((e) => {
                console.error(e);
                showToast(toast, {
                    title: '¡Error!',
                    description: 'Error al reembolar el pago. Inténtalo de nuevo más tarde.',
                    status: 'error'
                });
            })
            .finally(() => {
                setIsRefunding(false);
            })
    }


    const getRelevantPayment = () => {
        const paidPayments = paymentsQuerySnapshot?.docs.filter(p => p.data().paid)
        if (paidPayments?.length ?? 0 > 0) {
            return paidPayments?.pop();
        }
        const sortedPayments = paymentsQuerySnapshot?.docs.sort((a, b) => {
            return a.data().created.toMillis() - b.data().created.toMillis()
        })
        return sortedPayments?.pop()
    }

    const relevantPayment = getRelevantPayment();
    return (
        <>
            <ConfirmationModal
                title='Reembolsar pago'
                isLoading={isRefunding}
                disclosure={refundCofirmationModalDisclosure}
                onConfirm={onRefundConfirm} />
            <Stack
                direction={{ base: 'column', xl: 'row' }}
                spacing={{ base: '5', xl: '8' }}
            >
                <Box flexShrink={0} width={{ base: '100%', xl: '250px' }}>
                    <Text fontSize='lg' fontWeight='medium'>
                        Detalles del pago
                    </Text>
                    <Text color='muted' fontSize='sm'>
                        Información detallada del pago del pedido
                    </Text>
                </Box>
                <Box bg='bg-surface' boxShadow='sm' borderRadius='lg' flexGrow={1}>
                    {loadingPaymentsQuery &&
                        <Box p={8}>
                            <Center>
                                <Spinner color='brand.600' size='lg' />
                            </Center>
                        </Box>
                    }
                    {!loadingPaymentsQuery &&
                        <Box>
                            <Stack p={12} direction={{ base: 'column', md: 'row' }} spacing={{ base: 2, md: '24' }}>
                                <Stack flexGrow={1}>
                                    <DataLabel title='Método' value={relevantPayment?.data().method} />
                                    <DataLabel
                                        title='Pagado'
                                        value={<Badge size='md' variant='solid' colorScheme={relevantPayment?.data().paid ? 'blue' : 'red'}>
                                            {relevantPayment?.data().paid ? 'Sí' : 'No'}
                                        </Badge>} />
                                    <DataLabel title='Estado' value={getPaymentStatusLabel(relevantPayment?.data().status)} />
                                    <DataLabel
                                        title='Última actualización'
                                        value={formatDate(relevantPayment?.data().updated, legalSettings)} />
                                    <DataLabel
                                        title='Fecha reembolso'
                                        value={ relevantPayment?.data().refund?.date ? formatDate(relevantPayment?.data().refund?.date,legalSettings) : '-'} />
                                    <DataLabel
                                        title='Reembolsado por'
                                        value={relevantPayment?.data().refund?.userName ?? '-'} />
                                </Stack>
                                <Stack flexGrow={1}>
                                    {relevantPayment?.data().method === 'PAYLANDS' &&
                                        <PaylandsPaymentData payment={relevantPayment} />
                                    }
                                </Stack>
                            </Stack>
                            <Divider mt={4} />
                            <Flex direction='row-reverse' py='4' px={{ base: '4', md: '6' }}>
                                <Menu>
                                    <MenuButton
                                        as={Button}
                                        isDisabled={context.isAgent()}
                                        rightIcon={<ChevronDownIcon />}
                                        variant='primary'>
                                        Acciones
                                    </MenuButton>
                                    <MenuList>
                                        <MenuItem
                                            isDisabled={relevantPayment?.data().status !== 'PAID'}
                                            onClick={refundCofirmationModalDisclosure.onOpen}
                                            icon={<RiRefund2Fill size={'2em'} />}>
                                            Reembolsar
                                        </MenuItem>
                                    </MenuList>
                                </Menu>
                            </Flex>
                        </Box>
                    }
                </Box>
            </Stack>
        </>
    )
}