import { ListViewHeader } from '../components/ListViewHeader';
import { FaHotel } from 'react-icons/fa';
import { IoMdAdd } from 'react-icons/io';
import { collectionGroup, collection } from '@firebase/firestore';
import { firebaseFirestore } from '../../../firebase';
import { useCollection } from 'react-firebase-hooks/firestore';
import { Thead, Tr, Th, Tbody, Td, Text, useToast } from '@chakra-ui/react';
import { BasicTable } from '../components/tables/BasicTable';
import { BasicTableContainer } from '../components/tables/BasicTableContainer';
import { EditButton } from '../components/tables/EditButton';
import { useCallback, useEffect, useState } from 'react';
import { showToast } from '../../../helpers';
import debounce from 'lodash.debounce';

export const HotelsList = () => {

    const toast = useToast();

    const [searchQuery, setSearchQuery] = useState<string>();
    const changeHandler = (event: any) => setSearchQuery(event.target.value);
    const debouncedChangeHandler = useCallback(debounce(changeHandler, 300), []);

    const [hotelsCollectionSnapshot, isLoadingHotels, hotelsLoadError] = useCollection(
        collectionGroup(firebaseFirestore, 'hotels')
    );

    const [clientsCollectionSnapshot, _isLoadingClients, clientsLoadError] = useCollection(
        collection(firebaseFirestore, 'clients')
    );

    const filteredHotels = hotelsCollectionSnapshot?.docs.filter(d => {
        if (!searchQuery) return d;
        const lowerCaseQuery = searchQuery.toLowerCase();
        return d.data().name.toLowerCase().includes(lowerCaseQuery) ||
            d.data().contactPerson?.name?.toLowerCase().includes(lowerCaseQuery)
    })

    useEffect(() => {
        if (hotelsLoadError) {
            console.error(hotelsLoadError);
            showToast(toast, {
                title: 'Error al cargar el listado de hoteles.',
                status: 'error'
            });
        }
        if (clientsLoadError) {
            console.error(clientsLoadError);
            showToast(toast, {
                title: 'Error al obntener el detalle de clientes.',
                status: 'error'
            });
        }
    }, [hotelsLoadError, clientsLoadError]);

    return (
        <>
            <ListViewHeader
                icon={FaHotel}
                title={'Administración de hoteles'}
                subtitle={'Ver, editar y añadir hoteles'}
                buttonIcon={IoMdAdd}
                buttonTitle={'Añadir hotel'}
                buttonTo={'/console/hotels/new'} />
            <BasicTableContainer
                title={'Hoteles'}
                onSearch={debouncedChangeHandler}
                caption={`Mostrando ${filteredHotels?.length} de ${hotelsCollectionSnapshot?.docs.length} resultados`}
            >
                <BasicTable
                    isLoading={isLoadingHotels}
                    isEmpty={filteredHotels?.length === 0}>
                    <Thead>
                        <Tr>
                            <Th width={8}></Th>
                            <Th>Id</Th>
                            <Th>Nombre</Th>
                            <Th>Persona de contacto</Th>
                            <Th>Cliente</Th>
                        </Tr>
                    </Thead>
                    <Tbody>
                        {filteredHotels?.map((hotel) => (
                            <Tr key={hotel.id}>
                                <Td>
                                    <EditButton to={`/console/hotels/${hotel.data().clientId}-${hotel.id}`}
                                    />
                                </Td>
                                <Td>
                                    <Text color='muted'>{hotel.id}</Text>
                                </Td>
                                <Td>
                                    <Text color='muted'>{hotel.data().name}</Text>
                                </Td>
                                <Td>
                                    <Text color='muted'>{hotel.data().contactPerson?.name ?? ''}</Text>
                                </Td>
                                <Td>
                                    <Text color='muted'>
                                        {clientsCollectionSnapshot?.docs.find(d => d.id === hotel.data().clientId)?.data().name}
                                    </Text>
                                </Td>
                            </Tr>
                        ))}
                    </Tbody>
                </BasicTable>
            </BasicTableContainer>
        </>
    );
}