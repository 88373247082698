import { CreateToastFnReturn } from '@chakra-ui/react'
import { getDownloadURL, ref } from '@firebase/storage';
import { firebaseStorage } from './firebase';
import Values from 'values.js';
import { currencies } from './data/currencies';
import { countries } from './data/countries';
import { Timestamp } from 'firebase/firestore';
import { DateTime } from 'luxon';

type ToastProps = {
    title: string,
    description?: string;
    status: 'success' | 'error';
}
export const showToast = (toastInstace: CreateToastFnReturn, props: ToastProps) => {
    toastInstace({
        title: props.title,
        position: 'top-right',
        description: props.description ?? '',
        status: props.status,
        duration: 2000,
        isClosable: true
    });
}

export enum ResourceSize {
    LARGE = '1024x1024',
    MEDIUM = '600x600',
    SMALL = '600x600'
}

export const getResizedWebpImgSource = (
    res?: string,
    size: ResourceSize = ResourceSize.MEDIUM
): Promise<string | undefined> => {
    if (!res) {
        Promise.resolve();
    }
    const pathComponents = res!!.replace('gs://', '').split('/');
    const lastPathComponent = pathComponents.pop();
    const [fileName, _fileExtension] = lastPathComponent!!.split('.');
    const resizedRef = `gs://${pathComponents.join('/')}/${fileName}_${size}.webp`;
    return new Promise((resolve) => {
        getDownloadURL(ref(firebaseStorage, resizedRef))
            .then(resolve)
            .catch((e) => {
                resolve(undefined);
                console.error(e);
            })
    });
}

export function formatPrice(
    price?: number,
    legalSettings?: { currency: string, country: string }
) {
    const currency = currencies.find(c => c.iso === legalSettings?.currency);
    const country = countries.find(c => c.iso2 === legalSettings?.country);
    if (currency && country) {
        return new Intl.NumberFormat(country.locale, {
            style: 'currency',
            currency: currency.iso,
        }).format(price ?? 0)
    }
    return price?.toString(10) ?? ''
}


export function formatDate(
    timestamp?: Timestamp,
    legalSettings?: { country: string }
) {
    let dt = DateTime.now();
    if (timestamp) {
        dt = DateTime.fromMillis(timestamp.toMillis())
    }
    const country = countries.find(c => c.iso2 === legalSettings?.country);
    return dt.setLocale(country?.locale ?? 'es').toLocaleString(DateTime.DATETIME_SHORT);
}


export const generateHotelTheme = (primaryColor: string) => {
    const colors = new Values(`#${primaryColor || '000'}`);
    const allColors = colors.all(15);
    const palette = {
        50: allColors.find(c => c.type === 'tint' && c.weight === 75)!!.hexString(),
        100: allColors.find(c => c.type === 'tint' && c.weight === 60)!!.hexString(),
        200: allColors.find(c => c.type === 'tint' && c.weight === 45)!!.hexString(),
        300: allColors.find(c => c.type === 'tint' && c.weight === 30)!!.hexString(),
        400: allColors.find(c => c.type === 'tint' && c.weight === 15)!!.hexString(),
        500: allColors.find(c => c.type === 'base')!!.hexString(),
        600: allColors.find(c => c.type === 'shade' && c.weight === 15)!!.hexString(),
        700: allColors.find(c => c.type === 'shade' && c.weight === 30)!!.hexString(),
        800: allColors.find(c => c.type === 'shade' && c.weight === 45)!!.hexString(),
        900: allColors.find(c => c.type === 'shade' && c.weight === 60)!!.hexString(),
    }
    return palette;
}

export function getOrderStatusLabel(status: string) {
    if (status === 'AWAITING_PAYMENT') {
        return 'ESPERANDO PAGO';
    } else if (status === 'PAID') {
        return 'PAGADO';
    } else if (status === 'CANCELLED') {
        return 'CANCELADO';
    } else if (status === 'CLAIMED') {
        return 'CANJEADO';
    }
    return status;
}

export function getPaymentStatusLabel(status: string) {
    if (status === 'PENDING') {
        return 'ESPERANDO PAGO';
    } else if (status === 'PAID') {
        return 'PAGADO';
    } else if (status === 'REFUNDED') {
        return 'REEMBOLSADO';
    }
    return status;
}

export function getColorForOrderStatusLabel(status: string) {
    if (status === 'AWAITING_PAYMENT') {
        return 'yellow';
    } else if (status === 'PAID') {
        return 'green';
    } else if (status === 'CANCELLED') {
        return 'red';
    } else if (status === 'CLAIMED') {
        return 'cyan';
    }
    return 'grey';
}