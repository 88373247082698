import {
    useToast,
    FormControl,
    FormLabel,
    Input,
    FormErrorMessage,
    Textarea,
    Select,
    Button,
    useDisclosure,
} from '@chakra-ui/react';
import { doc, setDoc, serverTimestamp } from 'firebase/firestore';
import { useEffect, useState } from 'react';
import { useDocumentData } from 'react-firebase-hooks/firestore';
import { useForm } from 'react-hook-form';
import { firebaseFirestore } from '../../../../firebase';
import { useConsoleContext } from '../../../../layout/console/authenticated/AuthenticatedConsoleLayout';
import { showToast } from '../../../../helpers';
import { BasicForm } from '../../components/forms/BasicForm';
import { Validators } from '../../../../validationPatterns';
import { SendTestmailModal } from './SendTestmailModal';

type MailingFormData = {
    mode: string;
    customSmtpData?: {
        fromEmail: string;
        username: string;
        password: string;
        host: string;
        port: string;
    }
}

export const Mailing = () => {

    const context = useConsoleContext();
    const toast = useToast();

    const sendTestmailModalDisclouse = useDisclosure();

    const docRef = doc(
        firebaseFirestore,
        'clients',
        context.settings.selectedClient!!.id,
        'hotels',
        context.settings.selectedHotel!!.id,
        'settings',
        'mailing'
    );

    const [mailingDataSnapshot, loading, errorLoadingData] = useDocumentData(docRef);

    useEffect(() => {
        if (errorLoadingData) {
            console.error(errorLoadingData);
            showToast(toast, {
                title: 'Error al obtener la configuración de envío de correos.',
                status: 'error'
            });
        }
    }, [errorLoadingData]);

    useEffect(() => {
        reset({
            mode: '',
            customSmtpData: {
                fromEmail: '',
                username: '',
                password: '',
                host: '',
                port: ''
            }
        }, { keepDirty: false });
    }, [context.settings]);

    const showSaveSuccess = () => {
        showToast(toast, {
            title: 'Información actualizada correctamente',
            status: 'success',
        });

    }
    const showSaveError = (e: any) => {
        console.error(e);
        showToast(toast, {
            title: 'Error al guardar la información. Vuelve a itentarlo.',
            status: 'error',
        });
    }

    const save = (data: MailingFormData) => new Promise<void>((resolve) => {
        setDoc(
            docRef, {
            ...data,
            updated: serverTimestamp()
        }, { merge: true })
            .then(showSaveSuccess)
            .catch(showSaveError)
            .finally(resolve);
    });

    const {
        handleSubmit,
        watch,
        register,
        unregister,
        reset,
        formState: { errors, isSubmitting },
    } = useForm<MailingFormData>({
        values: mailingDataSnapshot as MailingFormData,
        resetOptions: {
            keepDirtyValues: true
        }
    });

    const mode = watch('mode');

    useEffect(() => {
        if (mode === 'DEFAULT') {
            unregister('customSmtpData.fromEmail');
            unregister('customSmtpData.host');
            unregister('customSmtpData.username');
            unregister('customSmtpData.password');
            unregister('customSmtpData.port');
        }
    }, [mode]);

    return (
        <>
            <SendTestmailModal
                onClose={sendTestmailModalDisclouse.onClose}
                isOpen={sendTestmailModalDisclouse.isOpen} />
            <BasicForm
                title={'Configuración de envío de correos'}
                description={'Personaliza como se envían los correos electrónicos de Giftbox.'}
                canSubmit={!loading}
                isSubmitting={isSubmitting}
                additionalActions={
                    <Button
                        variant='ghost'
                        onClick={sendTestmailModalDisclouse.onOpen}>
                        Enviar correo de prueba
                    </Button>
                }
                onSubmit={handleSubmit(save)} >
                <FormControl isRequired isInvalid={errors?.mode !== undefined}>
                    <FormLabel htmlFor='environment'>Modo</FormLabel>
                    <Select
                        id='environment'
                        placeholder='Selecciona el modo'
                        {...register('mode', {
                            required: 'Campo obligatorio',
                        })}>
                        <option value='DEFAULT'>CONFIGURACIÓN POR DEFECTO</option>
                        <option value='CUSTOM_SMTP'>SERVIDOR SMTP PROPIO</option>
                    </Select>
                    <FormErrorMessage>
                        {errors.mode &&
                            <>{errors.mode.message}</>
                        }
                    </FormErrorMessage>
                </FormControl>
                {mode === 'CUSTOM_SMTP' &&
                    <>
                        <FormControl isRequired isInvalid={errors?.customSmtpData?.fromEmail !== undefined}>
                            <FormLabel htmlFor='fromEmail'>Correo electrónico remitente</FormLabel>
                            <Input
                                id='fromEmail'
                                {...register('customSmtpData.fromEmail', {
                                    required: 'Campo obligatorio',
                                    pattern: {
                                        value: Validators.email,
                                        message: 'Formato no válido.'
                                    }
                                })}
                            />
                            <FormErrorMessage>
                                {errors?.customSmtpData?.fromEmail &&
                                    <>{errors?.customSmtpData?.fromEmail?.message}</>
                                }
                            </FormErrorMessage>
                        </FormControl>
                        <FormControl isRequired isInvalid={errors?.customSmtpData?.host !== undefined}>
                            <FormLabel htmlFor='hostName'>Hostname</FormLabel>
                            <Input
                                id='hostName'
                                {...register('customSmtpData.host', {
                                    required: 'Campo obligatorio',
                                })}
                            />
                            <FormErrorMessage>
                                {errors?.customSmtpData?.host &&
                                    <>{errors?.customSmtpData?.host?.message}</>
                                }
                            </FormErrorMessage>
                        </FormControl>
                        <FormControl isRequired isInvalid={errors?.customSmtpData?.port !== undefined}>
                            <FormLabel htmlFor='port'>Puerto</FormLabel>
                            <Input
                                id='fromEmail'
                                {...register('customSmtpData.port', {
                                    required: 'Campo obligatorio',
                                    pattern: {
                                        value: /^(?!(?:25)$)\d+/,
                                        message: 'Debe ser un número y el puerto 25 no está permitido.'
                                    }
                                })}
                            />
                            <FormErrorMessage>
                                {errors?.customSmtpData?.port &&
                                    <>{errors?.customSmtpData?.port?.message}</>
                                }
                            </FormErrorMessage>
                        </FormControl>
                        <FormControl isRequired isInvalid={errors?.customSmtpData?.username !== undefined}>
                            <FormLabel htmlFor='smtpUsername'>Nombre de usuario</FormLabel>
                            <Input
                                id='smtpUsername'
                                {...register('customSmtpData.username', {
                                    required: 'Campo obligatorio',
                                })}
                            />
                            <FormErrorMessage>
                                {errors?.customSmtpData?.username &&
                                    <>{errors?.customSmtpData?.username?.message}</>
                                }
                            </FormErrorMessage>
                        </FormControl>
                        <FormControl isRequired isInvalid={errors?.customSmtpData?.password !== undefined}>
                            <FormLabel htmlFor='smtpPassword'>Contraseña</FormLabel>
                            <Input
                                id='smtpPassword'
                                {...register('customSmtpData.password', {
                                    required: 'Campo obligatorio',
                                })}
                            />
                            <FormErrorMessage>
                                {errors?.customSmtpData?.password &&
                                    <>{errors?.customSmtpData?.password?.message}</>
                                }
                            </FormErrorMessage>
                        </FormControl>
                    </>
                }
            </BasicForm >
        </>
    )

}