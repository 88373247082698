import {
  Box,
  Heading,
  Radio,
  RadioGroup,
  Stack,
  Text,
} from '@chakra-ui/react'

export const PaymentMethod = () => (
  <Stack spacing={{ base: '6', md: '10' }}>
    <Heading size='md'>Forma de pago</Heading>
    <RadioGroup colorScheme='gray' size='lg'>
      <Stack direction={{ base: 'column', lg: 'row' }} spacing='6'>
        <Radio defaultChecked spacing='3' flex='1'>
          <Stack spacing='1.5'>
            <Box>
              <Text color={'black'} fontWeight='medium'>
                Tarjeta de crédito
              </Text>
              <Text color='gray.600'>
                Paga de forma segura con tu tarjeta.
              </Text>
            </Box>
          </Stack>
        </Radio>
      </Stack>
    </RadioGroup>
  </Stack>
)