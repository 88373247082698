import {
  Avatar,
  Divider,
  Flex,
  HStack,
  IconButton,
  Stack,
  Text,
  useToast
} from '@chakra-ui/react'
import { Logo } from './Logo'
import { NavButton } from './NavButton'
import { IoEnterOutline } from 'react-icons/io5';
import {
  MdOutlineLiveHelp,
  MdCategory,
  MdColorLens,
  MdLeaderboard,
  MdManageAccounts,
  MdProductionQuantityLimits
} from 'react-icons/md';
import { BsHandbagFill } from 'react-icons/bs';
import { HiUsers } from 'react-icons/hi';
import { FaHotel } from 'react-icons/fa';
import { firebaseAuth, firebaseFirestore } from '../../../firebase';
import { useEffect } from 'react';
import { collection, collectionGroup, doc, QueryConstraint, where } from '@firebase/firestore';
import { AuthenticatedConsoleContext } from '../../../types/AuthenticatedConsoleContext';
import { query } from 'firebase/firestore';
import { useCollection, useDocument } from 'react-firebase-hooks/firestore';
import { showToast } from '../../../helpers';
import { SelectWithLeftIcon } from './SelectWithLeftIcon';
import { RiArrowDropDownLine } from 'react-icons/ri';
import { isMobileSafari, isTablet } from 'react-device-detect';

type SidebarProps = {
  drawer?: boolean;
  context: AuthenticatedConsoleContext;
}

export const Sidebar = (props: SidebarProps) => {
  const { context } = props;
  const isSuperAdmin = context.isSuperAdmin();
  const canAddUsers = context.claims.canAddUsers;
  const authenticatedClientId = context.claims.clientId;
  const toast = useToast();

  const [clientsCollectionSnapshot, _isLoadingClientsCollection, loadingClientsCollectionError] = useCollection(
    collection(firebaseFirestore, 'clients')
  );

  const [clientDocumentSnapshot, _isLoadingClientDocument, loadingClientDocumentError] = useDocument(
    doc(firebaseFirestore, 'clients', authenticatedClientId ? authenticatedClientId : '-')
  );

  const hotelsQueryConstraints: QueryConstraint[] = [];
  if (!context.isSuperAdmin()) {
    hotelsQueryConstraints.push(where('clientId', '==', authenticatedClientId))
  }

  const [hotelsQuerySnapshot, _isLoadingHotelsQuery, loadingHotelsQueryError] = useCollection(
    query(collectionGroup(firebaseFirestore, 'hotels'), ...hotelsQueryConstraints)
  );


  useEffect(() => {
    if (context.isSuperAdmin()) {
      if (loadingClientsCollectionError) {
        console.error(loadingClientsCollectionError);
        showToast(toast, {
          title: 'Error al obtener el listado de clientes.',
          status: 'error'
        });
      }
    } else {
      if (loadingClientDocumentError) {
        console.error(loadingClientDocumentError);
        showToast(toast, {
          title: 'Error al obtener la información del cliente.',
          status: 'error'
        });
      }
    }
    if (loadingHotelsQueryError) {
      console.error(loadingHotelsQueryError);
      showToast(toast, {
        title: 'Error al obtener el listado de hoteles.',
        status: 'error'
      });
    }
  }, [
    loadingClientsCollectionError,
    loadingClientDocumentError,
    loadingHotelsQueryError
  ]);


  useEffect(() => {
    const filteredHotels = hotelsQuerySnapshot?.docs.filter(h => h.data().clientId === context.settings.selectedClient?.id);
    if (filteredHotels?.length === 1) {
      context.settings.selectHotel({
        id: filteredHotels[0].id,
        name: filteredHotels[0].data().name,
        clientId: filteredHotels[0].data().clientId
      });
    }
  }, [context.settings.selectedClient]);

  useEffect(() => {
    if (!context.isSuperAdmin() && !context.settings.selectedClient) {
      const client = clientDocumentSnapshot ? {
        id: clientDocumentSnapshot?.id!!,
        name: clientDocumentSnapshot?.data()?.name!!
      } : undefined;
      if (client) {
        clients = [client];
      }
      context.settings.selectClient(client);
    }
  }, [clientDocumentSnapshot])

  const logout = () => {
    firebaseAuth.signOut();
  }

  const selectClient = (e: any) => {
    let client;
    if (isSuperAdmin) {
      client = clientsCollectionSnapshot?.docs.find(c => c.id === e.target.value)
      client = client ? {
        id: client?.id!!,
        name: client?.data().name!!
      } : undefined;
    } else {
      client = clientDocumentSnapshot ? {
        id: clientDocumentSnapshot?.id!!,
        name: clientDocumentSnapshot?.data()?.name!!
      } : undefined
    }
    context.settings.selectClient(client);
    context.settings.selectHotel(undefined);
  }

  const selectHotel = (e: any) => {
    const hotel = hotelsQuerySnapshot?.docs.find(c => c.id === e.target.value);
    context.settings.selectHotel(hotel ? {
      id: hotel?.id!!,
      name: hotel?.data()?.name!!,
      clientId: hotel?.data()?.clientId!!
    } : undefined);
  }

  let clients;
  if (context.isSuperAdmin()) {
    clients = clientsCollectionSnapshot?.docs.map(d => ({
      id: d.id,
      name: d.data().name
    }));
  } else {
    const client = clientDocumentSnapshot ? {
      id: clientDocumentSnapshot?.id!!,
      name: clientDocumentSnapshot?.data()?.name!!
    } : undefined;
    if (client) {
      clients = [client];
    }
  }

  return (
    <Flex
      bg='bg-accent'
      color='on-accent'
      overflowY='auto'
      className='no-scroll-bar'
      flex='1'
      maxW={{ base: 'full', sm: 'xs' }}
      pt={'8'}
      px={{ base: '4', sm: '6' }}
    >
      <Stack spacing={8} width={'full'}>
        <Logo size='big' />
        <Stack spacing='3'>
          <SelectWithLeftIcon
            tabIndex={-1}
            variant={'filled'}
            size='lg'
            fontSize={'md'}
            color='gray.800'
            leftIcon={<HiUsers />}
            leftIconSize={'2xl'}
            leftIconColor={'brand.800'}
            icon={<RiArrowDropDownLine />}
            iconSize='4xl'
            disabled={!isSuperAdmin}
            placeholder={'Selecciona un cliente'}
            value={context.settings.selectedClient?.id}
            onChange={selectClient}>
            {clients?.map(d => (
              <option key={d.id} value={d.id}>{d.name}</option>
            ))}
          </SelectWithLeftIcon>

          <SelectWithLeftIcon
            variant={'filled'}
            tabIndex={-1}
            size='lg'
            fontSize={'md'}
            color='gray.800'
            leftIcon={<FaHotel />}
            leftIconSize={'2xl'}
            leftIconColor={'brand.800'}
            icon={<RiArrowDropDownLine />}
            iconSize='4xl'
            placeholder='Selecciona un hotel'
            value={context.settings.selectedHotel?.id}
            onChange={selectHotel}
          >
            {hotelsQuerySnapshot?.docs
              ?.filter(h => h.data().clientId === context.settings.selectedClient?.id)
              .map(d => (
                <option key={d.id} value={d.id}>{d.data().name}</option>
              ))}
          </SelectWithLeftIcon>
        </Stack>
        <Stack spacing='1' flexGrow='1'>
          {context.isAdminOrSuperadmin() &&
            <>
              <NavButton disableHover={props.drawer} to='/console/hotel-settings' label='Personalización' icon={MdColorLens} />
              <NavButton disableHover={props.drawer} to='/console/products' label='Productos' icon={MdProductionQuantityLimits} />
              <NavButton disableHover={props.drawer} to='/console/categories' label='Categorías' icon={MdCategory} />
              <NavButton disableHover={props.drawer} to='/console/metrics/hotel' label='Métricas' icon={MdLeaderboard} />
            </>
          }
          <NavButton disableHover={props.drawer} to='/console/sales' label='Ventas' icon={BsHandbagFill} />
        </Stack>
        <Stack spacing='1'>
          {context.isSuperAdmin() &&
            <>
              <NavButton disableHover={props.drawer} to='/console/clients' label='Clientes' icon={HiUsers} />
              <NavButton disableHover={props.drawer} to='/console/hotels' label='Hoteles' icon={FaHotel} />
            </>
          }
          {(context.isSuperAdmin() || (context.isAdmin() && canAddUsers)) &&
            <NavButton disableHover={props.drawer} to='/console/users' label='Usuarios' icon={MdManageAccounts} />
          }
          <NavButton
            disableHover={props.drawer}
            href={`https://easygoband.atlassian.net/servicedesk/customer/portal/7/group/15/create/62?customfield_10054=${context.settings.selectedClient?.name ?? ''}`}
            label='Ayuda'
            icon={MdOutlineLiveHelp} />
        </Stack>
        <Divider sx={{ borderColor: 'brand.50' }} />
        <HStack spacing={3} pb={isMobileSafari ? isTablet ? 16 : 24 : 8}>
          <Avatar name={context.user.displayName ?? 'Anon'} src={context.user.photoURL ?? undefined} />
          <Stack sx={{ overflow: 'hidden', whiteSpace: 'nowrap' }}>
            <Text fontWeight={'bold'} sx={{ textOverflow: 'ellipsis', overflow: 'hidden' }}>{context.user.displayName}</Text>
            <Text sx={{ textOverflow: 'ellipsis', overflow: 'hidden' }}>{context.user.email}</Text>
          </Stack>
          <IconButton
            fontSize={'28'}
            onClick={logout}
            variant='ghost-on-accent'
            aria-label='Logout'
            icon={<IoEnterOutline />}
          />
        </HStack>
      </Stack>
    </Flex >
  )
}