import { Stack, Button, Icon } from '@chakra-ui/react'
import { IconType } from 'react-icons';
import { Link } from 'react-router-dom';
import { SectionHeader, SectionHeaderProps } from './SectionHeader';

type ListViewHeader = SectionHeaderProps & {
    buttonIcon?: IconType
    buttonTitle?: string;
    buttonTo?: string;
    showButton?: boolean;
}

export const ListViewHeader = (props: ListViewHeader) => {

    const { showButton } = props;

    return (
        <SectionHeader {...props}>
            <Stack width={'full'} alignItems='end'>
                {(props.buttonIcon && props.buttonTitle && (showButton === undefined || showButton)) &&
                    <Button
                        as={Link}
                        to={props.buttonTo}
                        leftIcon={<Icon as={props.buttonIcon} fontSize='22' />}
                        variant='primary'
                        size='lg'
                    >
                        {props.buttonTitle}
                    </Button>
                }
            </Stack>
        </SectionHeader>
    );
}