import { Link, ListItem, Stack, Text, UnorderedList } from '@chakra-ui/react';
import { useHotelContext } from '../../../layout/hotel/HotelLayout';
import { SectionHeader } from '../components/SectionHeader';
import { DateTime } from 'luxon';

export const TermsConditions = () => {
    const context = useHotelContext();
    const legalSettings = context.settings.legal;
    const generalSettings = context.settings.general;
    const giftboxUrl = `${window.location.protocol}//${window.location.host}/hotel/${context.clientId}-${context.hotelId}`;
    const privacyUrl = `${giftboxUrl}/privacy`;
    const cookiePolicyUrl = `${giftboxUrl}/cookies`;
    const startOfYear = DateTime.now().startOf('year').toLocaleString(DateTime.DATE_FULL)

    return (
        <>
            <SectionHeader
                title='TÉRMINOS Y CONDICIONES'
            />
            <Stack spacing={3}>
                <Text>
                    Las presentes Condiciones Generales de Venta y de Uso (en lo sucesivo, las «CGV/CGU») tienen el propósito de definir las reglas aplicables a la venta de Experiencias, Servicios y Productos, y al uso de los mismos. Son válidas a partir del {startOfYear} para toda compra y/o uso realizados a partir de esa fecha.
                </Text>
                <Text>
                    {legalSettings.company} se reserva el derecho de modificar las presentes CGV/CGU en cualquier momento sin previo aviso. En caso de modificación, las CGV/CGU aplicables serán aquellas vigentes en la fecha de compra o uso del Regalo/bono de Experiencias.
                </Text>
                <Text>
                    Todo Usuario/Comprador/Beneficiario declara tener la capacidad legal, de conformidad con el artículo 1163 del Código Civil, para contratar y utilizar el Sitio de acuerdo con las CGV/CGU del Sitio.
                </Text>
                <Text>
                    {legalSettings.company}, una sociedad constituida con domicilio en {legalSettings.address}, {legalSettings.city}, {legalSettings.country}, con identificación fiscal {legalSettings.taxId}.
                </Text>
                <Text>
                    Los términos definidos a continuación, que inician con letra mayúscula, tendrán los siguientes significados en estas CGV/CGU, tanto en plural como en singular:
                </Text>
                <Text>
                    <b>Beneficiario:</b> La persona que usa la Experiencia, servicio o el adquiere el producto.
                </Text>
                <Text>
                    El Bono Regalo, independientemente de su formato, no tiene valor nominal. Sólo puede utilizarse en {generalSettings.name}. Sólo le permite beneficiarse del servicio o experiencia elegida al presentar el Bono Regalo o elegir uno o varios productos, servicios o experiencias. No es fungible, divisible o fraccionable y no puede añadirse a otro Bono Regalo ni dividirse en varios Bonos Regalos.
                </Text>
                <Text>
                    Un Regalo de Experiencia, servicio o producto puede ser canjeado por otro Regalo de Experiencias, servicios o productos de acuerdo con los términos y condiciones establecidos en el artículo 2.3.
                </Text>
                <Text>
                    <b>Comprador:</b> Se refiere a la persona que compra un la experiencia, el servicio, producto o bono regalo.
                </Text>
                <Text>
                    <b>Compra/Venta en línea:</b> significa la compra de un servicio, producto o Bono Regalo en la web.
                </Text>
                <Text>
                    <b>Compra/Venta en tienda:</b> significa la compra de una experiencia, servicio o producto o Bono Regalo en un punto de venta físico.
                </Text>
                <Text>
                    <b>Cookies:</b> Se refiere a los pequeños archivos enviados al disco duro de la computadora del Usuario para facilitar su navegación en el Sitio y ofrecerle las páginas que le correspondan mejor cuando regrese al Sitio.
                </Text>
                <Text>
                    <b>Datos personales:</b> Se refiere a toda la información que permite la identificación, directa o indirecta, de una persona física (apellido, nombre, dirección de correo electrónico, etc.).
                </Text>
                <Text>
                    <b>Experiencia/Servicio/Producto:</b> significa el servicio o producto proporcionado al Beneficiario a partir de la selección de ofertas contenidas en la Web. Una Experiencia puede constar de varios servicios diferentes y/o complementarios (por ejemplo: una cena, un cóctel, acceso al spa, una noche, un desayuno, una atención, un masaje, una reunión informativa, una o varias vueltas en un circuito de carreras, etc.).
                </Text>
                <Text>
                    <b>Parte(s):</b> significa, según el contexto, el Comprador y/o el Beneficiario y/o {legalSettings.company}.
                </Text>
                <Text>
                    <b>Regalos de Experiencias y Productos:</b> Los Regalos de Experiencias o servicios son preparados y comercializados por la empresa y permiten al Beneficiario acceder a un Servicio, producto o experiencia de su elección de la lista de servicios  que se ofrece en la web.
                </Text>
                <Text>
                    Los Regalos de Experiencias, productos o servicios siempre están acompañados por un Bono Regalo.
                </Text>
                <Text fontWeight={'bold'}>
                    Puede acceder a la lista de Productos, Servicios y Experiencias disponibles directamente en la página web <Link href={giftboxUrl}>{giftboxUrl}</Link>.
                </Text>
                <Text>
                    El Regalo de Experiencias, productos o servicios se puede ofrecer a cualquier Beneficiario a elección del Comprador.
                </Text>
                <Text>
                    <b>Sitio:</b> Se refiere al sitio web de {legalSettings.company} accesible en la URL <Link href={giftboxUrl}>{giftboxUrl}</Link>
                </Text>
                <Text>
                    <b>Usuario:</b> significa la persona que navega o se conecta al Sitio, sin ser necesariamente un Comprador o Beneficiario.
                </Text>
                <Text fontSize='xl' fontWeight={'semibold'}>
                    Sección 1 - Condiciones Generales de Venta (CGV) para Compras en línea
                </Text>
                <Text>
                    Se invita al Comprador a leer detenidamente esta Sección 1 y la Sección 3 (Disposiciones Generales) de estas Condiciones Generales, aplicables solo a las Compras en línea.
                </Text>
                <Text fontSize='xl' fontWeight={'semibold'}>
                    Artículo 1.1: Proceso de colocación pedidos y formación del contrato
                </Text>
                <Text>
                    El comprador puede efectuar sus Compras de Regalos de Experiencias, Productos y Servicios en línea directamente a través del Sitio {giftboxUrl}.
                </Text>
                <Text>
                    La colocación de pedidos en el Sitio consta en particular de los siguientes pasos:
                </Text>
                <UnorderedList>
                    <ListItem>El Comprador selecciona en el Sitio uno o más productos, servicios, o Bonos Regalos de Experiencias, productos o servicios;</ListItem>
                    <ListItem>A continuación, se le pedirá al Comprador que elija el formato de su Regalo así como las modalidades de entrega (solo para productos físicos).</ListItem>
                    <ListItem>Para continuar con el pedido, el Comprador deberá colocar el pedido rellenando los campos necesarios para enviar su pedido. El Comprador garantiza que toda la información que proporcione a la empresa será precisa y estará actualizada y libre de todo carácter engañoso. La información proporcionada por el Comprador lo compromete tan pronto como sea validada.</ListItem>
                    <ListItem>Después de elegir, un resumen que enumera todas las opciones y el precio total de los productos o servicios seleccionados, los detalles de contacto, los eventuales costes de envío, el método de pago, permitirán al Comprador verificar los detalles de su pedido y así realizar las modificaciones necesarias, antes de la colocación definitiva de su pedido.</ListItem>
                    <ListItem>La colocación del pedido se realizará después de la aceptación de las presentes CGV. La venta se considerará definitiva únicamente después de la aceptación de los medios de pago utilizados y la recepción del precio total por parte de la empresa.</ListItem>
                </UnorderedList>
                <Text>
                    Los pagos se efectuarán a través de la pasarela de pagos correspondiente, y sólo se entenderá adquirido el producto, cuando se produzca la confirmación de pago.
                </Text>
                <Text>
                    La empresa se reserva el derecho de cancelar o rechazar cualquier pedido de un Comprador con el que exista una disputa sobre el pago de un pedido anterior que no se haya pagado o en caso de sospecha seria de un pedido fraudulento.
                </Text>
                <Text>
                    A partir de la confirmación final del pedido, la empresa enviará una confirmación del pedido por correo electrónico al Comprador, incluida toda la información relacionada con el mismo. Al imprimir y/o conservar este correo electrónico, el Comprador tendrá un comprobante de su pedido. En el caso de un pedido de un bono regalo, el Comprador recibirá sistemáticamente una copia del Bono regalo si no es el Beneficiario.
                </Text>
                <Text>
                    El Comprador deberá conservar este correo electrónico de confirmación de pedido y, si es necesario, debe enviarse al Beneficiario. De hecho, será necesario y la empresa lo solicitará sistemáticamente como prueba de compra en particular en caso de fraude, robo, pérdida, no activación, cambio, disputa con el Colaborador y cualquier otra solicitud relacionada con el Regalo de Experiencias.
                </Text>
                <Text fontSize='xl' fontWeight={'semibold'}>
                    Artículo 1.2: Precios y condiciones de pago
                </Text>
                <Text fontSize='xl' fontWeight={'semibold'}>
                    Artículo 1.2.1: Precio
                </Text>
                <Text>
                    Los precios de los productos, servicios, experiencias, publicados en el Sitio, son los vigentes el día de la orden e incluyen todos los impuestos aplicables.
                </Text>
                <Text fontSize='xl' fontWeight={'semibold'}>
                    Artículo 1.2.2: Condiciones de pago
                </Text>
                <Text>
                    El pago del pedido se efectuará a través de la plataforma de pagos correspondiente.
                </Text>
                <Text>
                    El Comprador autoriza expresamente  a la empresa a emitir la factura en soporte electrónico, si bien podrá indicarnos en cualquier momento su voluntad de recibir una factura en soporte papel, en cuyo caso, emitiremos y remitiremos la factura en dicho formato. Podrá solicitarlo contactando con nuestro servicio de atención al cliente, a través de cualquiera de los medios puestos a su disposición y de manera gratuita.
                </Text>
                <Text fontSize='xl' fontWeight={'semibold'}>
                    Artículo 1.2.3: Seguridad de los pagos
                </Text>
                <Text>
                    Con el fin de proteger al Comprador y la empresa contra toda práctica fraudulenta, los pedidos de se verifican habitualmente. En el marco de este procedimiento, la empresa puede tener que suspender el procesamiento de un pedido y comunicarse con el Comprador o el Beneficiario para obtener los documentos de respaldo necesarios para desbloquear el pedido: comprobante de domicilio, identidad o banco. Estos documentos de respaldo deben corresponder a los detalles de entrega y facturación ingresados durante el pedido y, cuando corresponda, a los detalles del titular de la tarjeta.
                </Text>
                <Text>
                    Para un pedido que tiene una dirección de entrega diferente de la dirección de facturación, la empresa puede tener que comunicarse con las dos personas mencionadas, a saber, la persona de la dirección de facturación y la persona indicada para la dirección de la dirección de entrega.
                    La información obtenida en este contexto puede estar sujeta a un procesamiento de datos automatizado, cuyo propósito es definir un nivel de seguridad de transacción y luchar contra el fraude.
                </Text>
                <Text>
                    Estos documentos de respaldo pueden ser solicitados por correo electrónico, correo postal o teléfono. Su no transmisión puede dar lugar a la cancelación de la orden.
                </Text>
                <Text fontSize='xl' fontWeight={'semibold'}>
                    Artículo 1.3: Derecho de desistimiento
                </Text>
                <Text>
                    De acuerdo con las disposiciones de la Ley General para la Defensa de los Consumidores y Usuarios, el Comprador dispondrá, por sus Compras en línea, de un período de desistimiento de catorce (14) días a partir de la fecha de recepción de la compra, sin tener que justificar razones o pagar multas. Este derecho de desistimiento no aplica a las Compras en tienda.
                </Text>
                <Text>
                    El Comprador podrá ejercer su derecho de desistimiento: mediante escrito enviado por correo electrónico o correo postal a las direcciones anteriores, para informar a {legalSettings.company} de su decisión clara e inequívoca de desistir de su Compra en línea;
                </Text>
                <Text>
                    <b>Será imprescindible que el Comprador comunique la siguiente información:</b>
                </Text>
                <UnorderedList>
                    <ListItem>Su nombre y apellido;</ListItem>
                    <ListItem>El número de pedido;</ListItem>
                    <ListItem>El nombre del servicio, producto o bono correspondiente;</ListItem>
                    <ListItem>El código de confirmación;</ListItem>
                </UnorderedList>
                <Text>
                    Una vez que la empresa haya recibido la solicitud de desistimiento, enviará inmediatamente al Comprador un acuse de recibo del desistimiento en un soporte duradero (correo electrónico).
                </Text>
                <Text>
                    Cualquier desistimiento solicitado de acuerdo con las condiciones de este artículo resultará en un reembolso de todos los importes pagados, incluidos los cargos de envío correspondientes al costo estándar, dentro de los catorce (14) días a partir de la fecha en que el Comprador haya informado a la empresa de su decisión de desistimiento.
                </Text>
                <Text>
                    El reembolso se realizará (sin coste para el Comprador) de acuerdo con los medios de pago utilizados para la transacción inicial, excepto con aceptación expresa y previa del Comprador de otros medios de reembolso.
                </Text>
                <Text>
                    Después del uso del derecho de desistimiento por parte del Comprador, ya no se podrá utilizar el Regalo de Experiencias.
                </Text>
                <Text fontSize='xl' fontWeight={'semibold'}>
                    Artículo 1.4: Entrega
                </Text>
                <Text fontSize='xl' fontWeight={'semibold'}>
                    Artículo 1.4.1: Disponibilidad de los Productos, Servicios, Experiencias y Bonos  de Experiencias
                </Text>
                <Text>
                    En caso de agotarse existencias temporal o permanentemente de algún producto, servicio, experiencia o bono regalo se informará al Comprador y el pedido no se podrá completar en este formato.
                </Text>
                <Text fontSize='xl' fontWeight={'semibold'}>
                    Artículo 1.4.2: Lugar y modo de entrega
                </Text>
                <Text>
                    La adquisición de un servicio, experiencia o bono regalo se remitirá por mail a la dirección de correo electrónico del comprador, con el correspondiente código de adquisición para que pueda acreditar la compra al disfrutar del servicio o experiencia los días elegidos.
                </Text>
                <Text>
                    Los productos se enviarán al domicilio que señale el comprador al efectuar la compra. Los gastos de envío serán por cuenta del comprador, que tendrá información detallada sobre dicho coste antes de confirmar la adquisición.
                </Text>
                <Text fontSize='xl' fontWeight={'semibold'}>
                    Artículo 2.1: Condiciones de uso de experiencias, servicios y bonos regalo.
                </Text>
                <Text fontSize='xl' fontWeight={'semibold'}>
                    Artículo 2.1.1. Activación de los Regalos de bonos de Experiencias y servicios
                </Text>
                <Text>
                    Los Regalos de bonos de Experiencias y servicios se activan cuando se pagan.
                </Text>
                <Text fontSize='xl' fontWeight={'semibold'}>
                    Artículo 2.2: Periodo de validez y condiciones específicas aplicables determinadas experiencias y servicios.
                </Text>
                <Text>
                    Tanto las experiencias como servicios pueden tener diferentes períodos de validez y diferentes fechas para ser disfrutados.
                </Text>
                <Text>
                    Por este motivo pueden estar sujetos a condiciones específicas en función de la categoría a la cual pertenezcan.
                </Text>
                <Text>
                    En este sentido, la empresa se reserva el derecho a completar y/o modificar la lista de servicios o experiencias y suspender su distribución a su entera discreción y en cualquier momento.
                </Text>
                <Text fontWeight={'bold'}>
                    En el caso de que cese la comercialización de una experiencia o servicio antes del fin del periodo de validez, la empresa propondrá al Beneficiario un mecanismo de cambio de conformidad con lo previsto en el artículo 2.3.
                </Text>
                <Text fontSize='xl' fontWeight={'semibold'}>
                    Artículo 2.3: Condiciones específicas a las cuales son sujetos algunas experiencias o servicios
                </Text>
                <Text>
                    La empresa puede comercializar experiencias de ocio (parques temáticos, zoológicos, etc.). Esta categoría de Experiencias permite a los Beneficiarios de beneficiarse de Experiencias en parques temáticos, zoos, etc….. Para estas Experiencias se invita el Beneficiario a consultar la página del Colaborador correspondiente en el Sitio y a informarse sobre las condiciones específicas que pueden aplicarse.
                </Text>
                <Text fontSize='xl' fontWeight={'semibold'}>
                    Artículo 2.4: Modalidades de reembolso
                </Text>
                <Text fontSize='xl' fontWeight={'semibold'}>
                    Artículo 2.4.1: El cambio realizado en línea
                </Text>
                <Text>
                    Para conocer las condiciones de cambio aplicables al Regalo de Experiencia deseado se invita al Beneficiario a remitirse a las condiciones generales de venta aplicables en el momento de la compra de éste. Estas condiciones están disponibles en el Sitio pulsando sobre el apartado «Informaciones legales. Términos y condiciones genenrales sobre la venta».
                </Text>
                <Text>
                    En todos los casos el cambio se reserva a los bonos o regalos de experiencia que no hayan sido utilizados.
                </Text>
                <Text>
                    Salvo ejercicio del derecho de rescisión la compra de un servicio, experiencia o producto o bono regalo nunca es reembolsable.
                </Text>
                <Text>
                    Todo cambio se efectuará siempre en función del valor de la compra de la experiencia o servicio o bono regalo que se desea cambiar, atendiendo al precio real abonado por el comprador.
                </Text>
                <Text>
                    El cambio permite aprovechar un nuevo servicio, experiencia o bono regalo, cuyo periodo de validez será idéntico al periodo de validez restante del Regalo de Experiencia intercambiado.
                </Text>
                <Text>
                    El cambio permite aprovechar un nuevo servicio, experiencia o bono regalo, cuyo periodo de validez será idéntico al periodo de validez restante del Regalo de Experiencia intercambiado.
                </Text>
                <Text>
                    Los gastos inherentes a los cambios están sujetos a modificaciones. Se invita al Beneficiario a consultar el Sitio para conocer el importe el día en que desea cambiar.
                </Text>
                <Text fontSize='xl' fontWeight={'semibold'}>
                    Artículo 2.5: Modalidades de renovación
                </Text>
                <Text>
                    Los servicios, experiencias y bonos regalos de estos no son renovables. En caso de caducidad de un servicio o experiencia o su bono relago no se podrá canjear ni hacer uso de él.
                </Text>
                <Text fontSize='xl' fontWeight={'semibold'}>
                    Sección 3: Disposiciones comunes
                </Text>
                <Text fontSize='xl' fontWeight={'semibold'}>
                    Artículo 3.1: Servicio de Atención al Cliente – Reclamaciones
                </Text>
                <Text>
                    Toda otra solicitud de información y aclaraciones sobre, la compra, el reembolso, el cambio y el funcionamiento de servicios o experiencias o bonos regalo, debe estar dirigida a la empresa por correo electrónico a {generalSettings.email}.
                </Text>
                <Text fontSize='xl' fontWeight={'semibold'}>
                    Artículo 3.2: Política de confidencialidad
                </Text>
                <Text fontSize='xl' fontWeight={'semibold'}>
                    Artículo 3.2.1 Protección de datos personales
                </Text>
                <Text>
                    Para conocer las características de la política de protección de datos personales implementada por la empresa, se invita al Usuario, al Comprador y al Beneficiario a leer la Política de privacidad disponible en el Sitio web <Link href={privacyUrl}>{privacyUrl}</Link>.
                </Text>

                <Text fontSize='xl' fontWeight={'semibold'}>
                    Artículo 3.2.2 Cookies
                </Text>
                <Text>
                    Para obtener más información sobre el uso de cookies, se invita al Usuario, al Comprador y al Beneficiario a leer la Política de la empresa en materia de cookies disponible en el Sitio <Link href={cookiePolicyUrl}>{cookiePolicyUrl}</Link>.
                </Text>
                <Text fontSize='xl' fontWeight={'semibold'}>
                    Artículo 3.3: Propiedad intelectual
                </Text>
                <Text>
                    Los componentes de servicios, productos y experiencias y bonos regalos, incluyendo el texto, las fotografías, las imágenes, los iconos y los logotipos, son propiedad exclusiva de la empresa. Está estrictamente prohibido reproducir, representar, modificar, transmitir, publicar, adaptar, en cualquier soporte, por cualquier medio, o dar uso de cualquier manera, de la totalidad o parte de estos elementos sin la autorización escrita y previa de la empresa.
                </Text>
                <Text>
                    El Sitio es una creación protegida por la ley de propiedad intelectual. El Sitio y cada uno de los elementos que lo componen (como marcas comerciales, logotipos, textos, etc.) son propiedad exclusiva de la empresa o terceros, y como tales, gozan de protección a título de la propiedad intelectual.
                </Text>
                <Text>
                    Al acceder al Sitio, el Usuario, el Comprador y el Beneficiario reciben una licencia de uso limitado para el contenido del Sitio, que no es exclusiva y es solo para su uso privado.
                </Text>
                <Text>
                    Por lo tanto, la reproducción de todos los documentos publicados en el Sitio solo está autorizada exclusivamente para fines de información y para un uso estrictamente personal y privado.
                </Text>
                <Text>
                    Cualquier reproducción, modificación, etc., en su totalidad o en parte, del Sitio o cualquiera de los elementos que lo componen que pertenezcan a la empresa o terceros, en cualquier soporte, para cualquier otro propósito, que no sea el uso personal y privado, y en particular para un propósito comercial, sin el permiso previo por escrito de la empresa o de terceros titulares de los derechos de la creación, está expresamente prohibida y expone al Usuario, al Comprador y/o al Beneficiario del Sitio a un procedimiento penal y civil.
                </Text>
                <Text fontSize='xl' fontWeight={'semibold'}>
                    Artículo 3.4: Independencia de las cláusulas
                </Text>
                <Text>
                    El hecho de que la empresa no haga valer en un momento determinado de una de las cláusulas de estas Condiciones Generales no puede interpretarse como una renuncia por parte de la misma a cualquiera de dichas condiciones en una fecha posterior.
                </Text>
                <Text>
                    En el caso de que una de estas disposiciones se declare nula o sin efecto, inválida o inaplicable, esto no afectará a la validez de las demás disposiciones.
                </Text>
                <Text fontSize='xl' fontWeight={'semibold'}>
                    Artículo 3.5: Derecho aplicable y resolución de conflictos
                </Text>
                <Text>
                    Estas Condiciones Generales y el contrato celebrado con el usuario o adquirente de cualquier producto, incluidas las cuestiones relativas a su validez, su interpretación, su ejecución o su resolución, están sujetos al derecho español.
                </Text>
                <Text>
                    En el caso de cualquier disputa, las Partes se comprometen, antes de emprender cualquier acción contenciosa, a actuar de buena fe y buscar una solución amistosa en un plazo de treinta (30) días. Por lo tanto, cualquier reclamación se realizará primero por escrito al Servicio de Atención al Cliente de la empresa, contactando por email a {generalSettings.email}.
                </Text>
                <Text>
                    En caso de que no se alcance una solución amistosa en relación con esta cuestión, el Cliente puede solicitar una mediación de consumo en su oficina municipal de información al consumidor (OMIC), de su localidad o la Dirección General de Consumo de su Comunidad Autónoma, o a la organización de consumidores de la que sea asociado.
                </Text>
                <Text>
                    Si no se pudiera llegar a un acuerdo amistoso, la disputa se someterá a la jurisdicción exclusiva del tribunal de la jurisdicción en la que el Comprador o el Beneficiario tenga su domicilio o residencia habitual, salvo que existan normas de procedimiento en contrario.
                </Text>
            </Stack>

        </>
    )
}