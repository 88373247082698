import { Text, Image, Box, Button, Container, List, ListItem, Stack, Flex, Link as ChakraLink, LinkOverlay, LinkBox } from '@chakra-ui/react';
import { Link } from 'react-router-dom';
import GiftboxLogo from '../../assets/img/giftbox-light.svg';

type FooterProps = {
    generalSettings: any;
    legalSettings: any;
}

const FooterLink = ({ to, label }: { to: string, label: string }) => (
    <Button
        sx={{ _hover: {}, _active: {} }}
        fontWeight='medium'
        variant='link'
        color='white'
        as={Link}
        to={to}>
        {label}
    </Button>
)

export const Footer = ({ generalSettings, legalSettings }: FooterProps) => (
    <Box as='footer' role='contentinfo' bg='gray.800'>
        <Container p={8}>
            <Stack spacing={8} direction={{ base: 'column', md: 'row' }} justify='space-between'>
                <List spacing={1} color='white'>
                    <ListItem fontWeight={'bold'} mb={2}>
                        {generalSettings.name}
                    </ListItem>
                    <ListItem>
                        {legalSettings.address}
                    </ListItem>
                    <ListItem>
                        {`${legalSettings.postCode ? legalSettings.postCode + ', ' : ''} ${legalSettings.city}, ${legalSettings.country}`}
                    </ListItem>
                    <ListItem>
                        <ChakraLink href={`tel:${generalSettings.phone}`}>{generalSettings.phone}</ChakraLink>
                    </ListItem>
                    <ListItem>
                        <ChakraLink href={`mailto:${generalSettings.email}`}>{generalSettings.email}</ChakraLink>
                    </ListItem>
                </List>
                <List spacing={1} color='white'>
                    <ListItem>
                        <FooterLink to='legal' label='Aviso legal' />
                    </ListItem>
                    <ListItem>
                        <FooterLink to='privacy' label='Política de privacidad' />
                    </ListItem>
                    <ListItem>
                        <FooterLink to='cookies' label='Política de cookies' />
                    </ListItem>
                    <ListItem>
                        <FooterLink to='terms-conditions' label='Términos y condiciones' />
                    </ListItem>

                </List>
            </Stack>
            <Flex justify={'flex-end'} mt={8} p={8}>

                <LinkBox>
                    <LinkOverlay target={'blank'} href='https://www.easygoband.com'>
                        <Text fontWeight={'thin'} color='white'>Powered by</Text>
                        <Image width={120} src={GiftboxLogo} />
                    </LinkOverlay>
                </LinkBox>
            </Flex>
        </Container>
    </Box>
)
