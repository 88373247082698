import { getResizedWebpImgSource, ResourceSize } from '../../../helpers';
import { Image, ImageProps } from '@chakra-ui/react';
import { useEffect, useState } from 'react';
import ImgPlaceholder from '../../../assets/img/img-placeholder.svg'

type ResizedImageProps = ImageProps & {
    resizeTo?: ResourceSize;
}

export const ResizedImageWithDefaultFallback = ({
    src,
    fallbackSrc,
    resizeTo = ResourceSize.LARGE,
    ...rest
}: ResizedImageProps) => {
    const [resizedSrc, setResizedSrc] = useState<string>();
    useEffect(() => {
        if (src) {
            getResizedWebpImgSource(src, resizeTo).then(setResizedSrc)
        } else {
            setResizedSrc('');
        }
    }, [src])

    return (
        <Image
            src={resizedSrc}
            fallbackSrc={fallbackSrc ? fallbackSrc : ImgPlaceholder}
            {...rest} />
    )
}

