import {
    Box,
    Heading,
    HStack,
    Radio,
    RadioGroup,
    Stack,
    Text,
  } from '@chakra-ui/react'
  
  export const DeliveryMethod = () => (
    <Stack spacing={{ base: '6', md: '10' }}>
      <Heading size='md'>Método de entrega</Heading>
      <RadioGroup colorScheme='gray' size='lg'>
        <Stack direction={{ base: 'column', lg: 'row' }} spacing='6'>
          <Radio defaultChecked spacing='3' flex='1'>
            <Box>
              <HStack>
                <Text fontWeight='medium'>Digital</Text>
              </HStack>
              <Text color={'gray.600'}>Te enviaremos un PDF a tu correo electrónico.</Text>
            </Box>
          </Radio>
        </Stack>
      </RadioGroup>
    </Stack>
  )