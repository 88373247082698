import { Box, Text, Stack } from '@chakra-ui/react';

type FormControlGroupProps = {
    title: string;
    description?: string;
    children?: React.ReactNode | React.ReactNode[];
}
export const FormControlGroup = (props: FormControlGroupProps) => (
    <Stack
        direction={{ base: 'column', xl: 'row' }}
        spacing={{ base: '5', xl: '8' }}
    >
        <Box flexShrink={0} width={{base:'100%',xl:'250px'}}>
            <Text fontSize='lg' fontWeight='medium'>
                {props.title}
            </Text>
            <Text color='muted' fontSize='sm'>
                {props.description ?? ''}
            </Text>
        </Box>
        <Box bg='bg-surface' boxShadow='sm' borderRadius='lg' flexGrow={1} pb={4}>
            <Stack spacing='5' px={{ base: '4', md: '6' }} py={{ base: '5', md: '6' }}>
                {props.children}
            </Stack>
        </Box>
    </Stack>
)