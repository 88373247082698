import { ListViewHeader } from '../components/ListViewHeader';
import { HiTemplate } from 'react-icons/hi';
import { IoMdAdd } from 'react-icons/io';
import { HotelSpecifficSection } from '../components/HotelSpecifficSection';
import { Thead, Tr, Th, Tbody, Td, Text, useToast, Badge } from '@chakra-ui/react';
import { BasicTable } from '../components/tables/BasicTable';
import { BasicTableContainer } from '../components/tables/BasicTableContainer';
import { EditButton } from '../components/tables/EditButton';
import { query, collection, orderBy, DocumentSnapshot, doc } from 'firebase/firestore';
import debounce from 'lodash.debounce';
import { useState, useCallback, useEffect } from 'react';
import { useCollection, useDocumentData } from 'react-firebase-hooks/firestore';
import { firebaseFirestore } from '../../../firebase';
import { formatPrice, showToast } from '../../../helpers';
import { useConsoleContext } from '../../../layout/console/authenticated/AuthenticatedConsoleLayout';


const WrappedView = () => {
    const toast = useToast();
    const context = useConsoleContext();

    const [searchQuery, setSearchQuery] = useState<string>();
    const changeHandler = (event: any) => setSearchQuery(event.target.value);
    const debouncedChangeHandler = useCallback(debounce(changeHandler, 300), []);

    const [legalSettingsDataSnapshot, _loading, legalSettingLoadingError] = useDocumentData(
        doc(
            firebaseFirestore,
            'clients',
            context.settings.selectedClient!!.id,
            'hotels',
            context.settings.selectedHotel!!.id,
            'settings',
            'legal'
        )
    );

    const [productsQuerySnapshot, isLoading, productsLoadingError] = useCollection(
        query(
            collection(
                firebaseFirestore,
                'clients',
                context.settings.selectedClient!!.id,
                'hotels',
                context.settings.selectedHotel!!.id,
                'products'
            ),
            orderBy('updated', 'desc')
        )
    );

    useEffect(() => {
        if (productsLoadingError) {
            console.error(productsLoadingError);
            showToast(toast, {
                title: 'Error al obtener el listado de productos.',
                status: 'error'
            });
        }
        if (legalSettingLoadingError) {
            console.error(legalSettingLoadingError);
            showToast(toast, {
                title: 'Error al obtener el la información legal del hotel.',
                status: 'error'
            });
        }
    }, [productsLoadingError, legalSettingLoadingError])

    useEffect(() => {
        return () => {
            debouncedChangeHandler.cancel();
        }
    }, []);

    const filteredProducts = productsQuerySnapshot?.docs.filter((d) => {
        if (!searchQuery) return d;
        const lowerCaseQuery = searchQuery.toLowerCase();
        return d.data().name.toLowerCase().includes(lowerCaseQuery);
    });

    const getCompountProductId = (product: DocumentSnapshot) => {
        return `${context.settings.selectedClient!!.id}-${context.settings.selectedHotel!!.id}-${product.id}`;
    }

    return (
        <BasicTableContainer
            title={'Productos'}
            onSearch={debouncedChangeHandler}
            caption={`Mostrando ${filteredProducts?.length} de ${productsQuerySnapshot?.docs.length} resultados`}>
            <BasicTable
                isLoading={isLoading}
                isEmpty={filteredProducts?.length === 0}>
                <Thead>
                    <Tr>
                        <Th width={8}></Th>
                        <Th>Id</Th>
                        <Th>Nombre</Th>
                        <Th>Precio</Th>
                        <Th>Estado</Th>
                    </Tr>
                </Thead>
                <Tbody>
                    {filteredProducts?.map((product) => (
                        <Tr key={product.id}>
                            <Td>
                                <EditButton to={`/console/products/${getCompountProductId(product)}`} />
                            </Td>
                            <Td>
                                <Text color='muted'>{product.id}</Text>
                            </Td>
                            <Td>
                                <Text color='muted'>{product.data().name}</Text>
                            </Td>
                            <Td>
                                <Text color='muted'>
                                    {formatPrice(product.data().price, legalSettingsDataSnapshot as any)}
                                </Text>
                            </Td>
                            <Td>
                                <Badge size='md' variant='solid' colorScheme={product.data().disabled ? 'red' : 'green'}>
                                    {product.data().disabled ? 'DESHABILITADO' : 'HABILITADO'}
                                </Badge>
                            </Td>
                        </Tr>
                    ))}
                </Tbody>
            </BasicTable>
        </BasicTableContainer>
    );
}

export const ProductsList = () => {
    const context = useConsoleContext();
    return (
        <>
            <ListViewHeader
                icon={HiTemplate}
                title={'Administración de productos'}
                subtitle={'Ver, editar y añadir productos'}
                buttonIcon={IoMdAdd}
                buttonTitle={'Añadir producto'}
                buttonTo={'/console/products/new'}
                showButton={context.settings.selectedHotel !== undefined} />
            <HotelSpecifficSection>
                <WrappedView />
            </HotelSpecifficSection>
        </>
    )
}
