import {
    Modal,
    ModalOverlay,
    ModalContent,
    ModalHeader,
    ModalCloseButton,
    ModalBody,
    ModalFooter,
    Button,
    Alert,
    AlertDescription,
    AlertIcon,
    AlertTitle,
    AlertStatus
} from "@chakra-ui/react"

type AlertModalProps = {
    status: AlertStatus;
    title: string;
    message: string;
    isOpen: boolean;
    onClose: () => void;
}

export const AlertModal = (props: AlertModalProps) => {
    const { isOpen, onClose, title, message, status } = props;
    return (
        <Modal isOpen={isOpen} onClose={onClose} isCentered>
            <ModalOverlay />
            <ModalContent>
                <ModalHeader />
                <ModalCloseButton />
                <ModalBody>
                    <Alert
                        status={status}
                        variant='subtle'
                        flexDirection='column'
                        alignItems='center'
                        justifyContent='center'
                        textAlign='center'
                    >
                        <AlertIcon boxSize='60px' mr={0} />
                        <AlertTitle mt={4} mb={2} fontSize='lg'>
                            {title}
                        </AlertTitle>
                        <AlertDescription maxWidth='sm' mb={4}>
                            {message}
                        </AlertDescription>
                    </Alert>
                </ModalBody>
                <ModalFooter>
                    <Button variant='ghost' onClick={onClose}>
                        Cerrar
                    </Button>
                </ModalFooter>
            </ModalContent>
        </Modal>
    )
}