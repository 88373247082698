import { Box, Center, Flex, Spinner, Stack } from '@chakra-ui/react';
import { doc } from 'firebase/firestore';
import { useEffect } from 'react';
import { useDocumentData } from 'react-firebase-hooks/firestore';
import { Navigate, useParams } from 'react-router-dom';
import { firebaseFirestore, logAnalyticsEvent } from '../../../firebase';
import { useHotelContext } from '../../../layout/hotel/HotelLayout';
import { OrderSummary } from './OrderSummary';
import { PaymentMethod } from './PaymentMethod';
import { BuyerInformation } from './BuyerInformation';
import { DeliveryMethod } from './DeliveryMethod';
import { useForm } from 'react-hook-form';
import { CheckoutFormData } from '../../../types/CheckoutFormData';
import { httpsCallable } from '@firebase/functions';
import { firebaseFunctions } from '../../../firebase'

const checkoutCallable = httpsCallable<any, any>(firebaseFunctions, 'orders-checkout');

export const Checkout = () => {
    const { productId } = useParams();
    const context = useHotelContext();
    const [
        productDataSnapshot,
        isLoadingProductData,
        hasProductDataLoadingError
    ] = useDocumentData(
        doc(
            firebaseFirestore,
            'clients',
            context.clientId,
            'hotels',
            context.hotelId,
            'products',
            productId!!
        )
    );

    useEffect(() => {
        if (hasProductDataLoadingError) {
            console.error(hasProductDataLoadingError);
        }
    }, [hasProductDataLoadingError]);

    useEffect(()=>{
        if(productDataSnapshot){
            logAnalyticsEvent('begin_checkout',{
                currency: context.settings.legal.currency,
                value: productDataSnapshot.price,
                items:[{
                    item_id: productId,
                    item_name: productDataSnapshot.name,
                    location_id: context.settings.general.name
                }]
            })
        }
    },[productDataSnapshot])

    const {
        handleSubmit,
        register,
        formState
    } = useForm<CheckoutFormData>();

    const submit = (data: CheckoutFormData) => new Promise<void>(async (resolve, reject) => {
        try {
            logAnalyticsEvent('add_payment_info',{
                currency: context.settings.legal.currency,
                value: productDataSnapshot!!.price,
                payment_type:'PAYLANDS',
                items:[{
                    item_id: productId,
                    item_name: productDataSnapshot!!.name,
                    location_id: context.settings.general.name
                }]
            })
            const response = await checkoutCallable({
                ...data,
                productId: productId,
                clientId: context.clientId,
                hotelId: context.hotelId
            });
            if (response.data.paymentLink) {
                window.location.href = response.data.paymentLink;
            } else {
                resolve();
            }
        } catch (e) {
            console.error(e)
            resolve();
        }
    })

    if (!isLoadingProductData && !productDataSnapshot) {
        return <Navigate to='/not-found' replace />
    }

    return (
        <>
            {isLoadingProductData &&
                <Center height={'full'}>
                    <Spinner size={'lg'} color='black' />
                </Center>
            }
            {!isLoadingProductData &&
                <Flex
                    maxW='8xl'
                    as='form'
                    noValidate
                    onSubmit={handleSubmit(submit)}
                    mx='auto'
                    direction={{ base: 'column', md: 'row' }}
                    boxShadow={'sm'}
                    borderRadius='2xl'>
                    <Box
                        flex='1'
                        borderLeftRadius={'2xl'}
                        borderTopRightRadius={{ base: '2xl', md: 'none' }}
                        bg='bg-surface'
                        px={{ base: '4', md: '8', lg: '12', xl: '20' }}
                        py={{ base: '6', md: '8', lg: '12', xl: '20' }}
                    >
                        <Stack spacing={{ base: '16', lg: '20' }}>
                            <BuyerInformation register={register} formState={formState} />
                            <DeliveryMethod />
                            <PaymentMethod />
                        </Stack>
                    </Box>
                    <Box
                        flex='1'
                        bg={{ base: 'bg-surface', md: 'bg-canvas' }}
                        borderRightRadius={'2xl'}
                        borderBottomLeftRadius={{ base: '2xl', md: 'none' }}
                        maxW={{ lg: 'md', xl: '40rem' }}
                        px={{ base: '4', md: '8', lg: '12', xl: '20' }}
                        py={{ base: '6', md: '8', lg: '12', xl: '20' }}
                    >
                        <OrderSummary product={productDataSnapshot} formState={formState} />
                    </Box>
                </Flex>
            }
        </>
    )
}