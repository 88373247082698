import { Container, Stack, Box } from '@chakra-ui/react'
import { Outlet } from 'react-router'
import { HotelContext } from '../../types/HotelContext'
import { useEffect } from 'react';
import { useCookies } from 'react-cookie';
import { DateTime } from 'luxon';

export type WidgetProps = {
  context: HotelContext;
}

export const Widget = ({ context }: WidgetProps) => {
  const [_cookies, setCookie] = useCookies(['ggb-cookie-consent']);

  useEffect(() => {
    setCookie(
      'ggb-cookie-consent',
      `required+statistics|${DateTime.now().toISO()}`,
      {
        path: '/',
        expires: DateTime.now().plus({ year: 1 }).toJSDate(),
        sameSite: 'strict'
      }
    );
  }, []);
  return (
    <>
      <Container as='main' role='main' flex='1' pt={{ base: 8 }}>
        <Stack spacing={8}>
          <Outlet context={context} />
        </Stack>
        <Box h='8' data-iframe-height></Box>
      </Container>
    </>
  )
}