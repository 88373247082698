import {
    Box,
    Text,
    Center,
    SimpleGrid,
    Spinner,
    Stack,
    AspectRatio,
    Button,
    WrapItem,
    Wrap,
    Flex,
    HStack,
    RangeSlider,
    RangeSliderFilledTrack,
    RangeSliderMark,
    RangeSliderThumb,
    RangeSliderTrack,
    Badge,
} from '@chakra-ui/react';
import { DocumentSnapshot, collection, limit, orderBy, query, where } from 'firebase/firestore';
import { useCallback, useEffect, useState } from 'react';
import { useCollection } from 'react-firebase-hooks/firestore';
import { BsFillEyeFill, BsToggleOff } from 'react-icons/bs';
import { BiSliderAlt } from 'react-icons/bi';
import { IoAdd } from 'react-icons/io5';
import { Link } from 'react-router-dom';
import { firebaseFirestore } from '../../../firebase';
import { formatPrice, ResourceSize } from '../../../helpers';
import { useHotelContext } from '../../../layout/hotel/HotelLayout';
import { ResizedImageWithDefaultFallback } from '../components/ResizedImage';
import { SectionHeader } from '../components/SectionHeader';
import { CategoryTag } from './CategoryTag';
import debounce from 'lodash.debounce';

export const ProductsList = () => {
    const displayStep = 9;
    const [displayCount, setDisplayCount] = useState(displayStep);
    const hotelContext = useHotelContext();
    const [selectedCategories, setSelectedCategories] = useState<DocumentSnapshot[]>([]);

    const [priceSliderValue, setPriceSliderValue] = useState([0, 9999]);

    const [minMaxPrice, setMinMaxPrice] = useState([0, 9999]);

    const [
        categoriesCollectionSnapshot,
        isLoadingCategoriesCollection,
        hasCategoriesCollectionLoadingError
    ] = useCollection(
        query(
            collection(
                firebaseFirestore,
                'clients',
                hotelContext.clientId,
                'hotels',
                hotelContext.hotelId,
                'categories'
            )
        )
    );

    const constraints = [
        where('disabled', '==', false),
        where('price', '>=', minMaxPrice[0]),
        where('price', '<=', minMaxPrice[1])
    ];

    if (selectedCategories.length > 0) {
        constraints.push(
            where('categories', 'array-contains-any', selectedCategories.map(c => c.data()?.name))
        )
    }


    const [
        productsCollectionSnapshot,
        isLoadingProductsCollection,
        hasProductsCollectionLoadingError
    ] = useCollection(
        query(
            collection(
                firebaseFirestore,
                'clients',
                hotelContext.clientId,
                'hotels',
                hotelContext.hotelId,
                'products'
            ),
            ...constraints
        )
    );

    const [
        productsCollectionSortedByPriceAscSnapshot,
        isLoadingProductsCollectionSortedByPriceAsc,
        hasProductCollectionSortedByPriceAscLoadingError
    ] = useCollection(
        query(
            collection(
                firebaseFirestore,
                'clients',
                hotelContext.clientId,
                'hotels',
                hotelContext.hotelId,
                'products'
            ),
            where('disabled', '==', false),
            orderBy('price', 'asc'),
            limit(1)
        )
    );

    const [
        productsCollectionSortedByPriceDescSnapshot,
        isLoadingProductsCollectionSortedByPriceDesc,
        hasProductCollectionSortedByPriceDescLoadingError
    ] = useCollection(
        query(
            collection(
                firebaseFirestore,
                'clients',
                hotelContext.clientId,
                'hotels',
                hotelContext.hotelId,
                'products'
            ),
            where('disabled', '==', false),
            orderBy('price', 'desc'),
            limit(1)
        )
    );

    useEffect(() => {
        if (hasProductsCollectionLoadingError) {
            console.error(hasProductsCollectionLoadingError);
        }
        if (hasCategoriesCollectionLoadingError) {
            console.error(hasCategoriesCollectionLoadingError);
        }
        if (hasProductCollectionSortedByPriceAscLoadingError) {
            console.error(hasProductCollectionSortedByPriceAscLoadingError);
        }
        if (hasProductCollectionSortedByPriceDescLoadingError) {
            console.error(hasProductCollectionSortedByPriceDescLoadingError);
        }
    }, [
        hasProductsCollectionLoadingError,
        hasCategoriesCollectionLoadingError,
        hasProductCollectionSortedByPriceAscLoadingError,
        hasProductCollectionSortedByPriceDescLoadingError,
    ]);

    const viewMore = () => {
        setDisplayCount(displayStep + displayCount);
    }

    const getMinPrice = () => {
        if (productsCollectionSortedByPriceAscSnapshot?.docs.length ?? 0 > 0) {
            return productsCollectionSortedByPriceAscSnapshot?.docs[0].data()!!.price;
        }
        return 0;
    }

    const getMaxPrice = () => {
        if (productsCollectionSortedByPriceDescSnapshot?.docs.length ?? 0 > 0) {
            return productsCollectionSortedByPriceDescSnapshot?.docs[0].data()!!.price;
        }
        return 0;
    }

    const filteredProducts = productsCollectionSnapshot?.docs.slice(
        0,
        productsCollectionSnapshot.docs.length >= displayCount ?
            displayCount :
            productsCollectionSnapshot.docs.length
    );

    const isLoading = isLoadingCategoriesCollection ||
        isLoadingProductsCollection ||
        isLoadingProductsCollectionSortedByPriceDesc ||
        isLoadingProductsCollectionSortedByPriceAsc;

    useEffect(() => {
        setPriceSliderValue([getMinPrice(), getMaxPrice()]);
    }, [
        productsCollectionSortedByPriceDescSnapshot,
        productsCollectionSortedByPriceAscSnapshot
    ]);

    const priceSliderChangeHandler = (values: number[]) => setMinMaxPrice(values);
    const debouncedPriceChangeHandler = useCallback(debounce(priceSliderChangeHandler, 300), []);

    useEffect(() => {
        debouncedPriceChangeHandler(priceSliderValue)
        return debouncedPriceChangeHandler.cancel;
    }, [priceSliderValue]);

    return (
        <>
            <SectionHeader
                title={hotelContext.settings.copy.landingPageTitle}
                subTitle={hotelContext.settings.copy.landingPageSubtitle}
            />
            <Wrap>
                <WrapItem>
                    <CategoryTag
                        name={'Todas las categorías'}
                        selected={selectedCategories.length === 0}
                        canDeselect={false}
                        onDeselect={() => {
                            setSelectedCategories([]);
                        }}
                        onSelect={() => {
                            setSelectedCategories([]);
                        }}
                    />
                </WrapItem>
                {categoriesCollectionSnapshot?.docs.map(category => (
                    <WrapItem key={category.id}>
                        <CategoryTag
                            name={category.data().name}
                            selected={selectedCategories.find(c => c.id === category.id) !== undefined}
                            canDeselect={true}
                            onSelect={() => {
                                setSelectedCategories([...selectedCategories, category]);
                            }}
                            onDeselect={() => {
                                const index = selectedCategories.findIndex(c => c.id === category.id)
                                if (index >= 0) {
                                    const newSelectedCategories = [...selectedCategories];
                                    newSelectedCategories.splice(index, 1)
                                    setSelectedCategories(newSelectedCategories);
                                }
                            }} />
                    </WrapItem>
                ))}
            </Wrap>
            <Flex py={8} pr={8}> 
                <HStack spacing='8' width={'full'} justify={'flex-end'}>
                    <BiSliderAlt fontSize={'2rem'} />
                    <Text fontWeight={'bold'}>Precio</Text>
                    <RangeSlider
                        aria-label={['min', 'max']}
                        min={getMinPrice()}
                        max={getMaxPrice()}
                        value={priceSliderValue}
                        onChange={(val) => setPriceSliderValue(val)}
                        maxW={300}>
                        <RangeSliderMark
                            value={priceSliderValue[0]}
                            textAlign='center'
                            mt='-12'
                            ml='-8'
                            w='18'
                            fontWeight={'bold'}
                        >
                            {formatPrice(priceSliderValue[0], hotelContext.settings.legal)}
                        </RangeSliderMark>
                        <RangeSliderMark
                            value={priceSliderValue[1]}
                            textAlign='center'
                            fontWeight={'bold'}
                            mt='-12'
                            ml='-8'
                            w='18'
                        >
                            {formatPrice(priceSliderValue[1], hotelContext.settings.legal)}
                        </RangeSliderMark>
                        <RangeSliderTrack bg={hotelContext.settings.theme.colors.palette[100]}>
                            <RangeSliderFilledTrack bg={hotelContext.settings.theme.colors.palette[600]} />
                        </RangeSliderTrack>
                        <RangeSliderThumb boxSize={6} index={0} />
                        <RangeSliderThumb boxSize={6} index={1} />
                    </RangeSlider>
                </HStack>
            </Flex>
            {isLoading &&
                <Center>
                    <Spinner size={'lg'} color='black' />
                </Center>
            }
            {filteredProducts?.length === 0 &&
                <Center mt={8}>
                    <Stack align={'center'} spacing={4}>
                        <BsToggleOff size='80px' />
                        <Text color='muted'>Actualmente no hay productos disponibles con los criterios de búsqueda</Text>
                    </Stack>
                </Center>
            }
            <SimpleGrid
                columns={{ base: 1, md: 2, lg: 3 }}
                columnGap='8'
                rowGap={{ base: '6', md: '8', lg: '16' }}
            >
                {filteredProducts?.map((d) => (
                    <Box key={d.id} bg='bg-surface' p={3} boxShadow='sm' borderRadius='2xl'>
                        <Stack spacing={6} height='full'>
                            <AspectRatio>
                                <ResizedImageWithDefaultFallback
                                    borderRadius={'2xl'}
                                    src={d.data().image}
                                    resizeTo={ResourceSize.SMALL}
                                />
                            </AspectRatio>
                            <Stack spacing={4} px={4} flexGrow='1'>
                                <Stack flexGrow={'1'}>
                                    <Text fontWeight={'medium'}>{d.data().name}</Text>
                                    <Wrap>
                                        {d.data().categories?.map((c: string) => (
                                            <WrapItem key={c}>
                                                <Badge variant='outline'>
                                                    {c}
                                                </Badge>
                                            </WrapItem>
                                        ))}
                                    </Wrap>
                                    <Text fontWeight={'light'}>{d.data().shortDescription}</Text>
                                </Stack>
                                <Text mt={4} fontSize={'2xl'} fontWeight='normal'>
                                    {formatPrice(d.data().price, hotelContext.settings.legal)}
                                </Text>
                            </Stack>
                            <Button
                                size='xl'
                                variant='solid'
                                as={Link}
                                to={d.id}
                                leftIcon={<BsFillEyeFill size={'25'} />}
                                color={hotelContext.settings.theme.colors.text}
                                _hover={{
                                    bg: hotelContext.settings.theme.colors.palette[600],
                                    textDecoration: 'none'
                                }}
                                _active={{
                                    bg: hotelContext.settings.theme.colors.palette[700]
                                }}
                                bg={hotelContext.settings.theme.colors.palette[500]}>
                                {'Más Info'}
                            </Button>
                        </Stack>
                    </Box>
                ))}
            </SimpleGrid>
            {displayCount < (productsCollectionSnapshot?.docs.length ?? 0) &&
                <Center>
                    <Button
                        size='xl'
                        variant='outline'
                        onClick={viewMore}
                        leftIcon={<IoAdd size={'25'} />}>
                        {'Ver más'}
                    </Button>
                </Center>
            }
        </>
    )
}