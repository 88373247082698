import {
    Button,
    FormControl,
    FormLabel,
    Input,
    Stack,
    Box,
    FormErrorMessage,
    InputRightElement,
    InputGroup,
    Icon,
    Switch
} from '@chakra-ui/react'
import {
    signInWithEmailAndPassword,
    browserSessionPersistence,
    browserLocalPersistence,
    setPersistence
} from '@firebase/auth'
import { useState } from 'react'
import { Controller, useForm } from 'react-hook-form'
import { Link } from 'react-router-dom'
import { AlertModal } from '../components/AlertModal'
import { firebaseAuth } from '../../../firebase'
import { Header } from './header'
import { AiFillEyeInvisible, AiFillEye } from 'react-icons/ai';
import { Validators } from '../../../validationPatterns'

type LoginFormData = {
    email: string;
    password: string;
    persistentLogin: boolean;
}

export const Login = () => {

    const [hasError, setHasError] = useState(false);
    const [showPassword, setShowPassword] = useState(false);

    const toggleShowPassword = () => {
        setShowPassword(!showPassword);
    }

    const login = (data: any) => {
        return new Promise<void>((resolve) => {
            if (data.persistentLogin) {
                localStorage.setItem('persistentLogin', 'true');
            } else {
                localStorage.removeItem('persistentLogin');
            }
            setPersistence(
                firebaseAuth,
                data.persistentLogin ?
                    browserLocalPersistence :
                    browserSessionPersistence
            )
                .then(() => signInWithEmailAndPassword(
                    firebaseAuth,
                    data.email,
                    data.password
                ))
                .catch((_e) => {
                    setHasError(true);
                    resetField('password');
                }).finally(() => {
                    resolve();
                });
        })
    }

    const {
        handleSubmit,
        resetField,
        control,
        register,
        formState: { errors, isSubmitting },
    } = useForm<LoginFormData>({
        defaultValues: {
            persistentLogin: localStorage.getItem('persistentLogin') ? true : false
        }
    });

    return (
        <>
            <AlertModal
                isOpen={hasError}
                status={'error'}
                title={'Usuario o contraseña incorrectos'}
                message={'Revisa los credenciales e inténtalo de nuevo. Si el error persiste intenta usar la función de recuperación de contraseña.'}
                onClose={() => {
                    setHasError(false);
                }}
            />
            <Header title={'Accede con tu cuenta'} />
            <Stack as='form' spacing='6' onSubmit={handleSubmit(login)} noValidate>
                <FormControl isRequired isInvalid={errors.email !== undefined}>
                    <FormLabel htmlFor='email'>Email</FormLabel>
                    <Input
                        id='email'
                        type='email'
                        autoComplete='username'
                        {...register('email', {
                            required: 'Campo obligatorio.',
                            pattern: {
                                value: Validators.email,
                                message: 'Introduce un correo electrónico válido.'
                            }
                        })}
                    />
                    <FormErrorMessage>
                        {errors.email &&
                            <>{errors.email.message}</>
                        }
                    </FormErrorMessage>
                </FormControl>
                <FormControl isRequired isInvalid={errors.password !== undefined}>
                    <FormLabel htmlFor='password'>Contraseña</FormLabel>
                    <InputGroup size='md'>
                        <Input
                            id='password'
                            autoComplete='current-password'
                            type={showPassword ? 'text' : 'password'}
                            {...register('password', {
                                required: 'Campo obligatorio.'
                            })}
                        />
                        <InputRightElement onClick={toggleShowPassword} sx={{ cursor: 'pointer' }}>
                            {!showPassword &&
                                <Icon as={AiFillEye} boxSize='6' color='brand.800' />
                            }
                            {showPassword &&
                                <Icon as={AiFillEyeInvisible} boxSize='6' color='brand.800' />
                            }
                        </InputRightElement>
                    </InputGroup>
                    <FormErrorMessage>
                        {errors.password &&
                            <>{errors.password.message}</>
                        }
                    </FormErrorMessage>
                </FormControl>
                <FormControl display='flex' alignItems='center'>
                    <FormLabel htmlFor='persistentLogin' mb='0'>
                        ¿Mantener la sesión iniciada?
                    </FormLabel>
                    <Controller
                        control={control}
                        name='persistentLogin'
                        render={({ field: { onChange, value, ref } }) => (
                            <Switch
                                colorScheme={'brand'}
                                ref={ref}
                                onChange={onChange}
                                isChecked={value}
                                id='persistentLogin' />
                        )}
                    />
                </FormControl>
                <Box display={'flex'} justifyContent='flex-end'>
                    <Button
                        variant={'link'}
                        as={Link}
                        to='../recover'
                        size='sm'
                        colorScheme={'brand'}
                    >
                        ¿Has olvidado tu contraseña?
                    </Button>
                </Box>
                <Button
                    colorScheme='brand'
                    isLoading={isSubmitting}
                    variant='primary'
                    type='submit'
                >
                    Iniciar sesión
                </Button>
            </Stack>
        </>
    )

}