import { HStack, Text } from '@chakra-ui/react';

type DataLabelProps = {
    title: string;
    value?: string | React.ReactNode;
}
export const DataLabel = (props: DataLabelProps) => (
    <HStack justify={'space-between'} maxW={{base:'100%',md:'450px'}} spacing={8}>
        <Text >{props.title}</Text>
        <Text fontWeight={'bold'} align={'right'}>{props.value ?? ''}</Text>
    </HStack>
)
