import { Link, List, ListItem, Stack, Text, UnorderedList } from '@chakra-ui/react';
import { useHotelContext } from '../../../layout/hotel/HotelLayout';
import { SectionHeader } from '../components/SectionHeader';

export const Privacy = () => {
    const context = useHotelContext();
    const legalSettings = context.settings.legal;
    const generalSettings = context.settings.general;
    const giftboxUrl = `${window.location.protocol}//${window.location.host}/hotel/${context.clientId}-${context.hotelId}`;

    return (
        <>
            <SectionHeader
                title='POLÍTICA DE PRIVACIDAD'
            />
            <Stack spacing={3}>
                <Text>
                    En {legalSettings.company} nos preocupamos por los datos personales que tratamos y por el exacto cumplimiento de la normativa vigente en materia de protección de datos personales, entre otras, el Reglamento (UE) 2016/679 del Parlamento Europeo y del Consejo de 27 de abril de 2016 relativo a la protección de las personas físicas en lo que respecta al tratamiento de datos personales y a la libre circulación de estos datos y por el que se deroga la Directiva 95/46/CE, así como la Ley Orgánica 3/2018, de 5 de diciembre, de Protección de Datos Personales y garantía de los derechos digitales.
                </Text>
                <Text>
                    De acuerdo con ello, se informa de las siguientes cuestiones relativas a los tratamientos de datos personales que realizamos en {legalSettings.company}:
                </Text>
                <Text fontSize='xl' fontWeight={'semibold'}>
                    RESPONSABLE DE TRATAMIENTO DE DATOS PERSONALES
                </Text>
                <List>
                    <ListItem><b>Identidad:</b> {legalSettings.company}</ListItem>
                    <ListItem><b>Identificación fiscal:</b> {legalSettings.taxId}</ListItem>
                    <ListItem><b>Dirección:</b> {legalSettings.address}, {legalSettings.city}, {legalSettings.country}</ListItem>
                    <ListItem><b>Teléfono:</b> {generalSettings.phone}</ListItem>
                    <ListItem><b>Correo electrónico:</b> {generalSettings.email}</ListItem>
                </List>
                <Text fontSize='xl' fontWeight={'semibold'}>
                    PRINCIPIOS RELATIVOS AL TRATAMIENTO
                </Text>
                <Text>
                    En el tratamiento de datos personales que se realiza en el mismo se respetan los principios exigidos por el Reglamento (UE) 2016/679 del Parlamento Europeo y del Consejo de 27 de abril de 2016 relativo a la protección de las personas físicas en lo que respecta al tratamiento de datos personales y a la libre circulación de estos datos y por el que se deroga la Directiva 95/46/CE (en adelante, RGPD):
                </Text>
                <Text>
                    <b>Principio de licitud, lealtad y transparencia:</b> los datos que recogemos son tratados de forma lícita, leal y transparente, con el previo consentimiento de los interesados cuando fuera necesario o, en su caso, para la ejecución de un contrato en el que el interesado es parte o para la aplicación a petición de este de medidas precontractuales, o bien, si el tratamiento es necesario para el cumplimiento de una obligación legal aplicable, o para la satisfacción de intereses legítimos perseguidos por el responsable o por un tercero, siempre que sobre dichos intereses no prevalezcan los intereses o los derechos y libertados fundamentales del interesado que requieran la protección de datos personales, en particular cuando el interesado sea un niño.
                </Text>
                <Text>
                    <b>Principio de limitación de la finalidad:</b> los datos personales que tratamos se utilizan para las finalidades indicadas en el apartado “¿Con qué finalidad utilizamos sus datos personales?”.
                </Text>
                <Text>
                    <b>Principio de minimización de datos:</b> de acuerdo con este principio, los únicos datos personales que recabamos de los usuarios son los estrictamente necesarios para cumplir con las diferentes finalidades del tratamiento descritas en cada formulario y en la presente política de privacidad
                </Text>
                <Text>
                    <b>Principio de exactitud:</b> los datos personales que recabamos se mantendrán exactos y, si fuera necesario, actualizados. Para ello, en caso de que se produzca algún cambio de datos personales el usuario nos lo deberá comunicar de forma que podamos realizar la oportuna actualización.
                </Text>
                <Text>
                    <b>Principio de limitación del plazo de conservación:</b> los datos personales que tratamos se conservarán durante los plazos indicados en el apartado “¿Durante cuánto tiempo conservaremos sus datos personales?”.
                </Text>
                <Text>
                    <b>Principio de integridad y confidencialidad:</b> para respetar este principio, los datos personales serán tratados de forma que se garantice una seguridad adecuada de los mismos, incluida la protección contra el tratamiento no autorizado o ilícito y contra su pérdida, destrucción o daño accidental, aplicando para ello las medidas técnicas y organizativas apropiadas.
                </Text>
                <Text fontSize='xl' fontWeight={'semibold'}>
                    ¿CON QUÉ FINALIDAD UTILIZAMOS LOS DATOS PERSONALES?
                </Text>
                <Text>
                    {legalSettings.company} procesa sus datos de acuerdo con todas las leyes de protección de datos aplicables. Por supuesto, respetamos los principios de la ley de protección de datos para el procesamiento de datos personales. Por lo tanto, procesamos sus datos solo para los fines descritos en esta Política de privacidad o durante la recopilación de datos. Estos son principalmente el proceso de compra y la provisión, personalización y desarrollo, así como la seguridad de nuestros servicios. Además, utilizamos sus datos dentro de la estricta legislación española y europea de protección de datos, sino también para otros fines, tales como el desarrollo de productos, la investigación científica (especialmente en los ámbitos del aprendizaje automático, la inteligencia artificial y el aprendizaje profundo) y estudios de mercado, para la optimización de procesos de negocio, el diseño orientado a la demanda nuestros servicios, así como para la publicidad personalizada.
                </Text>
                <Text>
                    Le informamos de que utilizaremos sus datos identificativos y de contacto para enviarles, por medios tradicionales o electrónicos, información técnica, operativa y/o comercial sobre ofertas, promociones, noticias, actividades y otras informaciones sobre nuestros productos y servicios.
                </Text>
                <Text>
                    <b>Datos recabados a través del formulario de solicitud de información:</b> utilizamos sus datos personales para gestionar y atender las solicitudes que nos realizan. Del mismo modo, en caso de que marque la casilla habilitada al efecto junto al formulario, utilizaremos sus datos personales para enviarle información sobre otras ofertas de productos que puedan resultarle de interés.
                </Text>
                <Text>
                    <b>Datos recabados a través del correo electrónico de contacto:</b> utilizamos sus datos personales para gestionar y atender las consultas o solicitudes de información que realizan los usuarios a través de la misma.
                </Text>
                <Text>
                    <b>Datos recabados a través del chat:</b> (en caso de que exista) utilizamos sus datos personales para atender y responder las consultas y mensajes que nos envían los usuarios.
                </Text>
                <Text>
                    <b>Datos de suscriptores a newsletter:</b> (en caso de que exista) utilizamos sus datos personales para gestionar la suscripción a nuestra newsletter y, por tanto, el envío, por correo electrónico de newsletters o boletines informativos sobre nuestra empresa, sus productos, servicios, noticias, ofertas o promociones.
                </Text>
                <Text>
                    <b>Datos de comentarios en el blog:</b> para gestionar y responder los comentarios que realizan los usuarios a las publicaciones que hacemos.
                </Text>
                <Text>
                    <b>Datos de usuarios de nuestros perfiles en redes sociales:</b> para interactuar con nuestros usuarios, principalmente, respondiendo a comentarios que realicen a las publicaciones que hacemos en los perfiles que tenemos en diferentes redes sociales.
                </Text>
                <Text fontSize='xl' fontWeight={'semibold'}>
                    ¿CUÁL ES LA LEGITIMACIÓN PARA EL TRATAMIENTO DE SUS DATOS PERSONALES?
                </Text>
                <Text>
                    <b>Datos de clientes:</b> (en caso de que exista) la base legal o jurídica que legitima el tratamiento de sus datos personales, es que el mismo es necesario para la ejecución de un contrato en el que los interesados son parte.
                </Text>
                <Text>
                    En cuanto al envío de información comercial, la base jurídica que legitima el tratamiento de sus datos personales es que el mismo es necesario para la satisfacción de intereses legítimos perseguidos por {legalSettings.company} conforme contempla el artículo 6.1. f) del RGPD, basándonos en lo dispuesto por el Informe 195/2017 de la AEPD. Ello será sin perjuicio de la posibilidad que tienen los interesados de oponerse al envío de esa información comercial.
                </Text>
                <Text>
                    <b>Datos recabados a través del formulario de solicitud de información:</b> (en caso de que exista) la base legal o jurídica que legitima el tratamiento de sus datos personales es el consentimiento prestado al contactar con nosotros a través del formulario habilitado para ello y, por tanto, la necesidad de dicho tratamiento para gestionar las solicitudes de información que realizan. Por su parte, la base que legitima el tratamiento de sus datos con fines de envío de información comercial es el consentimiento que presten marcando la casilla habilitada al efecto.
                </Text>
                <Text>
                    <b>Datos recabados a través del correo electrónico de contacto:</b> (en caso de que exista) la base legal o jurídica que legitima el tratamiento de sus datos personales es el consentimiento prestado al contactar con nosotros a través de la dirección de correo electrónico habilitada para ello y, por tanto, la necesidad de dicho tratamiento para gestionar las solicitudes de información que realizan.
                </Text>
                <Text>
                    <b>Datos recabados a través del chat:</b> (en caso de que exista) la base legal o jurídica que legitima el tratamiento de sus datos personales es el consentimiento que nos prestan al contactar con nosotros a través del chat.
                </Text>
                <Text>
                    <b>Datos de suscriptores a newsletter:</b> (en caso de que exista) la base legal o jurídica que legitima el tratamiento de sus datos personales es el consentimiento prestado al suscribirse a nuestra newsletter y, por tanto, la necesidad del mismo para atender su suscripción.
                </Text>
                <Text>
                    <b>Datos de comentarios en el blog:</b> (en caso de que exista) la base legal o jurídica para el tratamiento de los datos personales que nos facilitan los interesados es el consentimiento prestado al realizar comentarios a las publicaciones que hacemos.
                </Text>
                <Text>
                    <b>Datos de usuarios de nuestros perfiles en redes sociales:</b> la base legal o jurídica que legitima el tratamiento de sus datos personales es el consentimiento prestado al seguirnos en nuestras redes sociales, siendo necesario por tanto dicho tratamiento para interactuar con los usuarios (por ejemplo, para responder comentarios).
                </Text>
                <Text fontSize='xl' fontWeight={'semibold'}>
                    ¿CÓMO HEMOS OBTENIDO SUS DATOS PERSONALES?
                </Text>
                <Text>
                    Todos los datos personales que tratamos en {legalSettings.company} nos los proporcionan los propios interesados o sus representantes legales.
                    Los datos personales que recabamos a través de este sitio web han sido recabados a través de los formularios existentes o a través de la dirección de correo electrónico habilitada para establecer contacto con nosotros.
                </Text>
                <Text fontSize='xl' fontWeight={'semibold'}>
                    ¿A QUÉ DESTINATARIOS SE COMUNICARÁN SUS DATOS PERSONALES?
                </Text>
                <Text>
                    {legalSettings.company} solo transmitirá sus datos si así lo permite la legislación de protección de datos española o europea. Trabajamos estrechamente con algunos proveedores de servicios, como servicio al cliente (por ejemplo, proveedores de servicios de línea directa), proveedores de servicios técnicos (como operaciones de centros de datos) o empresas de logística (como compañías postales). Estos proveedores de servicios solo pueden procesar sus datos en nuestro nombre bajo condiciones especiales. En la medida en que los utilicemos como procesador, los proveedores de servicios solo podrán acceder a sus datos en la medida y durante el período requerido para la prestación del servicio respectivo.
                </Text>
                <Text>
                    Trabajamos con proveedores de servicios técnicos para proporcionarle nuestros servicios. Respecto estos proveedores de servicios {legalSettings.company} se asegurará de que garanticen un nivel equivalente de protección de datos.
                </Text>
                <Text>
                    {legalSettings.company} trabaja con diversos proveedores tecnológicos para ofrecer los productos mostrados en <Link fontWeight={'bold'} href={giftboxUrl}>{giftboxUrl}</Link>. Para ello, la información del comprador puede ser transmitida a los proveedores de servicios con los que trabaja. Para obtener más información sobre cómo procesan la información personal estos proveedores tecnológicos, consulte sus respectivas políticas de privacidad.
                </Text>
                <UnorderedList px={12}>
                    <ListItem>Plataforma “e-commerce” de Easygoband World S.L. (Giftbox). Política de privacidad: <Link fontWeight={'bold'} href='https://www.easygoband.com/es/politica-de-privacidad'>https://www.easygoband.com/es/politica-de-privacidad</Link></ListItem>
                    <ListItem>Pasarela de pagos (Europa): PaynoPain Solutions S.L. (Paylands). Política de privacidad: <Link fontWeight={'bold'} href='https://paylands.com/politica-de-privacidad/'>https://paylands.com/politica-de-privacidad/</Link></ListItem>
                    <ListItem>Pasarela de pagos (México): Getnet MX <Link fontWeight={'bold'} href='https://getnet.mx/media/pdf/AvisodePrivacidad2022.pdf'>https://getnet.mx/media/pdf/AvisodePrivacidad2022.pdf</Link> y Mitec <Link fontWeight={'bold'} href='https://www.mitec.com.mx/avisodeprivacidad'>https://www.mitec.com.mx/avisodeprivacidad</Link></ListItem>
                </UnorderedList>
                <Text>
                    En la medida en que nos veamos obligados por una decisión de las autoridades o el tribunal o por un proceso legal o penal, transmitiremos sus datos a las autoridades policiales u otros terceros si es necesario.
                </Text>
                <Text fontSize='xl' fontWeight={'semibold'}>
                    ¿DURANTE CUÁNTO TIEMPO CONSERVAREMOS SUS DATOS PERSONALES?
                </Text>
                <Text>
                    <b>Datos de clientes:</b> los datos personales se conservarán durante la vigencia de la relación contractual y, una vez finalizada esta, durante los plazos necesarios para el cumplimiento de obligaciones legales.
                </Text>
                <Text>
                    En relación al envío de información comercial, los datos personales identificativos y de contacto de los interesados se conservarán hasta que manifiesten su oposición o revoque su consentimiento a tal finalidad.
                </Text>
                <Text>
                    <b>Datos recabados a través del formulario de solicitud de información:</b> conservaremos sus datos personales durante los plazos necesarios para gestionar y atender las solicitudes que realizan. No obstante, respecto al uso de sus datos personales identificativos y de contacto con fines comerciales, los conservaremos hasta que el momento que revoquen su consentimiento.
                </Text>
                <Text>
                    <b>Datos recabados a través del correo electrónico de contacto:</b> conservaremos sus datos personales durante los plazos necesarios para gestionar y atender las consultas o solicitudes que realizan.
                </Text>
                <Text>
                    <b>Datos recabados a través del chat:</b> (en caso de existir) las conversaciones que se realizan a través del chat son conservadas en durante un plazo máximo de un mes.
                </Text>
                <Text>
                    <b>Datos de suscriptores a newsletter:</b> (en caso de existir) conservaremos los datos personales de los suscriptores mientras no revoquen su consentimiento solicitando la baja de la suscripción.
                </Text>
                <Text>
                    <b>Datos de comentarios en el blog:</b> (en caso de existir) conservaremos los datos personales hasta el momento en el que se revoque el consentimiento, sin perjuicio de conservar los comentarios que se hayan realizado hasta entonces.
                </Text>
                <Text>
                    <b>Datos de usuarios de nuestros perfiles en redes sociales:</b> (en caso de existir) los plazos de conservación de los datos personales de nuestros seguidores en redes sociales dependen de las políticas de cada red social, si bien nosotros los trataremos únicamente hasta que dejan de seguirnos.
                </Text>
                <Text fontSize='xl' fontWeight={'semibold'}>
                    ¿CUÁLES SON SUS DERECHOS CUANDO NOS FACILITA SUS DATOS PERSONALES?
                </Text>
                <UnorderedList px={12}>
                    <ListItem><b>Derecho a solicitar el acceso a sus datos personales:</b> con el fin de conocer y verificar la licitud del tratamiento, usted podrá solicitarnos en cualquier momento confirmación de si {legalSettings.company} está tratando sus datos personales y, en tal caso, le informaremos, entre otras cuestiones, sobre qué datos estamos tratando, su finalidad, origen de los datos, plazo previsto de conservación de los datos y, en su caso, destinatarios o categorías de destinatarios.</ListItem>
                    <ListItem><b>Derecho a solicitar su rectificación:</b> usted podrá solicitarnos la rectificación de los datos personales que sean inexactos o que completemos los que sean incompletos, inclusive mediante una declaración adicional. En tal caso deberá indicar en su solicitud a qué datos se refiere y la corrección que haya de realizarse, debiendo acompañar, en su caso, la documentación justificativa de la inexactitud o carácter incompleto de los datos objeto de tratamiento.</ListItem>
                    <ListItem><b>Derecho a solicitar su supresión:</b> usted puede solicitarnos que sus datos personales se supriman y dejen de tratarse si ya no son necesarios para los fines para los que fueron recogidos o tratados de otro modo, retire su consentimiento, hayan sido tratados ilícitamente o deban ser suprimidos para el cumplimiento de una obligación legal.</ListItem>
                    <ListItem><b>Derecho a solicitar la limitación del tratamiento de sus datos personales:</b> en este caso ${legalSettings.company} únicamente conservará sus datos personales para la formulación, el ejercicio o la defensa de reclamaciones, o con miras a la protección de los derechos de otra persona física o jurídica o por razones de interés público importante.</ListItem>
                    <ListItem><b>Derecho a la portabilidad de sus datos personales:</b> puede solicitarnos que le entreguemos sus datos personales, a usted o a otro responsable que usted nos indique, en un formato estructurado, de uso común y lectura mecánica.</ListItem>
                    <ListItem><b>Derecho a oponerse al tratamiento:</b> {legalSettings.company} dejará de tratar sus datos personales en la forma que usted indique, salvo que tengamos que seguir tratándolos por motivos legítimos imperiosos o para la formulación, el ejercicio o la defensa de posibles reclamaciones.</ListItem>
                </UnorderedList>
                <Text>
                    Para ejercer sus derechos debe enviarnos una enviando un correo electrónico a <Link href={`mailto:${generalSettings.email}`}>{generalSettings.email}</Link>, acompañando en todo caso fotocopia de su documento de identidad.
                </Text>
                <Text>
                    {legalSettings.company} responderá todas las solicitudes en los plazos y condiciones que exige la normativa vigente en materia de protección de datos personales.
                </Text>
                <Text>
                    Si usted considera que no hemos tratado adecuadamente sus datos personales o que no hemos atendido debidamente en el ejercicio de sus derechos de protección de datos, puede presentar una reclamación ante la Agencia de Protección de Datos, bien a través de su sede electrónica.
                </Text>
                <Text fontSize='xl' fontWeight={'semibold'}>
                    SEGURIDAD
                </Text>
                <Text>
                    De acuerdo a lo dispuesto en el artículo 32 del RGPD, {legalSettings.company} ha adoptado las medidas técnicas y organizativas apropiadas para garantizar un nivel de seguridad adecuado al riesgo.
                </Text>
                <Text>
                    A fin de evaluar la adecuación del nivel de seguridad se han tenido particularmente en cuenta los riesgos que presenta el tratamiento de datos, en particular como consecuencia de la destrucción, pérdida o alteración accidental o ilícita de datos personales transmitidos, conservados o tratados de otra forma, o la comunicación o acceso no autorizado a dichos datos.
                </Text>
                <Text fontSize='xl' fontWeight={'semibold'}>
                    DEBER DE SECRETO
                </Text>
                <Text>
                    {legalSettings.company} ha adoptado medidas para garantizar que cualquier persona que actúe bajo su respectiva autoridad y tenga acceso a los datos personales que nos facilitan los usuarios, sólo puedan tratarlos siguiendo instrucciones de la empresa, debiendo además guardar el correspondiente secreto profesional sobre los mismos, que tendrá una duración indefinida.
                </Text>
                <Text>
                    A tal efecto, nuestros empleados han firmado un documento de confidencialidad y deber de secreto respecto a la información y los datos personales que tratan con ocasión de la relación laboral existente con la empresa.
                </Text>
            </Stack>
        </>
    )
}