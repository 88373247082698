import { ChakraProvider } from '@chakra-ui/react';
import {
    createBrowserRouter,
    createRoutesFromElements,
    Navigate,
    Outlet,
    Route,
    RouterProvider,
    ScrollRestoration
} from 'react-router-dom';
import { theme } from './theme';
import { Helmet, HelmetProvider } from 'react-helmet-async';
import { PublicConsoleLayout } from './layout/console/public/PublicConsoleLayout';
import { Login } from './routes/console/account/login';
import { Recover } from './routes/console/account/recover';
import { NotFound } from './routes/misc/NotFound';
import { AuthenticatedConsoleLayout } from './layout/console/authenticated/AuthenticatedConsoleLayout';
import { Reset } from './routes/console/account/reset';
import { ClientList } from './routes/console/clients/ClientList';
import { HotelsList } from './routes/console/hotels/HotelsList';
import { HotelMetrics } from './routes/console/metrics/HotelMetrics';
import { UsersList } from './routes/console/users/UsersList';
import { SalesList } from './routes/console/sales/SalesList';
import { HotelSettings } from './routes/console/hotelSettings/HotelSettings';
import { ProductsList } from './routes/console/products/ProductsList';
import { UsersForm } from './routes/console/users/UsersForm';
import { ClientsForm } from './routes/console/clients/ClientsForm';
import { HotelsForm } from './routes/console/hotels/HotelsForm';
import { ProductsForm } from './routes/console/products/ProductsForm';
import { Legal } from './routes/hotel/legal/Legal';
import { HotelLayout } from './layout/hotel/HotelLayout';
import { TermsConditions } from './routes/hotel/termsConditions/TermsConditions';
import { Cookies } from './routes/hotel/cookies/Cookies';
import { Privacy } from './routes/hotel/privacy/Privacy';
import { ProductsList as HotelProductList } from './routes/hotel/products/ProductsList';
import { Error } from './routes/misc/Error'
import { ProductDetails } from './routes/hotel/products/ProductDetails';
import { Checkout } from './routes/hotel/checkout/Checkout';
import { PaymentOk } from './routes/hotel/paymentOk/PaymentOk';
import { PaymentKo } from './routes/hotel/paymentKo/PaymentKo';
import { SalesDetails } from './routes/console/sales/SalesDetails';
import { CookiesProvider } from 'react-cookie';
import { CategoriesList } from './routes/console/categories/CategoriesList';
import { CategoriesForm } from './routes/console/categories/CategoriesForm';

const getHotelRoutes = () => (
    <>
        <Route index element={<Navigate to='products' replace />} />
        <Route path='legal' element={<Legal />} />
        <Route path='privacy' element={<Privacy />} />
        <Route path='cookies' element={<Cookies />} />
        <Route path='terms-conditions' element={<TermsConditions />} />
        <Route path='products' element={<HotelProductList />} />
        <Route path='products/:productId' element={<ProductDetails />} />
        <Route path='products/:productId/checkout' element={<Checkout />} />
        <Route path='orders/:orderId/payment/ok' element={<PaymentOk />} />
        <Route path='orders/:orderId/payment/ko' element={<PaymentKo />} />
    </>
)

const Root = () => (
    <>
        <ScrollRestoration />
        <Outlet />
    </>
)

export const App = () => {

    const router = createBrowserRouter(
        createRoutesFromElements(
            <Route path='/' element={<Root />} errorElement={<Error />}>
                <Route path='console/account' element={<PublicConsoleLayout />}>
                    <Route index element={<Navigate to='login' replace />} />
                    <Route path='login' element={<Login />} />
                    <Route path='recover' element={<Recover />} />
                    <Route path='reset' element={<Reset />} />
                </Route>
                <Route path='console' element={<AuthenticatedConsoleLayout />}>
                    <Route index element={<Navigate to='sales' replace />} />
                    <Route path='hotel-settings' element={<HotelSettings />} />
                    <Route path='products' element={<ProductsList />} />
                    <Route path='products/:id' element={<ProductsForm />} />
                    <Route path='categories' element={<CategoriesList />} />
                    <Route path='categories/new' element={<CategoriesForm />} />
                    <Route path='metrics/hotel' element={<HotelMetrics />} />
                    <Route path='sales' element={<SalesList />} />
                    <Route path='sales/:id' element={<SalesDetails />} />
                    <Route path='clients' element={<ClientList />} />
                    <Route path='clients/:id' element={<ClientsForm />} />
                    <Route path='hotels' element={<HotelsList />} />
                    <Route path='hotels/:id' element={<HotelsForm />} />
                    <Route path='users' element={<UsersList />} />
                    <Route path='users/:id' element={<UsersForm />} />
                </Route>
                <Route path='hotel/:id' element={<HotelLayout />}>
                    {getHotelRoutes()}
                </Route>
                <Route path='widget/hotel/:id' element={<HotelLayout widget />}>
                    {getHotelRoutes()}
                </Route>
                <Route path='not-found' element={<NotFound />} />
                <Route index element={<Navigate to='/console' replace />} />
                <Route path='*' element={<Navigate to='/not-found' replace />} />
            </Route >
        )
    );
    return (
        <CookiesProvider>
            <HelmetProvider>
                <Helmet>
                    <title>Giftbox by Goguest</title>
                    <link rel='canonical' href='https://giftbox.goguest.cloud' />
                    <meta name='description'
                        content='Giftbox by Goguest - Regala experiencias' />
                </Helmet>
                <ChakraProvider theme={theme}>
                    <RouterProvider router={router} />
                </ChakraProvider>
            </HelmetProvider>
        </CookiesProvider>
    );
}