import {
    Heading,
    Stack,
    Image,
    LinkBox,
    LinkOverlay
} from '@chakra-ui/react'
import { Link } from 'react-router-dom'
import Logo from '../../../assets/img/giftbox-dark.svg'

export const Header = ({ title }: { title: string }) => (
    <Stack spacing='8' align='center'>
        <LinkBox>
            <LinkOverlay as={Link} to='/console/account'>
                <Image sx={{ width: 320 }} src={Logo} />
            </LinkOverlay>
        </LinkBox>
        <Stack spacing='3' textAlign='center'>
            <Heading size={{ base: 'xs', md: 'sm' }}>{title}</Heading>
        </Stack>
    </Stack>
)