import { As, Button, ButtonProps, HStack, Icon, Link, Text } from '@chakra-ui/react'
import { NavLink } from 'react-router-dom';

interface NavButtonProps extends ButtonProps {
  href?: string;
  to?: string;
  icon: As;
  label: string;
  disableHover?: boolean;
}

export const NavButton = (props: NavButtonProps) => {
  const { icon, label, to, disableHover, ...buttonProps } = props
  let buttonStyles = {}
  if (disableHover) {
    buttonStyles = { _hover: {} }
  }
  if (props.href) {
    return (
      <Button
        as={Link}
        href={props.href}
        target='_blank'
        variant='ghost-on-accent'
        sx={buttonStyles}
        justifyContent='start'
        width={'full'}
        {...buttonProps}>
        <HStack spacing='3'>
          <Icon as={icon} boxSize='6' color='on-accent-subtle' />
          <Text>{label}</Text>
        </HStack>
      </Button>
    )
  }
  return (
    <NavLink style={{ width: '100%' }} to={to} replace>
      {({ isActive }) => (
        <Button
          variant='ghost-on-accent'
          sx={buttonStyles}
          justifyContent='start'
          width={'full'}
          aria-current={isActive ? 'page' : 'false'}
          {...buttonProps}>
          <HStack spacing='3'>
            <Icon as={icon} boxSize='6' color='on-accent-subtle' />
            <Text>{label}</Text>
          </HStack>
        </Button>
      )}
    </NavLink>
  )
}