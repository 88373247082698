import {
    Text,
    Stack,
    InputGroup,
    InputLeftElement,
    Icon,
    Input,
    Box
} from '@chakra-ui/react';
import React from 'react';
import { FiSearch } from 'react-icons/fi';

type BasicTableContainerProps = {
    title: string;
    onSearch?: (event: any) => void;
    customSearch?: React.ReactNode | React.ReactNode[];
    children?: React.ReactNode | React.ReactNode[];
    caption?: string;
    footer?: React.ReactNode | React.ReactNode[];
}

export const BasicTableContainer = (props: BasicTableContainerProps) => (
    <Box
        bg='bg-surface'
        boxShadow={{ base: 'none', md: 'sm' }}
        borderRadius={{ base: 'none', md: 'lg' }}
    >
        <Stack spacing='5'>
            <Box px={{ base: '4', md: '6' }} pt='5'>
                <Stack
                    direction={{ base: 'column', md: 'row' }}
                    justify='space-between'
                    spacing={{ base: 4, md: '8' }}>
                    <Text fontSize='lg' fontWeight='medium'>
                        {props.title}
                    </Text>
                    {(!props.customSearch && props.onSearch) &&
                        <InputGroup maxW='xs'>
                            <InputLeftElement pointerEvents='none'>
                                <Icon as={FiSearch} color='muted' boxSize='5' />
                            </InputLeftElement>
                            <Input placeholder='Buscar' onChange={props.onSearch} />
                        </InputGroup>
                    }
                    {props.customSearch &&
                        props.customSearch
                    }
                </Stack>
            </Box>
            <Box overflowX='auto'>
                {props.children}
            </Box>
            {props.caption && !props.footer &&
                <Box px={{ base: '4', md: '6' }} pb='5'>
                    {props.caption &&
                        <Text color='subtle' fontSize={'sm'}>
                            {props.caption}
                        </Text>
                    }
                </Box>
            }
            {props.footer && 
                <Box px={{ base: '4', md: '6' }} pb='5'>
                    {props.footer}
                </Box>
            }
        </Stack>
    </Box>
)