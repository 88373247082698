import { useNavigate, useParams } from 'react-router'
import { SectionHeader } from '../components/SectionHeader'
import { IoMdAdd, IoMdCreate } from 'react-icons/io';
import { useForm } from 'react-hook-form';
import { FormControl, FormErrorMessage, FormLabel, Input, Select, useToast } from '@chakra-ui/react';
import { useEffect } from 'react';
import { showToast } from '../../../helpers';
import { collection, serverTimestamp, updateDoc, doc, addDoc } from '@firebase/firestore';
import { firebaseFirestore } from '../../../firebase';
import { FullForm } from '../components/forms/FullForm';
import { FormControlGroup } from '../components/forms/FormControlGroup';
import { useCollection, useDocumentData } from 'react-firebase-hooks/firestore';
import { Validators } from '../../../validationPatterns';
import { setDoc } from 'firebase/firestore';

type HotelFormData = {
    name: string;
    clientId: string;
    contactPerson?: {
        name?: string;
        email?: string;
        phone?: string;
    }
}

export const HotelsForm = () => {
    const { id } = useParams();
    const isNew = id === 'new';
    const [clientId, hotelId] = id!!.split('-');
    const toast = useToast();
    const navigate = useNavigate();

    const [hotelDataSnapshot, isLoadingHotelData, hotelDataLoadingError] = useDocumentData(
        doc(firebaseFirestore, 'clients', clientId, 'hotels', hotelId ?? '?')
    );
    const [clientsCollectionSnapshot, isLoadingClientsList, clientsListLoadingError] = useCollection(
        collection(firebaseFirestore, 'clients')
    );
    const isLoading = isLoadingHotelData || isLoadingClientsList;

    useEffect(() => {
        if (hotelDataLoadingError) {
            console.error(hotelDataLoadingError);
            showToast(toast, {
                title: 'Error al obtener la información del hotel.',
                status: 'error'
            });
        }
        if (clientsListLoadingError) {
            console.error(clientsListLoadingError);
            showToast(toast, {
                title: 'Error al obtener el listado de clientes.',
                status: 'error'
            });
        }
    }, [hotelDataLoadingError, clientsListLoadingError]);

    const {
        handleSubmit,
        register,
        formState: { errors, isSubmitting },
    } = useForm<HotelFormData>({
        values: hotelDataSnapshot as HotelFormData,
        resetOptions: {
            keepDirtyValues: true
        }
    });


    const send = (data: HotelFormData) => {
        return new Promise<void>((resolve) => {
            const success = () => {
                showToast(toast, {
                    title: isNew ? 'Hotel creado' : 'Hotel actualizado',
                    description: isNew ?
                        'El hotel se ha creado correctamente.' :
                        'El hotel se ha actualizado correctamente.',
                    status: 'success',
                });
                navigate('/console/hotels', { replace: true });

            }
            const error = (e: any) => {
                console.error(e);
                showToast(toast, {
                    title: isNew ?
                        'Error al crear hotel' :
                        'Error al actualizar el hotel',
                    description: 'No se ha podido completar la acción. Inténtalo de nuevo.',
                    status: 'error',
                });
            }
            if (isNew) {
                addDoc(
                    collection(firebaseFirestore, 'clients', data.clientId, 'hotels'), {
                    ...data,
                    created: serverTimestamp(),
                    updated: serverTimestamp()
                })
                    .then((h) => setDoc(
                        doc(
                            firebaseFirestore,
                            'clients',
                            data.clientId,
                            'hotels',
                            h.id,
                            'settings',
                            'copy'
                        ),
                        {
                            landingPageTitle: 'REGALA EXPERIENCIAS',
                            landingPageSubtitle: 'Las mejores promociones y ofertas para disfrutar de nuestros servicios',
                            claimInstructions: 'Llama o escríbenos una semana antes de que planees hacer uso de tu vale e indícanos el código de canjeo que encontrarás más abajo.',
                            termsConditions: 'Puedes canjear este regalo hasta un año a partir de la fecha de emisión y el canje siempre está sujeto a disponibilidad y ocupación.'
                        }
                    ))
                    .then(success)
                    .catch(error)
                    .finally(resolve);
            } else {
                updateDoc(
                    doc(firebaseFirestore, 'clients', data.clientId, 'hotels', hotelId), {
                    ...data,
                    updated: serverTimestamp()
                })
                    .then(success)
                    .catch(error)
                    .finally(resolve);
            }
        });
    }
    return (
        <>
            <SectionHeader
                title={isNew ? 'Crear nuevo hotel' : 'Actualizar hotel'}
                subtitle={'Completa el formulario y pulsa en Enviar'}
                icon={isNew ? IoMdAdd : IoMdCreate} />
            <FullForm
                onSubmit={handleSubmit(send)}
                cancelTo={'/console/hotels'}
                isSubmitting={isSubmitting}
                canSubmit={!isLoading}>
                <FormControlGroup title='Datos generales' description='Nombre y cliente del hotel'>
                    <FormControl isRequired isInvalid={errors.name !== undefined}>
                        <FormLabel htmlFor='billingDetails.company'>Nombre del hotel</FormLabel>
                        <Input
                            id='name'
                            type='text'
                            {...register('name', {
                                required: 'Campo obligatorio.',
                            })} />
                        <FormErrorMessage>
                            {errors.name &&
                                <>{errors.name.message}</>
                            }
                        </FormErrorMessage>
                    </FormControl>
                    <FormControl isRequired isInvalid={errors.clientId !== undefined}>
                        <FormLabel htmlFor='client'>Cliente</FormLabel>
                        <Select
                            id='client'
                            placeholder='Selecciona un cliente'
                            {...register('clientId', {
                                required: 'Campo obligatorio.',
                            })}>
                            {clientsCollectionSnapshot?.docs.map(c => (
                                <option key={c.id} value={c.id}>{c.data().name}</option>
                            ))}
                        </Select>
                        <FormErrorMessage>
                            {errors.clientId &&
                                <>{errors.clientId.message}</>
                            }
                        </FormErrorMessage>
                    </FormControl>
                </FormControlGroup>
                <FormControlGroup title='Persona de contacto' description='Datos de la persona de contacto principal'>
                    <FormControl isInvalid={errors.contactPerson?.name !== undefined}>
                        <FormLabel htmlFor='contactPerson.name'>Nombre y apellidos</FormLabel>
                        <Input
                            id='contactPerson.name'
                            type='text'
                            {...register('contactPerson.name')} />
                        <FormErrorMessage>
                            {errors.contactPerson?.name &&
                                <>{errors.contactPerson?.name.message}</>
                            }
                        </FormErrorMessage>
                    </FormControl>
                    <FormControl isInvalid={errors.contactPerson?.email !== undefined}>
                        <FormLabel htmlFor='conctactPerson.email'>Correo electrónico</FormLabel>
                        <Input
                            id='contactPerson.email'
                            type='email'
                            {...register('contactPerson.email', {
                                pattern: {
                                    value: Validators.email,
                                    message: 'Introduce un correo electrónico válido.'
                                }
                            })} />
                        <FormErrorMessage>
                            {errors.contactPerson?.email &&
                                <>{errors.contactPerson?.email.message}</>
                            }
                        </FormErrorMessage>
                    </FormControl>
                    <FormControl isInvalid={errors.contactPerson?.phone !== undefined}>
                        <FormLabel htmlFor='contactPerson.phone'>Teléfono</FormLabel>
                        <Input
                            id='contactPerson.phone'
                            type='text'
                            {...register('contactPerson.phone')} />
                        <FormErrorMessage>
                            {errors.contactPerson?.phone &&
                                <>{errors.contactPerson?.phone.message}</>
                            }
                        </FormErrorMessage>
                    </FormControl>
                </FormControlGroup>
            </FullForm>
        </>
    )

}