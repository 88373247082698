import {
    useToast,
    FormControl,
    FormLabel,
    Input,
    FormErrorMessage,
    Textarea,
} from '@chakra-ui/react';
import { doc, setDoc, serverTimestamp } from 'firebase/firestore';
import { useEffect } from 'react';
import { useDocumentData } from 'react-firebase-hooks/firestore';
import { useForm } from 'react-hook-form';
import { firebaseFirestore } from '../../../firebase';
import { useConsoleContext } from '../../../layout/console/authenticated/AuthenticatedConsoleLayout';
import { showToast } from '../../../helpers';
import { BasicForm } from '../components/forms/BasicForm';

type CopyFormData = {
    termsConditions: string;
    claimInstructions: string;
    landingPageTitle: string;
    landingPageSubtitle: string;
}

export const Copy = () => {

    const context = useConsoleContext();
    const toast = useToast();

    const docRef = doc(
        firebaseFirestore,
        'clients',
        context.settings.selectedClient!!.id,
        'hotels',
        context.settings.selectedHotel!!.id,
        'settings',
        'copy'
    );

    const [copyDataSnapshot, loading, errorLoadingData] = useDocumentData(docRef);

    useEffect(() => {
        if (errorLoadingData) {
            console.error(errorLoadingData);
            showToast(toast, {
                title: 'Error al obtener la información de textos.',
                status: 'error'
            });
        }
    }, [errorLoadingData]);

    useEffect(() => {
        reset({
            termsConditions: '',
            claimInstructions: '',
            landingPageTitle: '',
            landingPageSubtitle: ''
        }, { keepDirty: false });
    }, [context.settings]);

    const showSaveSuccess = () => {
        showToast(toast, {
            title: 'Información actualizada correctamente',
            status: 'success',
        });

    }
    const showSaveError = (e: any) => {
        console.error(e);
        showToast(toast, {
            title: 'Error al guardar la información. Vuelve a itentarlo.',
            status: 'error',
        });
    }

    const save = (data: CopyFormData) => new Promise<void>((resolve) => {
        setDoc(
            docRef, {
            ...data,
            updated: serverTimestamp()
        }, { merge: true })
            .then(showSaveSuccess)
            .catch(showSaveError)
            .finally(resolve);
    });

    const {
        handleSubmit,
        register,
        reset,
        formState: { errors, isSubmitting },
    } = useForm<CopyFormData>({
        values: copyDataSnapshot as CopyFormData,
        resetOptions: {
            keepDirtyValues: true
        }
    });

    return (
        <BasicForm
            title={'Personalización de textos'}
            description={'Personaliza los textos que se usarán en la plataforma'}
            canSubmit={!loading}
            isSubmitting={isSubmitting}
            onSubmit={handleSubmit(save)} >
            <FormControl isRequired isInvalid={errors?.landingPageTitle !== undefined}>
                <FormLabel htmlFor='shortDescription'>Título de la landing page</FormLabel>
                <Input
                    id='landingPageTitle'
                    {...register('landingPageTitle', {
                        required: 'Campo obligatorio',
                        maxLength: {
                            value: 50,
                            message: 'Máximo 50 caracteres'
                        }
                    })}
                />
                <FormErrorMessage>
                    {errors.landingPageTitle &&
                        <>{errors.landingPageTitle.message}</>
                    }
                </FormErrorMessage>
            </FormControl>
            <FormControl isRequired isInvalid={errors?.landingPageSubtitle !== undefined}>
                <FormLabel htmlFor='shortDescription'>Subtítulo de la landing page</FormLabel>
                <Input
                    id='landingPageSubtitle'
                    {...register('landingPageSubtitle', {
                        required: 'Campo obligatorio',
                        maxLength: {
                            value: 100,
                            message: 'Máximo 100 caracteres'
                        }
                    })}
                />
                <FormErrorMessage>
                    {errors.landingPageSubtitle &&
                        <>{errors.landingPageSubtitle.message}</>
                    }
                </FormErrorMessage>
            </FormControl>
            <FormControl isRequired isInvalid={errors?.claimInstructions !== undefined}>
                <FormLabel htmlFor='shortDescription'>Instrucciones de canje que se muestran en el PDF del vale</FormLabel>
                <Textarea
                    id='claimInstructions'
                    height={100}
                    resize='none'
                    {...register('claimInstructions', {
                        required: 'Campo obligatorio',
                        maxLength: {
                            value: 33300,
                            message: 'Máximo 300 caracteres'
                        }
                    })}
                />
                <FormErrorMessage>
                    {errors.claimInstructions &&
                        <>{errors.claimInstructions.message}</>
                    }
                </FormErrorMessage>
            </FormControl>
            <FormControl isRequired isInvalid={errors?.termsConditions !== undefined}>
                <FormLabel htmlFor='shortDescription'>Términos y condiciones que se muestran en el PDF del vale</FormLabel>
                <Textarea
                    id='termsConditions'
                    height={100}
                    resize='none'
                    {...register('termsConditions', {
                        required: 'Campo obligatorio',
                        maxLength: {
                            value: 300,
                            message: 'Máximo 300 caracteres'
                        }
                    })}
                />
                <FormErrorMessage>
                    {errors.termsConditions &&
                        <>{errors.termsConditions.message}</>
                    }
                </FormErrorMessage>
            </FormControl>
        </BasicForm >
    )

}