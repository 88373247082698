import { SectionHeader } from '../components/SectionHeader'
import { IoMdAdd, IoMdCreate } from 'react-icons/io';
import {
    Button,
    FormControl,
    FormErrorMessage,
    FormLabel,
    Icon,
    Input,
    Select,
    Switch,
    useToast
} from '@chakra-ui/react';
import { useNavigate, useParams } from 'react-router-dom';
import { Controller, useForm } from 'react-hook-form';
import { httpsCallable } from '@firebase/functions';
import { firebaseFirestore, firebaseFunctions } from '../../../firebase';
import { doc, updateDoc, serverTimestamp } from '@firebase/firestore';
import { useEffect, useState } from 'react';
import { FormControlGroup } from '../components/forms/FormControlGroup';
import { showToast } from '../../../helpers';
import { FullForm } from '../components/forms/FullForm';
import { useConsoleContext } from '../../../layout/console/authenticated/AuthenticatedConsoleLayout';
import { collection } from '@firebase/firestore';
import { Role } from '../../../types/role';
import { MdOutgoingMail } from 'react-icons/md';
import { useCollection, useDocumentData } from 'react-firebase-hooks/firestore';
import { Validators } from '../../../validationPatterns';

type UserFormData = {
    name: string;
    email: string;
    role: string;
    clientId?: string;
    canAddUsers?: boolean;
    disabled: boolean;
}

const createUsersCallable = httpsCallable(firebaseFunctions, 'users-create');
const sendResetPasswordLinkCallable = httpsCallable(firebaseFunctions, 'users-sendResetPasswordLink');


export const UsersForm = () => {
    const { id } = useParams();
    const isNew = id === 'new';
    const toast = useToast();
    const navigate = useNavigate();
    const context = useConsoleContext();

    const [isSendingEmail, setIsSendingEmail] = useState(false);

    const [userData, isLoadingUserData, userLoadingError] = useDocumentData(
        doc(firebaseFirestore, 'users', id!!)
    );
    const [clientsCollectionSnapshot, isLoadingClientsCollection, clientsLoadingError] = useCollection(
        collection(firebaseFirestore, 'clients')
    );

    useEffect(() => {
        if (context.isSuperAdmin() && clientsLoadingError) {
            console.error(userLoadingError);
            showToast(toast, {
                title: 'Error al obtener listado de clientes',
                status: 'error'
            });
        }
        if (!isNew && userLoadingError) {
            console.error(userLoadingError);
            showToast(toast, {
                title: 'Error al obtener información de usuario',
                status: 'error'
            });
        }
    }, [clientsLoadingError, userLoadingError])

    const sendResetPasswordLink = () => {
        setIsSendingEmail(true);
        sendResetPasswordLinkCallable({
            email: userData?.email
        }).then(() => {
            showToast(toast, {
                title: 'Enlace de acceso enviado correctamente.',
                status: 'success'
            });
        }).catch((e) => {
            console.error(e);
            showToast(toast, {
                title: 'Ha ocurrido un error. Vuelve a intentarlo.',
                status: 'error'
            });
        }).finally(() => {
            setIsSendingEmail(false);
        })
    }

    const {
        handleSubmit,
        watch,
        register,
        control,
        formState: { errors, isSubmitting },
    } = useForm<UserFormData>({
        values: {
            ...(userData as UserFormData),
            canAddUsers: userData?.canAddUsers === undefined ? true : userData?.canAddUsers
        },
        resetOptions:{
            keepDirtyValues:true
        }
    });

    const role = watch('role');
    const isLoading = isLoadingUserData || isLoadingClientsCollection;

    const send = (data: UserFormData) => {
        return new Promise<void>((resolve) => {
            const success = () => {
                showToast(toast, {
                    title: isNew ? 'Usuario creado' : 'Usuario actualizado',
                    description: isNew ?
                        'El usuario se ha creado correctamente. Se le ha enviado un correo de invitación.' :
                        'El usuario se ha actualizado correctamente.',
                    status: 'success',
                });
                navigate('/console/users', { replace: true });
            }
            const error = (e: any) => {
                console.error(e);
                showToast(toast, {
                    title: isNew ? 'Error al crear usuario' : 'Error al actualizar el usuario',
                    description: 'No se ha podido completar la acción. Inténtalo de nuevo.',
                    status: 'error'
                });
            }
            if (isNew) {
                createUsersCallable({
                    ...data,
                    clientId: !context.isSuperAdmin() ? context.claims.clientId : data.clientId,
                })
                    .then(success)
                    .catch(error)
                    .finally(resolve);
            } else {
                const ref = doc(firebaseFirestore, 'users', id!!);
                updateDoc(ref, {
                    ...data,
                    clientId: !context.isSuperAdmin() ? context.claims.clientId : data.clientId,
                    updated: serverTimestamp()
                })
                    .then(success)
                    .catch(error)
                    .finally(resolve);
            }
        });
    }

    return (
        <>
            <SectionHeader
                title={isNew ? 'Nuevo usuario' : 'Actualizar usuario'}
                subtitle='Completa los campos y pulsa en Enviar'
                icon={isNew ? IoMdAdd : IoMdCreate}>
                {(!isNew && !isLoading) &&
                    <Button
                        variant='primary'
                        isLoading={isSendingEmail}
                        onClick={sendResetPasswordLink}
                        leftIcon={<Icon as={MdOutgoingMail} fontSize='22' />}
                    >
                        Enviar enlace de acceso
                    </Button>
                }

            </SectionHeader>
            <FullForm
                onSubmit={handleSubmit(send)}
                cancelTo={'/console/users'}
                isSubmitting={isSubmitting}
                canSubmit={!isLoading}>
                <FormControlGroup
                    title='Datos personales'
                    description='Completa los datos personales'
                >
                    <FormControl isRequired isInvalid={errors.name !== undefined}>
                        <FormLabel htmlFor='name'>Nombre y apellidos</FormLabel>
                        <Input
                            id='name'
                            type='text'
                            {...register('name', {
                                required: 'Campo obligatorio.',
                            })} />
                        <FormErrorMessage>
                            {errors.name &&
                                <>{errors.name.message}</>
                            }
                        </FormErrorMessage>
                    </FormControl>
                    <FormControl isRequired isInvalid={errors.email !== undefined}>
                        <FormLabel htmlFor='email'>Correo electrónico</FormLabel>
                        <Input
                            id='email'
                            type='email'
                            isDisabled={!isNew}
                            {...register('email', {
                                required: 'Campo obligatorio.',
                                pattern: {
                                    value: Validators.email,
                                    message: 'Introduce un correo electrónico válido.'
                                }
                            })} />
                        <FormErrorMessage>
                            {errors.email &&
                                <>{errors.email.message}</>
                            }
                        </FormErrorMessage>
                    </FormControl>
                </FormControlGroup>
                <FormControlGroup
                    title='Configuración de permisos'
                    description='Completa los parámetros de acceso'>
                    <FormControl isRequired isInvalid={errors.role !== undefined}>
                        <FormLabel htmlFor='role'>Role</FormLabel>
                        <Select
                            id='role'
                            placeholder='Seleccionar Rol'
                            {...register('role', {
                                required: 'Campo obligatorio.',
                            })}
                        >
                            {(context.isSuperAdmin()) &&
                                <option value='SUPERADMIN'>Superadministrador</option>
                            }
                            <option value='ADMIN'>Administrador</option>
                            <option value='AGENT'>Gestor de ventas</option>
                        </Select>
                        <FormErrorMessage>
                            {errors.role &&
                                <>{errors.role.message}</>
                            }
                        </FormErrorMessage>
                    </FormControl>
                    {(role === Role.ADMIN && context.isSuperAdmin()) &&
                        <FormControl isRequired isInvalid={errors.clientId !== undefined} >
                            <FormLabel htmlFor='client'>Cliente</FormLabel>
                            <Select
                                id='client'
                                placeholder='Seleccionar cliente'
                                {...register('clientId', {
                                    required: 'Campo obligatorio.',
                                })}
                            >
                                {clientsCollectionSnapshot?.docs.map(d => (
                                    <option key={d.id} value={d.id}>{d.data().name}</option>
                                ))}
                            </Select>
                            <FormErrorMessage>
                                {errors.clientId &&
                                    <>{errors.clientId.message}</>
                                }
                            </FormErrorMessage>
                        </FormControl>
                    }
                    {role === Role.ADMIN &&
                        <FormControl isInvalid={errors.canAddUsers !== undefined} display='flex' alignItems='center'>
                            <FormLabel htmlFor='canAddUsers' mb='0'>
                                ¿Puede añadir usuarios?
                            </FormLabel>
                            <Controller
                                control={control}
                                name='canAddUsers'
                                render={({ field: { onChange, value, ref } }) => (
                                    <Switch
                                        isChecked={value}
                                        onChange={onChange}
                                        ref={ref}
                                        size='lg'
                                        colorScheme={'brand'}
                                    />
                                )}
                            />
                        </FormControl>
                    }
                    {!isNew &&
                        <FormControl isInvalid={errors.disabled !== undefined} display='flex' alignItems='center'>
                            <FormLabel htmlFor='disabled' mb='0'>
                                ¿Deshabilitado?
                            </FormLabel>
                            <Controller
                                control={control}
                                name='disabled'
                                render={({ field: { onChange, value, ref } }) => (
                                    <Switch
                                        isChecked={value}
                                        onChange={onChange}
                                        ref={ref}
                                        size='lg'
                                        colorScheme={'brand'} />
                                )}
                            />
                        </FormControl>
                    }
                </FormControlGroup>
            </FullForm>
        </>
    )

}

