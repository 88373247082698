import {
    Alert,
    AlertDescription,
    AlertIcon,
    AlertTitle,
    Button,
    FormControl,
    FormErrorMessage,
    FormLabel,
    Icon,
    Input,
    InputGroup,
    InputRightElement,
    Stack,
    Text,
    useToast
} from '@chakra-ui/react'
import { Link, useNavigate, useSearchParams } from 'react-router-dom';
import { Header } from './header'
import * as jose from 'jose';
import { DateTime } from 'luxon';
import { firebaseAuth, firebaseFunctions } from '../../../firebase';
import { httpsCallable } from '@firebase/functions';
import { showToast } from '../../../helpers';
import { signInWithEmailAndPassword, browserSessionPersistence, setPersistence } from '@firebase/auth';
import { useState } from 'react';
import { useForm } from 'react-hook-form';
import { AiFillEye, AiFillEyeInvisible } from 'react-icons/ai';
import { Validators } from '../../../validationPatterns';

const resetPasswordCallable = httpsCallable(firebaseFunctions, 'users-resetPassword');

export const Reset = () => {
    const [searchParams] = useSearchParams();
    const [showPassword, setShowPassword] = useState(false);

    const token = searchParams.get('token');
    const toast = useToast();
    const navigate = useNavigate();

    const toggleShowPassword = () => {
        setShowPassword(!showPassword);
    }

    const hasValidToken = () => {
        if (token === null) {
            return false;
        }
        const claims = jose.decodeJwt(token);
        if (!claims.exp || !claims.sub) {
            return false;
        }
        return DateTime.fromMillis(claims.exp * 1000) > DateTime.now()
    };

    const getSubFromToken = () => {
        if (token === null) {
            return '';
        }
        const claims = jose.decodeJwt(token);
        return claims.sub ?? '';
    };

    const resetPassword = (data: any) => {

        return new Promise<void>((resolve) => {
            resetPasswordCallable({
                token: token,
                password: data.password
            }).then(() =>
                setPersistence(firebaseAuth, browserSessionPersistence)
            ).then(() => signInWithEmailAndPassword(
                firebaseAuth,
                getSubFromToken(),
                data.password
            )).catch((e) => {
                console.error(e);
                showToast(toast, {
                    title: 'Ha ocurrido un error. Vuelve a intentarlo',
                    status: 'error'
                });
            }).finally(() => {
                resolve();
            });
        });
    }


    const {
        handleSubmit,
        register,
        formState: { errors, isSubmitting },
    } = useForm();

    return (
        <>
            <Header title={'Establece tu contraseña'} />
            {!hasValidToken() &&
                <>
                    <Alert
                        status='error'
                        variant='subtle'
                        flexDirection='column'
                        alignItems='center'
                        justifyContent='center'
                        textAlign='center'
                        pb={4}
                    >
                        <AlertIcon boxSize='60px' mr={0} />
                        <AlertTitle mt={4} mb={2} fontSize='lg'>
                            ¡Enlace caducado!
                        </AlertTitle>
                        <AlertDescription maxWidth='md'>
                            Parece que has accedido con un enlace caducado.
                            Por favor usa la función de recuperación de contraseña o
                            contacta con el servicio de atención al cliente para conseguir
                            un enlace de acceso nuevo.
                        </AlertDescription>
                    </Alert>
                    <Button
                        variant='primary'
                        colorScheme={'brand'}
                        as={Link}
                        to='../login'>
                        Inicio
                    </Button>
                </>
            }
            {hasValidToken() &&
                <>
                    <Text>Introduce tu nueva contraseña y pulsa en continuar para acceder con tu cuenta.</Text>
                    <Stack spacing='6' as='form' noValidate onSubmit={handleSubmit(resetPassword)}>
                        <Input
                            id='email'
                            type='hidden'
                            autoComplete='username'
                            name='username'
                            value={getSubFromToken()}
                        />
                        <FormControl isRequired isInvalid={errors.password !== undefined}>
                            <FormLabel htmlFor='password'>Contraseña</FormLabel>
                            <InputGroup size='md'>
                                <Input
                                    id='password'
                                    autoComplete='new-password'
                                    type={showPassword ? 'text' : 'password'}
                                    {...register(
                                        'password',
                                        {
                                            required: 'Campo obligatorio.',
                                            pattern:{
                                                value: Validators.strongPassword,
                                                message: 'La contraseña debe tener una longitud mínima de 6 caracteres y contener por lo menos un número, una mayúscula y un carácter especial (?*%$&#!_.@-,)'
                                            }
                                        }
                                    )}
                                />
                                <InputRightElement onClick={toggleShowPassword} sx={{ cursor: 'pointer' }}>
                                    {!showPassword &&
                                        <Icon as={AiFillEye} boxSize='6' color='brand.800' />
                                    }
                                    {showPassword &&
                                        <Icon as={AiFillEyeInvisible} boxSize='6' color='brand.800' />
                                    }
                                </InputRightElement>
                            </InputGroup>
                            <FormErrorMessage>
                                {errors.password &&
                                    <>{errors.password.message}</>
                                }
                            </FormErrorMessage>
                        </FormControl>
                        <Button
                            type='submit'
                            isLoading={isSubmitting}
                            colorScheme='brand'
                            variant='primary'>
                            Acceder
                        </Button>
                    </Stack>
                </>
            }
        </>
    )

}

