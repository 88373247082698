import {
    useToast,
    FormControl,
    FormLabel,
    Box,
    Stack,
    Select,
    Text
} from '@chakra-ui/react';
import { doc } from 'firebase/firestore';
import { useEffect, useState } from 'react';
import { useDocumentData } from 'react-firebase-hooks/firestore';
import { firebaseFirestore } from '../../../../firebase';
import { useConsoleContext } from '../../../../layout/console/authenticated/AuthenticatedConsoleLayout';
import { showToast } from '../../../../helpers';
import { PaylandsForm } from './PaylandsForm';


export const PaymentMethod = () => {
    const context = useConsoleContext();
    const toast = useToast();

    const [implementation, setImplementation] = useState<string>();

    const docRef = doc(
        firebaseFirestore,
        'clients',
        context.settings.selectedClient!!.id,
        'hotels',
        context.settings.selectedHotel!!.id,
        'settings',
        'paymentMethods'
    );

    const [paymentMethodsDataSnapshot, _loading, errorLoadingData] = useDocumentData(docRef);

    useEffect(() => {
        if (paymentMethodsDataSnapshot?.paylands) {
            setImplementation('PAYLANDS');
        } else {
            setImplementation(undefined);
        }
    }, [paymentMethodsDataSnapshot])

    useEffect(() => {
        if (errorLoadingData) {
            console.error(errorLoadingData);
            showToast(toast, {
                title: 'Error al obtener los métodos de pago.',
                status: 'error'
            });
        }
    }, [errorLoadingData]);

    return (
        <Stack
            direction={{ base: 'column', xl: 'row' }}
            spacing={{ base: '5', xl: '8' }}
        >
            <Box flexShrink={0} width={{ base: '100%', xl: '250px' }}>
                <Text fontSize='lg' fontWeight='medium'>
                    {'Configuración de pago'}
                </Text>
                <Text color='muted' fontSize='sm'>
                    {'Datos para la implementación de la pasarela'}
                </Text>
            </Box>
            <Box
                bg='bg-surface'
                boxShadow='sm'
                borderRadius='lg'
                flexGrow={1}>
                <Box
                    px={{ base: '4', md: '6' }}
                    pt={{ base: '5', md: '6' }}>
                    <FormControl isRequired>
                        <FormLabel htmlFor='implementation'>Implementación</FormLabel>
                        <Select
                            id='implementation'
                            value={implementation}
                            onChange={(e) => {
                                setImplementation(e.target.value);
                            }}
                            placeholder='Selecciona una implementación'>
                            <option value='PAYLANDS'>Paylands</option>
                        </Select>
                    </FormControl>
                </Box>
                {!implementation &&
                    <Box h={{ base: 5, md: 6 }} />
                }
                {implementation === 'PAYLANDS' &&
                    <PaylandsForm />
                }
            </Box>
        </Stack>
    )

}