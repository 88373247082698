import {
  Button,
  Checkbox,
  Divider,
  Heading,
  HStack,
  Icon,
  LinkBox,
  LinkOverlay,
  Stack,
  Text,
} from '@chakra-ui/react'
import { HiOutlineMail, HiOutlinePhone } from 'react-icons/hi'
import { ProductItem } from './ProductItem'
import { useHotelContext } from '../../../layout/hotel/HotelLayout'
import { Link } from 'react-router-dom'
import { formatPrice } from '../../../helpers'
import { FormState } from 'react-hook-form'
import { CheckoutFormData } from '../../../types/CheckoutFormData'
import { useState } from 'react'

type OrderSummaryProps = {
  product: any;
  formState: FormState<CheckoutFormData>
}

export const OrderSummary = ({ product, formState }: OrderSummaryProps) => {
  const context = useHotelContext();
  const [hasAcceptedConditions, setHasAcceptedConditions] = useState(false);
  return (
    <Stack spacing={{ base: '6', md: '10' }}>
      <Heading size='md'>Resumen del pedido</Heading>
      <Stack spacing='8'>
        <Stack spacing='6'>
          <ProductItem product={product} />
          <Divider />
        </Stack>

        <Stack spacing='6'>
          <Stack direction='row' justify='space-between'>
            <Text
              fontSize='lg'
              fontWeight='semibold'
              color='gray.700'
            >
              Total a pagar
            </Text>
            <Text fontSize='xl' fontWeight='semibold' color='black' >
              {formatPrice(product.price, context.settings.legal)}
            </Text>
          </Stack>
        </Stack>
      </Stack>
      <HStack>
        <Checkbox
          size={'lg'}
          colorScheme={'blackAlpha'}
          onChange={() => { setHasAcceptedConditions(!hasAcceptedConditions) }}
          isChecked={hasAcceptedConditions} />
        <Text>Acepto los <Link to='../terms-conditions' target='_blank' style={{textDecoration:'underline'}}>términos & condiciones</Link></Text>
      </HStack>
      <Stack spacing='8'>
        <HStack justify={'stretch'} spacing={6}>
          <Button as={Link} to='..' replace size='lg' py='7' width='full'>
            Cancelar
          </Button>
          <Button
            width='full'
            type='submit'
            isDisabled={!hasAcceptedConditions}
            isLoading={formState.isSubmitting}
            size='lg'
            variant='solid'
            py='7'
            color={context.settings.theme.colors.text}
            _hover={{
              bg: context.settings.theme.colors.palette[600],
              textDecoration: 'none'
            }}
            _active={{
              bg: context.settings.theme.colors.palette[700]
            }}
            bg={context.settings.theme.colors.palette[500]}>
            {'Continuar'}
          </Button>

        </HStack>
        <Stack spacing='3'>
          <Text fontSize='sm' color='gray.700'>
            ¿Tienes preguntas? ¿Necesitas ayuda para completar tu pedido?
          </Text>
          <Stack
            spacing='8'
            direction={{ base: 'column', lg: 'row' }}
            fontWeight='semibold'
          >
            <LinkBox>
              <LinkOverlay href={`tel:${context.settings.general.phone}`}>
                <HStack>
                  <Icon as={HiOutlinePhone} boxSize='5' />
                  <Text>{context.settings.general.phone}</Text>
                </HStack>
              </LinkOverlay>
            </LinkBox>
            <LinkBox>
              <LinkOverlay href={`mailto:${context.settings.general.email}`}>
                <HStack>
                  <Icon as={HiOutlineMail} boxSize='5' />
                  <Text>{context.settings.general.email}</Text>
                </HStack>
              </LinkOverlay>
            </LinkBox>
          </Stack>
        </Stack>
      </Stack>
    </Stack>
  )
}