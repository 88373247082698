import { IconButton } from '@chakra-ui/react';
import { MdRemoveRedEye } from 'react-icons/md';
import { Link } from 'react-router-dom';

type ViewButtonProps = {
    to: string;
}

export const ViewButton = (props: ViewButtonProps) => (
    <IconButton
        as={Link}
        to={props.to}
        color='brand.800'
        icon={<MdRemoveRedEye fontSize='1.5rem' />}
        variant='ghost'
        aria-label='Ver fila'
    />
)
