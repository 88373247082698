import { ListViewHeader } from '../components/ListViewHeader';
import { HiTemplate } from 'react-icons/hi';
import { IoMdAdd } from 'react-icons/io';
import { HotelSpecifficSection } from '../components/HotelSpecifficSection';
import { Thead, Tr, Th, Tbody, Td, Text, useToast } from '@chakra-ui/react';
import { BasicTable } from '../components/tables/BasicTable';
import { BasicTableContainer } from '../components/tables/BasicTableContainer';
import { EditButton } from '../components/tables/EditButton';
import { collection, deleteDoc, doc } from 'firebase/firestore';
import { useEffect, useState } from 'react';
import { useCollection } from 'react-firebase-hooks/firestore';
import { firebaseFirestore } from '../../../firebase';
import { showToast } from '../../../helpers';
import { useConsoleContext } from '../../../layout/console/authenticated/AuthenticatedConsoleLayout';
import { DeleteButton } from '../components/tables/DleteButton';

const DeleteActionButton = ({ id }: { id: string }) => {
    const toast = useToast();
    const context = useConsoleContext();

    const [isDeletingCategory, setIsDeletingCategory] = useState(false);

    const deleteCategory = async (id: string) => {
        setIsDeletingCategory(true);
        try {
            await deleteDoc(
                doc(
                    firebaseFirestore,
                    'clients',
                    context.settings.selectedClient!!.id,
                    'hotels',
                    context.settings.selectedHotel!!.id,
                    'categories',
                    id
                )
            );
        } catch (e) {
            console.error(e);
            showToast(toast, {
                title: 'Error al borrar la categoría.',
                status: 'error'
            });
        } finally {
            setIsDeletingCategory(false);
        }
    }
    return (
        <DeleteButton onClick={() => deleteCategory(id)} isLoading={isDeletingCategory} />
    )

}

const WrappedView = () => {
    const toast = useToast();
    const context = useConsoleContext();


    const [categoriesQuerySnapshot, isLoading, categoriesLoadingError] = useCollection(
        collection(
            firebaseFirestore,
            'clients',
            context.settings.selectedClient!!.id,
            'hotels',
            context.settings.selectedHotel!!.id,
            'categories'
        )
    );



    useEffect(() => {
        if (categoriesLoadingError) {
            console.error(categoriesLoadingError);
            showToast(toast, {
                title: 'Error al obtener el listado de categorías.',
                status: 'error'
            });
        }
    }, [categoriesLoadingError])

    return (
        <BasicTableContainer
            title={'Categorías'}
            caption={`Mostrando ${categoriesQuerySnapshot?.docs.length} resultados`}>
            <BasicTable
                isLoading={isLoading}
                isEmpty={categoriesQuerySnapshot?.docs.length === 0}>
                <Thead>
                    <Tr>
                        <Th width={8}></Th>
                        <Th>Id</Th>
                        <Th>Nombre</Th>
                    </Tr>
                </Thead>
                <Tbody>
                    {categoriesQuerySnapshot?.docs.map((d) => (
                        <Tr key={d.id}>
                            <Td>
                                <DeleteActionButton id={d.id} />
                            </Td>
                            <Td>
                                <Text color='muted'>{d.id}</Text>
                            </Td>
                            <Td>
                                <Text color='muted'>{d.data().name}</Text>
                            </Td>
                        </Tr>
                    ))}
                </Tbody>
            </BasicTable>
        </BasicTableContainer>
    );
}

export const CategoriesList = () => {
    const context = useConsoleContext();
    return (
        <>
            <ListViewHeader
                icon={HiTemplate}
                title={'Administración de categorías de producto'}
                subtitle={'Añadir y borrar categorías'}
                buttonIcon={IoMdAdd}
                buttonTitle={'Añadir categoría'}
                buttonTo={'/console/categories/new'}
                showButton={context.settings.selectedHotel !== undefined} />
            <HotelSpecifficSection>
                <WrappedView />
            </HotelSpecifficSection>
        </>
    )
}
