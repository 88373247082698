import {
    Stack,
    useToast,
} from '@chakra-ui/react';
import { Navigate, useParams } from 'react-router-dom';
import { SectionHeader } from '../components/SectionHeader';
import { BsArrowLeft } from 'react-icons/bs';
import { useDocumentData } from 'react-firebase-hooks/firestore';
import { doc } from '@firebase/firestore';
import { firebaseFirestore } from '../../../firebase';
import { useEffect } from 'react';
import { showToast } from '../../../helpers';
import { OrderDetails } from './OrderDetails';
import { PaymentDetails } from './PaymentDetails';
import { Notes } from './Notes';

export const SalesDetails = () => {
    const { id } = useParams();

    const toast = useToast();
    const [orderDataSnapshot, loadingOrderData, orderLoadingError] = useDocumentData(
        doc(
            firebaseFirestore,
            'orders',
            id!!
        )
    );

    const [legalSettingsDataSnapshot, _loadingLegalSettingsData, _legalSettingLoadingError] = useDocumentData(
        doc(
            firebaseFirestore,
            'clients',
            orderDataSnapshot?.clientId ?? '?',
            'hotels',
            orderDataSnapshot?.hotelId ?? '?',
            'settings',
            'legal'
        )
    );

    useEffect(() => {
        if (orderLoadingError) {
            showToast(toast, {
                title: 'Error al obtener la información de la venta',
                status: 'error'
            });
        }

    }, [orderLoadingError]);

    if (!loadingOrderData && !orderDataSnapshot) {
        return <Navigate to='/not-found' replace />
    }

    return (
        <>
            <SectionHeader
                title={'Información de venta'}
                subtitle='Información detallada de la venta'
                to={'/console/sales'}
                icon={BsArrowLeft} />
            <Stack spacing={14}>
                <OrderDetails
                    orderId={id!!}
                    loading={loadingOrderData}
                    orderData={orderDataSnapshot}
                    legalData={legalSettingsDataSnapshot}
                />
                <PaymentDetails
                    orderId={id!!}
                    legalSettings={legalSettingsDataSnapshot} />
                <Notes
                    orderId={id!!}
                    legalSettings={legalSettingsDataSnapshot} />
            </Stack>
        </>
    )

}