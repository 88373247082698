import { Helmet } from 'react-helmet-async';

export type HeaderProps = {
    generalSettings:any;
    brandSettings:any;
}
export const Header = ({
    generalSettings, brandSettings
}: HeaderProps) => (
    <Helmet>
        <title>{generalSettings.name}</title>
        <meta name='description'
            content={`${generalSettings.name} - Regala experiencias`} />
        <meta name='theme-color' content={brandSettings.primaryColor} />
    </Helmet>
)
