import {
    AlertDialog,
    AlertDialogOverlay,
    AlertDialogContent,
    AlertDialogHeader,
    AlertDialogBody,
    AlertDialogFooter,
    Button,
    UseDisclosureReturn
} from '@chakra-ui/react'
import { useRef } from 'react';

type ConfirmationModalProps = {
    disclosure: UseDisclosureReturn;
    onConfirm?: ()=>void;
    title: string;
    isLoading:boolean;
}
export const ConfirmationModal = (props: ConfirmationModalProps) => {
    const { isOpen, onClose } = props.disclosure;
    const { title, onConfirm, isLoading} = props;
    const cancelRef = useRef(null);
    return (
        <AlertDialog
            isOpen={isOpen}
            isCentered
            leastDestructiveRef={cancelRef}
            onClose={onClose}
        >
            <AlertDialogOverlay>
                <AlertDialogContent>
                    <AlertDialogHeader fontSize='lg' fontWeight='bold'>
                        {title}
                    </AlertDialogHeader>
                    <AlertDialogBody>
                        ¿Estás seguro de querer continuar? Esta acción no se podrá deshacer.
                    </AlertDialogBody>
                    <AlertDialogFooter>
                        <Button variant='ghost' ref={cancelRef} onClick={onClose}>
                            Cancelar
                        </Button>
                        <Button isLoading={isLoading} variant='primary' onClick={onConfirm} ml={3}>
                            Confirmar
                        </Button>
                    </AlertDialogFooter>
                </AlertDialogContent>
            </AlertDialogOverlay>
        </AlertDialog>
    )
}