import { DataLabel } from './DataLabel';

export const PaylandsPaymentData = ({ payment }: { payment: any }) => {
    const rawData = JSON.parse(payment.data().data.raw);
    const auth = rawData.order.transactions?.find((t: any) => t.operative === 'AUTHORIZATION');
    const source = auth?.source;
    return (
        <>
            <DataLabel title='UUID' value={rawData.order.uuid} />
            <DataLabel title='Estado' value={rawData.order.status} />
            <DataLabel title='Servicio' value={rawData.order.service} />
            <DataLabel title='Importe' value={rawData.order.amount} />
            <DataLabel title='Importe reembolsado' value={rawData.order.refunded} />
            <DataLabel title='Moneda' value={rawData.order.currency} />
            <DataLabel title='Error' value={auth?.error ? auth.error : '-'} />
            <DataLabel title='Tarjeta' value={`${source?.brand ?? ''} ${source?.type ?? ''} ${source?.last4 ?? '-'}`} />
        </>
    )
}
